import Messages from './containers/Messages'
import Dashboard from './containers/Dashboard'
import Reservation from './containers/Reservation'
import Hours from './containers/Hours'
import Menu from './containers/Menu'
import Meal from './containers/Meal'
import Side from './containers/Side'
import Sales from './containers/Sales'
import ReviewsList from './containers/ReviewsList'
import Settings from './containers/Settings'

export default [{
  path: '/messages/:page?',
  component: Messages,
  exact: true,
  requireLoggedIn: true,
}, {
  path: '/dashboard/:date?',
  component: Dashboard,
  exact: true,
  requireLoggedIn: true,
}, {
  path: '/reservations/:reservationId',
  component: Reservation,
  exact: true,
  requireLoggedIn: true,
}, {
  path: '/hours/:date?',
  component: Hours,
  exact: true,
  requireLoggedIn: true,
}, {
  path: '/menu',
  component: Menu,
  exact: true,
  requireLoggedIn: true,
}, {
  path: '/meal/:mealId',
  component: Meal,
  exact: true,
  requireLoggedIn: true,
}, {
  path: '/side/:sideId',
  component: Side,
  exact: true,
  requireLoggedIn: true,
}, {
  path: '/sales/:date?',
  component: Sales,
  exact: true,
  requireLoggedIn: true,
}, {
  path: '/sales/:date/reviews/:mealId',
  component: ReviewsList,
  exact: true,
  requireLoggedIn: true,
}, {
  path: '/settings',
  component: Settings,
  exact: true,
  requireLoggedIn: true,
}]
