import React from 'react'

const SvgComponent = props => (
  <svg width={16} height={16} fill="none" {...props}>
    <path
      d="M7.133 2.668H2.471a1.331 1.331 0 00-1.332 1.333v9.334a1.334 1.334 0 001.332 1.333h9.324a1.331 1.331 0 001.332-1.333V8.668"
      stroke="#A8B2B9"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.129 1.668a1.412 1.412 0 012.412 1c0 .375-.149.735-.414 1L7.8 10.001l-2.664.667.666-2.667 6.327-6.333z"
      stroke="#A8B2B9"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgComponent
