import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ls from 'local-storage'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import promiseMap from '../utils/promiseMap'
import {
  useLoginLink,
  getProfile,
  getMyShops,
  getMessages,
} from '../redux/api/actions'
import {
  setShop,
  setRedirectTo,
  setMessagesBadge,
} from '../redux/features/actions'

const styles = theme => ({
  progress: {
    position: 'absolute',
    top: 'calc(50% - 20px)',
    left: 'calc(50% - 20px)',
  }
})

class LoginLink extends Component {
  async componentDidMount() {
    if (!this.props.match.params.code) {
      this.props.history.push('/login')
    }

    const loginLink = await this.props.useLoginLink(this.props.match.params.code)

    if (loginLink && loginLink.code) {
      const results = await promiseMap({
        profile: this.props.getProfile(),
        myShops: this.props.getMyShops(),
      })

      if (results.profile && results.myShops && results.myShops.length > 0) {
        const savedShopId = ls('shopId')
        const savedShop = results.myShops.find(shop => shop.id === savedShopId)

        const selectedShop = savedShop || results.myShops[0]

        const messagesResult = await this.props.getMessages(selectedShop.id, 0)

        this.props.setMessagesBadge(messagesResult.unreadCount)

        this.props.setShop(selectedShop)

        this.props.history.push(loginLink.destination || '/dashboard')
      }
    } else {
      this.props.history.push('/login')
    }
  }

  render() {
    return (
      <CircularProgress className={this.props.classes.progress} />
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {
  useLoginLink,
  getProfile,
  getMyShops,
  getMessages,
  setShop,
  setRedirectTo,
  setMessagesBadge,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(LoginLink)))
