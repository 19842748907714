import React from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

/*
 *   Made mostly according to: https://developers.line.biz/en/docs/line-login/login-button/
 */

const ICON_SIZE = 44
const ICON_MARGIN = 8

export default withStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    boxShadow: 'none',
    backgroundColor: '#00C300',
    fontFamily: 'Helvetica',
    fontWeight: 'bold',
    color: '#FFFFFF',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#00E000',
      '& div': {
        borderRightColor: '#00C900',
      },
    },
    borderRadius: 6,
  },
  icon: {
    backgroundImage: 'url(https://potluck-webapp.s3-ap-northeast-1.amazonaws.com/line-icon.png)',
    backgroundSize: 'contain',
    height: ICON_SIZE,
    width: ICON_SIZE,
    borderRight: '1px solid #00B300',
  },
  contents: {
    marginLeft: ICON_SIZE - ICON_MARGIN,
    marginRight: ICON_SIZE - ICON_MARGIN,
  },
})(({ classes, children, className, onClick, ...rest }) => (
  <Button
    {...rest}
    variant="contained"
    size="large"
    className={classNames(classes.container, className)}
    onClick={onClick}>
    <div className={classes.icon} />
    <div className={classes.contents}>
      {children}
    </div>
  </Button>
))
