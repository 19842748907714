import React from 'react'

const SvgComponent = props => (
  <svg width={81} height={80} fill="none" {...props}>
    <path
      d="M72.03 71.458H8.98a4.202 4.202 0 01-3.612-2.078 4.14 4.14 0 01-.012-4.17L36.9 10.624a4.148 4.148 0 013.602-2.082c1.486 0 2.87.798 3.61 2.084L75.57 65.074a4.175 4.175 0 01-3.54 6.384zM40.396 12.624h-.034L8.824 67.202l.156.258h63.05c.088 0 .174-.086.174-.172-.014-.022-.084-.192-.098-.214l-31.46-54.45h-.25z"
      fill="#FF6069"
    />
    <path
      d="M38.558 31.098V48.5a2.272 2.272 0 004.54 0V31.098a2.28 2.28 0 00-2.27-2.278 2.283 2.283 0 00-2.27 2.278zM40.83 53.798a2.27 2.27 0 00-2.27 2.264v1.522a2.28 2.28 0 002.27 2.264 2.276 2.276 0 002.27-2.264v-1.522a2.268 2.268 0 00-2.27-2.264z"
      fill="#FF6069"
    />
  </svg>
)

export default SvgComponent
