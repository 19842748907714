export const SET_SHOP = 'SET_SHOP'
export const SET_LOCALE = 'SET_LOCALE'
export const SET_FLAG = 'SET_FLAG'
export const SET_REDIRECT_TO = 'SET_REDIRECT_TO'
export const CLEAR_REDIRECT_TO = 'CLEAR_REDIRECT_TO'
export const SET_MESSAGES_BADGE = 'SET_MESSAGES_BADGE'
export const SET_DASHBOARD_DATE = 'SET_DASHBOARD_DATE'
export const SET_HOURS_DATE = 'SET_HOURS_DATE'
export const SET_MENU_SHOP_SELECTION = 'SET_MENU_SHOP_SELECTION'

export const setShop = shop => ({ type: SET_SHOP, shop })
export const setLocale = locale => ({ type: SET_LOCALE, locale })
export const setFlag = (name, value) => ({ type: SET_FLAG, name, value })
export const setRedirectTo = redirectTo => ({ type: SET_REDIRECT_TO, redirectTo })
export const clearRedirectTo = () => ({ type: CLEAR_REDIRECT_TO })
export const setMessagesBadge = messagesBadge => ({ type: SET_MESSAGES_BADGE, messagesBadge })
export const setDashboardDate = dashboardDate => ({ type: SET_DASHBOARD_DATE, dashboardDate })
export const setHoursDate = hoursDate => ({ type: SET_HOURS_DATE, hoursDate })
export const setMenuShopSelection = menuShopSelection => ({ type: SET_MENU_SHOP_SELECTION, menuShopSelection })
