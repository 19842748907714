import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import {
  Field,
  reduxForm,
  SubmissionError,
} from 'redux-form'
import {
  withRouter,
} from 'react-router-dom'
import {
  injectIntl,
  FormattedMessage,
} from 'react-intl'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Collapse from '@material-ui/core/Collapse'
import green from '@material-ui/core/colors/green'
import CheckRoundedIcon from '@material-ui/icons/CheckRounded'
import {
  requestPasswordResetLink,
} from '../redux/api/actions'
import FormField from '../components/FormField/FormField'
import Logo from '../components/Logo'
import validate from '../utils/validators/loginValidator'

const styles = theme => ({
  container: {
  },
  content: {
    maxWidth: theme.spacing(40),
    marginTop: theme.spacing(10),
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(2),
  },
  logoContainer: {
    position: 'relative',
    height: 50,
    width: 200,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(4),
  },
  logo: {
    width: '100%',
    height: '100%',
  },
  logoText: {
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
  cta: {},
  ctaSuccess: {
    backgroundColor: green[500],
    boxShadow: `0px 8.72651px 17.453px ${green[500]}`,
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  ctaProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

class ForgotPassword extends Component {
  state = {
    loading: false,
    success: false,
    showCta: true,
  }

  onClickSubmit = async values => {
    this.setState({ loading: true })

    const response = await this.props.requestPasswordResetLink(values.email)

    if (response && response.error) {
      this.setState({
        loading: false,
        success: false,
        showCta: true,
      })

      throw new SubmissionError({
        email: response.error,
      })
    }

    this.setState({
      loading: false,
      success: true,
    })

    setTimeout(() => {
      this.setState({
        showCta: false,
      })
    }, 500)
  }

  render() {
    const {
      handleSubmit,
      invalid,
      error,
    } = this.props

    return (
      <div className={this.props.classes.container}>
        <Paper classes={{
          root: this.props.classes.content,
        }}>
          <div className={this.props.classes.logoContainer}>
            <Logo className={this.props.classes.logo} />
            <Typography
              variant="body1"
              color="primary"
              align="right"
              style={styles.logoText}>
              <FormattedMessage
                id="forgot-password.shops"
                defaultMessage="Shops" />
            </Typography>
          </div>
          <form>
            {error && (
              <Typography
                variant="caption"
                color="error">
                {error}
              </Typography>
            )}
            <Field
              name="email"
              type="email"
              component={FormField}
              label={(
                <FormattedMessage
                  id="forgot-password.email"
                  defaultMessage="Email" />
              )} />
            <Collapse in={this.state.showCta}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                className={classNames(this.props.classes.cta, {
                  [this.props.classes.ctaSuccess]: this.state.success,
                })}
                disabled={invalid || this.state.loading}
                onClick={handleSubmit(this.onClickSubmit)}>
                {this.state.loading && (
                  <CircularProgress
                    size={24}
                    color="primary"
                    className={this.props.classes.ctaProgress} />
                )}
                {!this.state.success && (
                  <FormattedMessage
                    id="forgot-password.cta"
                    defaultMessage="Send reset link" />
                )}
                {this.state.success && (<CheckRoundedIcon />)}
              </Button>
            </Collapse>
            <Collapse in={!this.state.showCta}>
              <Typography variant="body1">
                <FormattedMessage
                  id="forgot-password.check-email"
                  defaultMessage="Please check hour email for a password reset link." />
              </Typography>
            </Collapse>
          </form>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = {
  requestPasswordResetLink,
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'forgot-password',
  validate,
})(withRouter(injectIntl(withStyles(styles)(ForgotPassword)))))
