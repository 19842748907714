import React from 'react'

const SvgComponent = ({ color, ...rest }) => (
  <svg width="1em" height="1em" viewBox="0 0 19 19" fill="none" {...rest}>
    <path
      d="M6.364 2.783H3.09v13.51c0 .623.487 1.126 1.09 1.126h10.91c.603 0 1.09-.503 1.09-1.126V2.783H12.91"
      stroke={color || '#A8B2B9'}
      strokeWidth={2.182}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.364 1.658h6.545v2.251c0 .623-.487 1.126-1.09 1.126H7.454c-.604 0-1.091-.503-1.091-1.126V1.658M11.818 9.537l-3.272 3.377-1.091-1.125"
      stroke={color || '#A8B2B9'}
      strokeWidth={2.182}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgComponent
