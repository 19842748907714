import React from 'react'

const SvgComponent = props => (
  <svg width={116} height={116} fill="none" {...props}>
    <circle cx={58} cy={58} r={56.5} stroke="#FF6069" strokeWidth={3} />
    <path
      d="M32.412 65.25L44.78 77.616l38.382-38.383"
      stroke="#FF6069"
      strokeWidth={3}
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgComponent
