import canUseDOM from './canUseDOM'

export default () => {
  if (canUseDOM()) {
    return window.location.host === 'shops.pot-luck.jp' ? 'prod' : 'dev'
    // return window.ENV
  }

  return process.env.ENVIRONMENT
}
