import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import qs from 'query-string'
import classNames from 'classnames'
import {
  injectIntl,
  defineMessages,
  FormattedMessage,
} from 'react-intl'
import { withRouter } from 'react-router-dom'
import moment from 'moment-timezone'
import { throttle } from 'throttle-debounce'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import ButtonBase from '@material-ui/core/ButtonBase'
import Divider from '@material-ui/core/Divider'
import Tooltip from '@material-ui/core/Tooltip'
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded'
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded'
import DirectionsBikeRoundedIcon from '@material-ui/icons/DirectionsBikeRounded'
import {
  setDashboardDate,
} from '../redux/features/actions'
import {
  getReservationsForDate,
  getOrderConfirmations,
  createOrderConfirmation,
  getShopHoursHistory,
} from '../redux/api/actions'
import ReservationProblemsDialog from './dialogs/ReservationProblemsDialog'
import EmptyReservationsIcon from '../components/icons/EmptyReservationsIcon'
import ManBowingIcon from '../components/icons/ManBowingIcon'
import PaidOptionIcon from '../components/icons/PaidOptionIcon'
import promiseMap from '../utils/promiseMap'
import getActiveMeal from '../utils/getActiveMeal'
import Constants from '../utils/Constants'

const DATE_FORMAT = 'YYYYMMDD'
const EARLIEST_DATE = moment('20180801')
const THROTTLE_DELAY = 500

const CONFIRM_FLAG = 'confirmReservations'

const messages = defineMessages({
  delivery: {
    id: 'dashboard.delivery',
    defaultMessage: 'Delivery',
  },
  hoursClosed: {
    id: 'dashboard.hours-closed',
    defaultMessage: 'Closed',
  },
  hoursLunch: {
    id: 'dashboard.hours-lunch',
    defaultMessage: 'Lunch ({lunchHours})',
  },
  hoursDinner: {
    id: 'dashboard.hours-dinner',
    defaultMessage: 'Dinner ({dinnerHours})',
  },
  hoursLunchDinner: {
    id: 'dashboard.hours-lunch-dinner',
    defaultMessage: 'Lunch・Dinner ({lunchHours} | {dinnerHours})',
  },
  finalizedStatus: {
    id: 'dashboard.finalized-status',
    defaultMessage: 'Status: Finalized',
  },
  notFinalizedStatus: {
    id: 'dashboard.not-finalized-status',
    defaultMessage: 'Status: Not finalized (reservations may change)',
  },
  reservationsCount: {
    id: 'dashboard.reservations-count',
    defaultMessage: 'Reservations: {count}',
  },
  active: {
    id: 'dashboard.active',
    defaultMessage: 'Active',
  },
  deleted: {
    id: 'dashboard.deleted',
    defaultMessage: 'Deleted (not paid)',
  },
  canceled: {
    id: 'dashboard.canceled',
    defaultMessage: 'User canceled (still paid)',
  },
  sides: {
    id: 'dashboard.sides',
    defaultMessage: 'Sides',
  },
})

const getStatusForReservation = reservation => {
  let status = messages.active

  if (reservation.deleted) {
    status = messages.deleted
  } else if (reservation.canceled) {
    status = messages.canceled
  }

  return status
}

const ReservationRow = withStyles(theme => ({
  container: {
    marginBottom: 6,
    border: '1px solid #EFEFEF',
    borderRadius: 4,
    boxShadow: '0px 3.27px 12px rgba(189, 159, 189, 0.11)',
    cursor: 'pointer',
  },
  reservation: {
    width: '100%',
    display: 'flex',
    backgroundColor: 'white',
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      textAlign: 'left',
    },
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  reservationCanceled: {
    background: '#EFEFEF',
    boxShadow: 'none',
    cursor: 'default',
  },
  reservationIndexText: {
    fontSize: 14,
    color: '#A8B2B9',
    minWidth: 70,
    marginRight: 4,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  orderNumberText: {
    fontSize: 14,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    minWidth: 70,
    marginRight: 4,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  orderType: {
    width: 60,
    height: 20,
  },
  deliveryIcon: {
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      top: theme.spacing(3),
      right: theme.spacing(3),
    },
  },
  reservationPickupText: {
    fontSize: 14,
    color: '#292529',
    fontWeight: 600,
    minWidth: 70,
    marginRight: 4,
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing(),
    },
  },
  reservationMealNameText: {
    fontSize: 14,
    color: '#292529',
    fontWeight: 600,
    flex: 1,
    marginRight: 4,
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(),
    },
  },
  reservationDetails: {
    display: 'flex',
    flex: 1,
  },
  reservationUser: {
    fontSize: 14,
    color: '#292529',
    flex: 1,
    marginRight: 4,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  reservationUserCountText: {
    fontSize: 14,
    color: '#292529',
    minWidth: 70,
    marginRight: 4,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  reservationStatusText: {
    fontSize: 14,
    color: '#292529',
    minWidth: 70,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  reservationStatusCanceledText: {
    color: '#ED5454',
    fontWeight: 600,
  },
  notes: {
    backgroundColor: '#F5F5F5',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  note: {
    paddingLeft: theme.spacing(12),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
    },
  },
  noteName: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#232323',
  },
  noteValue: {
    fontSize: 12,
    color: '#232323',
  },
  optionBox: {
    display: "flex",
    flexDirection: "row"
  },
  paidStamp: {
    backgroundColor: "#FF6069",
    padding: 8,
    marginLeft: 16,
    height: 36,
    borderRadius: 30,
    alignItems: "center",
  },
  paidStampText: {
    color: "#FFFFFF",
    margin: "auto"
  },
}))(({ reservation, index, lunchHours, dinnerHours, intl, onClick, classes }) => {
  let notes = []

  if (reservation.options && reservation.options.length > 0) {
    notes = notes.concat(reservation.options.map(option => ({
      name: option.name,
      value: option.value,
      cost: option.yenCost,
    })))
  }

  if (reservation.sides && reservation.sides.length > 0) {
    notes.push({
      name: intl.formatMessage(messages.sides),
      value: reservation.sides.map(side => side.name).join(', '),
    })
  }

  return (
    <div className={classes.container}>
      <ButtonBase disabled={reservation.canceled}
        className={classNames(classes.reservation, {
          [classes.reservationCanceled]: reservation.canceled,
        })}
        onClick={() => onClick(reservation)}>
        {reservation.orderNumber ? (
          <Typography
            variant="body1"
            color="primary"
            className={classes.orderNumberText}>
            #{reservation.orderNumber}
          </Typography>
        ) : (
          <Typography
            variant="body1"
            className={classes.reservationIndexText}>
            {index + 1}
          </Typography>
        )}
        <div className={classes.orderType}>
          {reservation.deliveryDetails && (
            <Tooltip
              title={intl.formatMessage(messages.delivery)}
              aria-label={intl.formatMessage(messages.delivery)}>
              <DirectionsBikeRoundedIcon
                color="primary"
                className={classes.deliveryIcon} />
            </Tooltip>
          )}
        </div>
        <Typography
          variant="body1"
          className={classes.reservationPickupText}>
          {!reservation.pickup.potluckNow && reservation.pickup.time}
          {reservation.pickup.potluckNow && reservation.type === 'lunch' && (lunchHours || 'NOW')}
          {reservation.pickup.potluckNow && reservation.type === 'dinner' && (dinnerHours || 'NOW')}
        </Typography>
        <Typography
          variant="body1"
          className={classes.reservationMealNameText}>
          {reservation.meal.name}
        </Typography>
        <div className={classes.reservationDetails}>
          <Typography
            variant="body1"
            className={classes.reservationUser}>
            {reservation.user}
          </Typography>
          <Typography
            variant="body1"
            className={classes.reservationUserCountText}>
            <FormattedMessage
              id="dashboard.order-index"
              defaultMessage="{index, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} visit"
              values={{
                index: reservation.orderIndex,
              }} />
          </Typography>
          <Typography
            variant="body1"
            className={classNames(classes.reservationStatusText, {
              [classes.reservationStatusCanceledText]: reservation.canceled,
            })}>
            {intl.formatMessage(getStatusForReservation(reservation))}
          </Typography>
        </div>
      </ButtonBase>
      {notes.length > 0 && (
        <div className={classes.notes}>
          {notes.map(note => (
            <div className={classes.optionBox}>
              <div 
                key={note.name}
                className={classes.note}>
                <Typography
                  variant="body1"
                  className={classes.noteName}>
                  {note.name}
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.noteValue}>
                  ・{note.value}
                </Typography>
              </div>
              {note.cost > 0 && 
              <div className={classes.paidStamp}>
                <p className={classes.paidStampText}>支払い済み</p>
              </div>
            }
            </div>
          ))}
        </div>
      )}
    </div>
  )
})


const DashboardPrint = withStyles(theme => ({
  container: {
    textAlign: 'center',
  },
  logo: {
    height: 50,
    width: 200,
    margin: '20px auto',
    backgroundImage: 'url(https://s3-ap-northeast-1.amazonaws.com/potluck-shops/logo.png)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  title: {
    fontSize: 16,
  },
  subtitle: {
    fontSize: 12,
  },
  list: {
    margin: 20,
    padding: '15px 0',
    borderRadius: 4,
    backgroundColor: 'white',
    '& table': {
      width: '100%',
      borderColor: '#dfe8f1',
      borderStyle: 'solid',
      borderWidth: '1px 0 1px 1px',
      borderRadius: 4,
      borderSpacing: 0,
      borderCollapse: 'separate',
    },
    '& thead': {
      backgroundColor: 'rgba(249, 250, 254, 1)',
    },
    '& tr': {
      cursor: 'pointer',
    },
    '& td': {
      borderColor: '#dfe8f1',
      borderStyle: 'solid',
      borderWidth: '0 1px 0 0',
      padding: 10,
      textAlign: 'left',
    },
    '& tbody td': {
      borderWidth: '1px 1px 0 0',
    },
  },
  smallColumn: {
    width: 100,
  },
  orderNumber: {
    fontSize: 14,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  optionName: {
    fontWeight: 'bold',
  },
}))(({ intl, date, reservations, lunchHours, dinnerHours, classes, ...rest }) => (
  <div className={classes.container}>
    <div className={classes.logo} />
    <div className={classes.title}>
      <FormattedMessage
        id="dashboard-print.header"
        defaultMessage="Potluck Orders" />
    </div>
    <div className={classes.subtitle}>
      {date.format('M/D (ddd)')}
    </div>
    <div className={classes.list}>
      <table>
        <thead>
          <tr>
            <td>
              <FormattedMessage
                id="dashboard-print.id"
                defaultMessage="#" />
            </td>
            <td>
              <FormattedMessage
                id="dashboard-print.time"
                defaultMessage="Time" />
            </td>
            <td>
              <FormattedMessage
                id="dashboard-print.item"
                defaultMessage="Item" />
            </td>
            <td>
              <FormattedMessage
                id="dashboard-print.user"
                defaultMessage="User" />
            </td>
            <td className={classes.smallColumn}>
              <FormattedMessage
                id="dashboard-print.completed"
                defaultMessage="Completed" />
            </td>
          </tr>
        </thead>
        <tbody>
          {reservations.map((reservation, index) => {
            let notes = []

            if (reservation.options && reservation.options.length > 0) {
              notes = notes.concat(reservation.options.map(option => ({
                name: option.name,
                value: option.value,
              })))
            }

            if (reservation.sides && reservation.sides.length > 0) {
              notes.push({
                name: intl.formatMessage(messages.sides),
                value: reservation.sides.map(side => side.name).join(', '),
              })
            }

            if (reservation.deliveryDetails) {
              notes.push({
                name: intl.formatMessage(messages.delivery),
              })
            }

            return (
              <tr key={reservation.id}>
                {reservation.orderNumber ? (
                  <td className={classes.orderNumber}>{reservation.orderNumber}</td>
                ) : (
                  <td>{index + 1}</td>
                )}
                <td>
                  {!reservation.pickup.potluckNow && reservation.pickup.time}
                  {reservation.pickup.potluckNow && reservation.type === 'lunch' && (lunchHours || 'NOW')}
                  {reservation.pickup.potluckNow && reservation.type === 'dinner' && (dinnerHours || 'NOW')}
                </td>
                <td>
                  {reservation.meal.name}
                  {notes.length > 0 && [(
                    <br key="break" />
                  ), (
                    <ul key="options">
                      {notes.map(note => {
                        if (note.value) {
                          return (
                            <div key={note.name}>
                              <span className={classes.optionName}>{note.name}</span>: {note.value}
                            </div>
                          )
                        }

                        return (
                          <div key={note.name}>
                            <span className={classes.optionName}>{note.name}</span>
                          </div>
                        )
                      })}
                    </ul>
                  )]}
                </td>
                <td>{reservation.user}</td>
                <td>
                  <input type="checkbox" />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  </div>
))



const fetchData = (dispatch, params, shop) => promiseMap({
  reservationsForDate: dispatch(getReservationsForDate(shop.id, params.date)),
  myShopHoursHistory: dispatch(getShopHoursHistory(shop.id)),
  orderConfirmations: dispatch(getOrderConfirmations(shop.id)),
})

const _fetchDataThrottled = throttle(THROTTLE_DELAY, fetchData)

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  topBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #D8D8D8',
    paddingBottom: theme.spacing(2),
  },
  topBarLeft: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      flex: 1,
    },
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  title: {
    fontSize: 24,
    display: 'flex',
    alignItems: 'center',
    color: '#535353',
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'flex-end',
    letterSpacing: '-0.112198px',
    color: '#8A888A',
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      marginLeft: 0,
    },
  },
  pageButtons: {
    alignSelf: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  pageButtonsMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  reservationTitle: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    color: '#292529',
    marginTop: theme.spacing(2),
  },
  ctaProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  emptyContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(10),
  },
  emptyText: {
    fontSize: 18,
    lineHeight: '36px',
    color: '#A8B2B9',
    marginTop: theme.spacing(),
  },
  loadingContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(10),
  },
  reservationsContainer: {

  },
  reservationsActionsBar: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  reservationsActionsBarLeft: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  orderConfirmationText: {
    fontSize: 15,
    color: '#232323',
  },
  reservationProblemsButton: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(),
    },
  },
  reservationsActionsPrint: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  reservations: {
    marginTop: theme.spacing(2),
  },
  reservationContainer: {
    textDecoration: 'none',
  },
  reservationSeparator: {
    margin: theme.spacing(2, 0),
  },
})

class Dashboard extends Component {
  state = {
    reservationProblemsDialogOpen: false,
    createOrderConfirmationLoadingStates: {},
  }

  constructor(props) {
    super(props)

    let date = this.props.match.params.date

    if (!date) {
      date = this.props.dashboardDate

      this.props.history.push(`/dashboard/${date.format(DATE_FORMAT)}`)
    } else {
      date = moment(date, DATE_FORMAT)
      props.setDashboardDate(date)
    }
  }

  hasLoaded = () => {
    return (this.props.reservationsForDate && this.props.reservationsForDate.loaded)
      && this.props.myShopHoursHistory.loaded
  }

  isLoading = () => {
    return (!this.props.reservationsForDate || this.props.reservationsForDate.loading)
      || this.props.myShopHoursHistory.loading
  }

  onClickDatePrevious = () => {
    const date = this.props.dashboardDate.clone().subtract(1, 'day')
    this.props.history.push(`/dashboard/${date.format(DATE_FORMAT)}`)

    this.props.setDashboardDate(date)
  }

  onClickDateNext = () => {
    const date = this.props.dashboardDate.clone().add(1, 'day')
    this.props.history.push(`/dashboard/${date.format(DATE_FORMAT)}`)

    this.props.setDashboardDate(date)
  }

  onClickPrint = () => {
    window.print()
  }

  onClickCreateOrderConfirmation = async () => {
    this.setState({
      createOrderConfirmationLoadingStates: {
        ...this.state.createOrderConfirmationLoadingStates,
        [this.props.dashboardDate.format(DATE_FORMAT)]: true,
      },
    })

    const now = moment()
    const dinnerOpen = this.props.dashboardDate.clone().set(Constants.DINNER_LAST_CALL)
    let type = 'lunch'

    if (now.isAfter(dinnerOpen)) {
      type = 'dinner'
    }

    await this.props.createOrderConfirmation(this.props.shop.id, {
      date: this.props.dashboardDate.format(DATE_FORMAT),
      type,
    })
    await this.props.getOrderConfirmations(this.props.shop.id)

    this.setState({
      createOrderConfirmationLoadingStates: {
        ...this.state.createOrderConfirmationLoadingStates,
        [this.props.dashboardDate.format(DATE_FORMAT)]: false,
      },
    })
  }

  onClickOpenReservationProblemsDialog = () => {
    this.setState({
      reservationProblemsDialogOpen: true,
    })
  }

  onCloseOpenReservationProblemsDialog = () => {
    this.setState({
      reservationProblemsDialogOpen: false,
    })
  }

  onClickReservation = reservation => {
    if (!reservation.canceled) {
      this.props.history.push(`/reservations/${reservation.id}`)
    }
  }

  componentWillMount() {
    if (this.props.shop && !this.hasLoaded()) {
      fetchData(this.props.dispatch, {
        date: this.props.dashboardDate.format(DATE_FORMAT),
      }, this.props.shop)
    }

    const query = qs.parse(this.props.location.search)

    if (Object.keys(query).includes(CONFIRM_FLAG)) {
      this.onClickCreateOrderConfirmation()
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.shop) {
      if (!prevProps.shop || (prevProps.shop.id !== this.props.shop.id)) {
        this.setState({
          loading: true,
        })
        await fetchData(this.props.dispatch, this.props.match.params, this.props.shop)

        this.setState({
          loading: false,
        })
      }

      if (prevProps.dashboardDate.format() !== this.props.dashboardDate.format()) {
        _fetchDataThrottled(this.props.dispatch, {
          date: this.props.dashboardDate.format(DATE_FORMAT),
        }, this.props.shop)
      }
    }
  }

  render() {
    let reservations = []
    let lunchReservations = []
    let dinnerReservations = []
    let lunchHours
    let dinnerHours

    let hoursText = this.props.intl.formatMessage(messages.hoursClosed)

    const now = moment()
    const lunchOpen = this.props.dashboardDate.clone().set(Constants.LUNCH_LAST_CALL)
    const dinnerOpen = this.props.dashboardDate.clone().set(Constants.DINNER_LAST_CALL)
    const activeMeal = getActiveMeal()
    let hasLunchOrderConfirmation = false
    let hasDinnerOrderConfirmation = false
    let canClickDatePrevious = this.props.dashboardDate.isAfter(EARLIEST_DATE, 'day')
    let canClickDateNext = this.props.dashboardDate.isBefore(activeMeal.date, 'day')
    let canClickLunchCreateOrderConfirmation = false
    let canClickLunchCreateReservationProblem = false
    let canClickDinnerCreateOrderConfirmation = false
    let canClickDinnerCreateReservationProblem = false

    let isLunchFinalized = false
    let isDinnerFinalized = false

    if (this.props.match.params.date) {
      isLunchFinalized = now.isSameOrAfter(lunchOpen)
      isDinnerFinalized = now.isSameOrAfter(dinnerOpen)
    }

    if (this.props.reservationsForDate && this.props.reservationsForDate.data) {
      reservations = this.props.reservationsForDate.data.map((reservation, index) => ({
        index,
        ...reservation,
      }))
      reservations.sort((first, second) => {
        if (first.pickup.potluckNow && !second.pickup.potluckNow) {
          return 1
        }

        if (second.pickup.potluckNow && !first.pickup.potluckNow) {
          return -1
        }

        let firstPickup = moment(`${first.pickup.date} ${first.pickup.time}`, 'YYYYMMDD hh:mm')
        let secondPickup = moment(`${second.pickup.date} ${second.pickup.time}`, 'YYYYMMDD hh:mm')

        return firstPickup.isBefore(secondPickup) ? -1 : 1
      })

      lunchReservations = reservations.filter(reservation => reservation.type === 'lunch')
      dinnerReservations = reservations.filter(reservation => reservation.type === 'dinner')

      if (lunchReservations.length > 0 && now.isBetween(lunchOpen, moment(`${lunchReservations[0].pickup.date} ${lunchReservations[0].pickup.time}`, 'YYYYMMDD hh:mm'))) {
        canClickLunchCreateOrderConfirmation = true
        canClickLunchCreateReservationProblem = true
      }

      if (dinnerReservations.length > 0 && now.isBetween(dinnerOpen, moment(`${dinnerReservations[0].pickup.date} ${dinnerReservations[0].pickup.time}`, 'YYYYMMDD hh:mm'))) {
        canClickDinnerCreateOrderConfirmation = true
        canClickDinnerCreateReservationProblem = true
      }
    }

    if (this.props.dashboardDate && this.props.myShopHoursHistory.data) {
      const range = this.props.myShopHoursHistory.data.find(({ startsAt, endsAt }) => this.props.dashboardDate.isBetween(moment(startsAt, DATE_FORMAT), moment(endsAt, DATE_FORMAT), null, '[)'))

      if (range) {
        const dayName = this.props.dashboardDate.clone().locale('en').format('dddd').toLowerCase()
        lunchHours = range.hours.lunch[dayName]
        dinnerHours = range.hours.dinner[dayName]

        if (lunchHours && dinnerHours) {
          hoursText = this.props.intl.formatMessage(messages.hoursLunchDinner, {
            lunchHours,
            dinnerHours
          })
        } else if (lunchHours) {
          hoursText = this.props.intl.formatMessage(messages.hoursLunch, { lunchHours })
        } else if (dinnerHours) {
          hoursText = this.props.intl.formatMessage(messages.hoursDinner, { dinnerHours })
        } else {
          hoursText = this.props.intl.formatMessage(messages.hoursClosed)
        }
      }
    }

    if (this.props.orderConfirmations.data) {
      if (now.isAfter(lunchOpen)) {
        hasLunchOrderConfirmation = this.props.orderConfirmations.data.some(orderConfirmation => orderConfirmation.type === 'lunch' && moment(orderConfirmation.date).isSame(this.props.dashboardDate, 'day'))
      }

      if (now.isAfter(dinnerOpen)) {
        hasDinnerOrderConfirmation = this.props.orderConfirmations.data.some(orderConfirmation => orderConfirmation.type === 'dinner' && moment(orderConfirmation.date).isSame(this.props.dashboardDate, 'day'))
      }
    }

    return [(
      <style
        key="dashboard-style-switch"
        dangerouslySetInnerHTML = {{__html: `
          #dashboard-print {
            display: none;
          }

          #dashboard-desktop {
            display: block;
          }

          @media print {
            #dashboard-print {
              display: block;
            }

            #dashboard-desktop {
              display: none;
            }
          }
        `}} />
    ), (
      <div
        key="dashboard-print"
        id="dashboard-print">
        <DashboardPrint
          intl={this.props.intl}
          date={this.props.dashboardDate}
          reservations={reservations.filter(reservation => !reservation.deleted && !reservation.canceled)}
          lunchHours={lunchHours}
          dinnerHours={dinnerHours} />
      </div>
    ), (
      <div
        key="dashboard-desktop"
        id="dashboard-desktop"
        className={this.props.classes.container}>
        <ReservationProblemsDialog
          open={this.state.reservationProblemsDialogOpen}
          onClose={this.onCloseOpenReservationProblemsDialog} />
        <div className={this.props.classes.topBar}>
          <div className={this.props.classes.topBarLeft}>
            <div className={this.props.classes.titleContainer}>
              <Typography
                variant="h6"
                className={this.props.classes.title}>
                <FormattedMessage
                  id="dashboard.title"
                  defaultMessage="{date} ({dayName})"
                  values={{
                    date: this.props.dashboardDate.format('M/D'),
                    dayName: this.props.dashboardDate.format('ddd'),
                  }} />
              </Typography>
              <div className={classNames(this.props.classes.pageButtons, this.props.classes.pageButtonsMobile)}>
                <IconButton
                  size="small"
                  disabled={!canClickDatePrevious}
                  onClick={this.onClickDatePrevious}>
                  <NavigateBeforeRoundedIcon />
                </IconButton>
                <IconButton
                  size="small"
                  disabled={!canClickDateNext}
                  onClick={this.onClickDateNext}>
                  <NavigateNextRoundedIcon />
                </IconButton>
              </div>
            </div>
            <Typography
              variant="body1"
              className={this.props.classes.subtitle}>
              {hoursText}
            </Typography>
          </div>
          <div className={this.props.classes.pageButtons}>
            <IconButton
              size="small"
              disabled={!canClickDatePrevious}
              onClick={this.onClickDatePrevious}>
              <NavigateBeforeRoundedIcon />
            </IconButton>
            <IconButton
              size="small"
              disabled={!canClickDateNext}
              onClick={this.onClickDateNext}>
              <NavigateNextRoundedIcon />
            </IconButton>
          </div>
        </div>
        <Typography
          variant="body1"
          className={this.props.classes.reservationTitle}>
          <FormattedMessage
            id="dashboard.lunch"
            defaultMessage="Lunch" />
        </Typography>
        {!isLunchFinalized && (
          <Typography variant="body2">
            {this.props.intl.formatMessage(messages.notFinalizedStatus)}
          </Typography>
        )}
        {isLunchFinalized && (
          <Typography variant="body2">
            {this.props.intl.formatMessage(messages.finalizedStatus)}
          </Typography>
        )}
        <Typography variant="body2">
          {this.props.intl.formatMessage(messages.reservationsCount, {
            count: lunchReservations.length,
          })}
        </Typography>
        {this.isLoading() && (
          <div className={this.props.classes.loadingContainer}>
            <CircularProgress />
          </div>
        )}
        {!this.isLoading() && this.hasLoaded() && reservations.length === 0 && (
          <div className={this.props.classes.emptyContainer}>
            <EmptyReservationsIcon />
            <Typography
              variant="body1"
              className={this.props.classes.emptyText}>
              <FormattedMessage
                id="dashboard.no-reservations"
                defaultMessage="No reservations" />
            </Typography>
          </div>
        )}
        {!this.isLoading() && this.hasLoaded() && reservations.length > 0 && (
          <div className={this.props.classes.reservationsContainer}>
            {lunchReservations.length > 0 && [(
              <div
                id="action-bar"
                className={this.props.classes.reservationsActionsBar}>
                <div className={this.props.classes.reservationsActionsBarLeft}>
                  {hasLunchOrderConfirmation && (
                    <Typography
                      variant="body1"
                      className={this.props.classes.orderConfirmationText}>
                      <FormattedMessage
                        id="dashboard.reservations-acknowledged"
                        defaultMessage="Acknowledged! Thank you for your time today" />
                      <ManBowingIcon />
                    </Typography>
                  )}
                  {!hasLunchOrderConfirmation && [(
                    <Button
                      key="create-order-confitmation-cta"
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={!canClickLunchCreateOrderConfirmation || this.state.createOrderConfirmationLoadingStates[this.props.dashboardDate.format(DATE_FORMAT)]}
                      onClick={this.onClickCreateOrderConfirmation}>
                      <FormattedMessage
                        id="dashboard.mark-as-seen"
                        defaultMessage="Mark as seen" />
                      {this.state.createOrderConfirmationLoadingStates[this.props.dashboardDate.format(DATE_FORMAT)] && (
                        <CircularProgress
                          size={24}
                          color="primary"
                          className={this.props.classes.ctaProgress} />
                      )}
                    </Button>
                  ), (
                    <Button
                      key="shop-problems-cta"
                      variant="text"
                      color="secondary"
                      size="large"
                      className={this.props.classes.reservationProblemsButton}
                      disabled={!canClickLunchCreateReservationProblem}
                      onClick={this.onClickOpenReservationProblemsDialog}>
                      <FormattedMessage
                        id="dashboard.report-a-problem"
                        defaultMessage="Report a problem" />
                    </Button>
                  )]}
                </div>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  className={this.props.classes.reservationsActionsPrint}
                  onClick={this.onClickPrint}>
                  <FormattedMessage
                    id="dashboard.print"
                    defaultMessage="Print" />
                </Button>
              </div>
            ), (
              <div
                id="reservations"
                className={this.props.classes.reservations}>
                {lunchReservations.map((reservation, index) => (
                  <ReservationRow
                    key={reservation.id}
                    index={index}
                    reservation={reservation}
                    lunchHours={lunchHours}
                    dinnerHours={dinnerHours}
                    intl={this.props.intl}
                    onClick={this.onClickReservation} />
                ))}
              </div>
            )]}
            {lunchReservations.length > 0 && dinnerReservations.length > 0 && (
              <Divider className={this.props.classes.reservationSeparator} />
            )}
            {dinnerReservations.length > 0 && [(
              <div>
                <Typography
                  variant="body1"
                  className={this.props.classes.reservationTitle}>
                  <FormattedMessage
                    id="dashboard.dinner"
                    defaultMessage="Dinner" />
                </Typography>
                {!isDinnerFinalized && (
                  <Typography variant="body2">
                    {this.props.intl.formatMessage(messages.notFinalizedStatus)}
                  </Typography>
                )}
                {isDinnerFinalized && (
                  <Typography variant="body2">
                    {this.props.intl.formatMessage(messages.finalizedStatus)}
                  </Typography>
                )}
                <Typography variant="body2">
                  {this.props.intl.formatMessage(messages.reservationsCount, {
                    count: dinnerReservations.length,
                  })}
                </Typography>
              </div>
            ), (
              <div
                id="action-bar"
                className={this.props.classes.reservationsActionsBar}>
                <div className={this.props.classes.reservationsActionsBarLeft}>
                {hasDinnerOrderConfirmation && (
                    <Typography
                      variant="body1"
                      className={this.props.classes.orderConfirmationText}>
                      <FormattedMessage
                        id="dashboard.reservations-acknowledged"
                        defaultMessage="Acknowledged! Thank you for your time today" />
                      <ManBowingIcon />
                    </Typography>
                  )}
                  {!hasDinnerOrderConfirmation && [(
                    <Button
                      key="create-order-confitmation-cta"
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={!canClickDinnerCreateOrderConfirmation || this.state.createOrderConfirmationLoadingStates[this.props.dashboardDate.format(DATE_FORMAT)]}
                      onClick={this.onClickCreateOrderConfirmation}>
                      <FormattedMessage
                        id="dashboard.mark-as-seen"
                        defaultMessage="Mark as seen" />
                      {this.state.createOrderConfirmationLoadingStates[this.props.dashboardDate.format(DATE_FORMAT)] && (
                        <CircularProgress
                          size={24}
                          color="primary"
                          className={this.props.classes.ctaProgress} />
                      )}
                    </Button>
                  ), (
                    <Button
                      key="shop-problems-cta"
                      variant="text"
                      color="secondary"
                      size="large"
                      className={this.props.classes.reservationProblemsButton}
                      disabled={!canClickDinnerCreateReservationProblem}
                      onClick={this.onClickOpenReservationProblemsDialog}>
                      <FormattedMessage
                        id="dashboard.report-a-problem"
                        defaultMessage="Report a problem" />
                    </Button>
                  )]}
                </div>
                {lunchReservations.length === 0 && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="large"
                    className={this.props.classes.reservationsActionsPrint}
                    onClick={this.onClickPrint}>
                    <FormattedMessage
                      id="dashboard.print"
                      defaultMessage="Print" />
                  </Button>
                )}
              </div>
            ), (
              <div
                id="reservations"
                className={this.props.classes.reservations}>
                {dinnerReservations.map((reservation, index) => (
                  <ReservationRow
                    key={reservation.id}
                    index={index}
                    reservation={reservation}
                    lunchHours={lunchHours}
                    dinnerHours={dinnerHours}
                    intl={this.props.intl}
                    onClick={this.onClickReservation} />
                ))}
              </div>
            )]}
          </div>
        )}
      </div>
    )]
  }
}

Dashboard.fetchData = fetchData


const mapStateToProps = state => {
  const dashboardDate = moment.tz(state.features.dashboardDate, 'Asia/Tokyo')

  return ({
    dashboardDate,
    shop: state.features.shop,
    myShopHoursHistory: state.api.myShopHoursHistory.default,
    reservationsForDate: state.api.reservationsForDate[dashboardDate.format('YYYYMMDD')],
    orderConfirmations: state.api.orderConfirmations.default,
  })
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    setDashboardDate,
    getReservationsForDate,
    getOrderConfirmations,
    createOrderConfirmation,
  }, dispatch),
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(withStyles(styles)(Dashboard))))
