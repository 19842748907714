import React from 'react'

const SvgComponent = ({ color, ...rest }) => (
  <svg width="1em" height="1em" viewBox="0 0 19 19" fill="none" {...rest}>
    <path
      d="M6.364 9.485h1.09M11.818 9.485h1.091M6.364 12.864h1.09M11.818 12.864h1.091M5.273 6.109V1.605M14 6.109V1.605"
      stroke={color || '#A8B2B9'}
      strokeWidth={2.182}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M16.182 17.366H3.09C2.488 17.366 2 16.86 2 16.24V4.981c0-.621.488-1.126 1.09-1.126h13.092c.603 0 1.09.505 1.09 1.126V16.24c0 .622-.487 1.126-1.09 1.126z"
      stroke={color || '#A8B2B9'}
      strokeWidth={2.182}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgComponent
