import moment from 'moment-timezone'
import Constants from './Constants'

export default () => {
  const date = moment.tz('Asia/Tokyo')

  /* midnight - 10:30am */
  if (date.hour() < Constants.LUNCH_LAST_CALL.hour || (date.hour() === Constants.LUNCH_LAST_CALL.hour && date.minute() <= Constants.LUNCH_LAST_CALL.minute)) {
    return {
      type: 'lunch',
      day: date.locale('en').format('dddd').toLowerCase(),
      date,
      label: 'today',
    }
  }

  /* 10:30am - 5:30pm */

  if ((date.hour() > Constants.LUNCH_LAST_CALL.hour || (date.hour() === Constants.LUNCH_LAST_CALL.hour && date.minute() >= Constants.LUNCH_LAST_CALL.minute))
    && (date.hour() < Constants.DINNER_LAST_CALL.hour || (date.hour() === Constants.DINNER_LAST_CALL.hour && date.minute() <= Constants.DINNER_LAST_CALL.minute))) {
    return {
      type: 'dinner',
      day: date.locale('en').format('dddd').toLowerCase(),
      date,
      label: 'today',
    }
  }

  /* 5:30pm - midnight */
  if (date.hour() > Constants.LUNCH_LAST_CALL.hour || (date.hour() === Constants.LUNCH_LAST_CALL.hour && date.minute() >= Constants.LUNCH_LAST_CALL.minute)) {
    date.add(1, 'd')

    return {
      type: 'lunch',
      day: date.locale('en').format('dddd').toLowerCase(),
      date,
      label: 'tomorrow',
    }
  }
}
