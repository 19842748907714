import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import {
  injectIntl,
  defineMessages,
  FormattedMessage
} from 'react-intl'
import {
  withStyles,
} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import SearchIcon from '@material-ui/icons/Search'
import {
  setMenuShopSelection,
} from '../redux/features/actions'
import {
  getMeals,
  getSides,
  setMealStatus,
  setSideStatus,
  getOpenRequests,
} from '../redux/api/actions'
import promiseMap from '../utils/promiseMap'
import getImageForMeal from '../utils/getImageForMeal'
import Tabs from '../components/Tabs'
import Toggle from '../components/Toggle'
import ShopSelector from '../components/ShopSelector'
import EditIcon from '../components/icons/EditIcon'

const NEW_MEAL_OPERATION_TYPE = 'NEW_MEAL_OPERATION'
const NEW_SIDE_OPERATION_TYPE = 'NEW_SIDE_OPERATION'

const statuses = {
  AVAILABLE: 'available',
  DISABLED: 'disabled',
  HIDDEN_FROM_SEARCH: 'hidden_from_search',
}

const messages = defineMessages({
  searchPlaceholder: {
    id: 'menu.search-placeholder',
    defaultMessage: 'Search meals',
  },
  meals: {
    id: 'menu.meals-tab',
    defaultMessage: 'Meals',
  },
  sides: {
    id: 'menu.sides-tab',
    defaultMessage: 'Sides',
  },
})

const TABS = [
  'meals',
  'sides',
]

const styles = theme => ({
  container: {
    padding: theme.spacing(4, 4, 0, 4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 2, 0, 2),
    },
  },
  topBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.spacing(3),
    backgroundColor: '#ffffff',
    boxShadow: '0px 3.27244px 11.9989px rgba(189, 159, 189, 0.112687)',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    maxWidth: 270,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(5),
    height: '100%',
    position: 'absolute',
    right: 0,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 7, 1, 1),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  newMealCta: {
    minWidth: 180,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(0),
    }
  },
  toggle: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  toggleMargin: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  shopSelector: {
    padding: theme.spacing(2),
  },
  mealsContainer: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  meals: {
    borderTopLeftRadius: 0,
    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.1)',
  },
  meal: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  mealInactive: {
    backgroundColor: '#EFEFEF',
  },
  loadingContainer: {
    zIndex: 5,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loading: {
  },
  mealImage: {
    width: 130,
    height: 84,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    [theme.breakpoints.down('sm')]: {
      width: 230,
      height: 150,
    }
  },
  mealImageInactive: {
    opacity: 0.5,
  },
  mealName: {
    flex: 1,
    fontSize: 16,
    fontWeight: 600,
    color: '#292529',
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: 0,
    },
  },
  mealNameInactive: {
    color: '#8C8A8C',
  },
  availableCta: {
    margin: theme.spacing(0, 1),
  },
  soldOutCta: {
    margin: theme.spacing(0, 1),
  },
  editCta: {
    fontSize: 14,
    color: '#A8B2B9',
    margin: theme.spacing(0, 1, 0, 3),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  editIcon: {
    marginRight: theme.spacing(1),
  },
  emptyList: {
    padding: theme.spacing(6, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

const fetchData = ( dispatch, shop ) => promiseMap({
  meals: dispatch(getMeals()),
  sides: dispatch(getSides()),
  openRequests: dispatch(getOpenRequests(shop.id)),
})

class Menu extends Component {
  constructor(props) {
    super(props)

    const state = {
      search: '',
      selectedShopIds: [],
      tabIndex: 0,
    }

    if (props.myShops.data) {
      state.selectedShopIds = props.myShops.data.map(shop => shop.id)
    }

    this.state = state
  }

  hasLoaded = () => {
    return this.props.meals.loaded
      && this.props.sides.loaded
      && this.props.openRequests.loaded
  }

  isMealLoading = meal => {
    return this.props.setMealStatusState[meal.id] && this.props.setMealStatusState[meal.id].loading
  }

  isSideLoading = side => {
    return this.props.setSideStatusState[side.id] && this.props.setSideStatusState[side.id].loading
  }

  onChangeSearch = event => {
    this.setState({
      search: event.nativeEvent.target.value,
    })
  }

  onSelectShopSelection = shopSelection => {
    this.props.setMenuShopSelection(shopSelection)
  }

  onChangeShopSelection = selectedShopIds => {
    this.setState({
      selectedShopIds,
    })
  }

  onClickTab = tabIndex => {
    this.setState({ tabIndex })
  }

  onClickCreateNewMeal = () => {
    if (this.state.tabIndex === 0) {
      this.props.history.push('/meal/new')
    } else {
      this.props.history.push('/side/new')
    }
  }

  onClickEnableMeal = async meal => {
    await this.props.setMealStatus({
      mealId: meal.id,
      status: statuses.AVAILABLE,
    })

    this.props.getMeals()
  }

  onClickDisableMeal = async meal => {
    await this.props.setMealStatus({
      mealId: meal.id,
      status: statuses.DISABLED,
    })

    this.props.getMeals()
  }

  onClickEditMeal = meal => {
    this.props.history.push(`/meal/${meal.id}`)
  }

  onClickEnableSide = async side => {
    await this.props.setSideStatus({
      sideId: side.id,
      status: statuses.AVAILABLE,
    })

    this.props.getSides()
  }

  onClickDisableSide = async side => {
    await this.props.setSideStatus({
      sideId: side.id,
      status: statuses.DISABLED,
    })

    this.props.getSides()
  }

  onClickEditSide = side => {
    this.props.history.push(`/side/${side.id}`)
  }

  componentWillMount() {
    if (!this.hasLoaded()) {
      fetchData(this.props.dispatch, this.props.shop)
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.myShops.data && this.props.myShops.data) {
      this.setState({
        selectedShopIds: this.props.myShops.data.map(shop => shop.id),
      })
    }
  }

  render() {
    let loading = true
    let meals = []
    let sides = []

    if (this.props.meals.data && this.props.sides.data && this.props.openRequests.data) {
      loading = false
      meals = [...this.props.meals.data]
      sides = [...this.props.sides.data]

      meals = meals.filter(meal => !meal.deleted)

      meals.sort((first, second) => {
        if (!first.hiddenFromSearch && second.hiddenFromSearch) {
          return -1
        }

        if (first.shop.id < second.shop.id) {
          return -1
        }

        if (first.id < second.id) {
          return -1
        }

        return 1
      })

//       for (let openRequest of this.props.openRequests.data) {
//         for (let operation of openRequest.operations.filter(operation => operation.type === NEW_MEAL_OPERATION_TYPE)) {
//           meals.push({
//             inReview: true,
//             shop: openRequest.shop,
//             ...operation.values
//           })
//         }
//         for (let operation of openRequest.operations.filter(operation => operation.type === NEW_SIDE_OPERATION_TYPE)) {
//           sides.push({
//             inReview: true,
//             shop: openRequest.shop,
//             ...operation.values
//           })
//         }
//       }
    }

    if (this.props.menuShopSelection === 'this-shop') {
      meals = meals.filter(meal => meal.shop.id === this.props.shop.id)
      sides = sides.filter(side => side.shopId === this.props.shop.id)

    } else {
      meals = meals.filter(meal => this.state.selectedShopIds.includes(meal.shop.id))
      sides = sides.filter(side => this.state.selectedShopIds.includes(side.shopId))
    }

    if (this.state.search) {
      meals = meals.filter(meal =>
        meal.name.includes(this.state.search)
        || (meal.details && meal.details.includes(this.state.search))
        || (meal.material && meal.material.includes(this.state.search)))
    }

    return (
      <div className={this.props.classes.container}>
        <div className={this.props.classes.topBar}>
          <div className={this.props.classes.search}>
            <div className={this.props.classes.searchIcon}>
              <SearchIcon
                color="secondary"
                style={{ fontSize: 18 }} />
            </div>
            <InputBase
              placeholder={this.props.intl.formatMessage(messages.searchPlaceholder)}
              classes={{
                root: this.props.classes.inputRoot,
                input: this.props.classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              value={this.state.search}
              onChange={this.onChangeSearch} />
          </div>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className={this.props.classes.newMealCta}
            onClick={this.onClickCreateNewMeal}>
            <FormattedMessage
              id="menu.new-meal-cta"
              defaultMessage="New meal" />
          </Button>
        </div>
        {this.props.myShops.data && this.props.myShops.data.length > 1 && (
          <Toggle
            items={[{
              label: 'This shop',
              value: 'this-shop',
            }, {
              label: 'All shops',
              value: 'all-shops',
            }]}
            value={this.props.menuShopSelection}
            onSelect={this.onSelectShopSelection}
            className={classNames(this.props.classes.toggle, this.props.classes.toggleMargin)} />
        )}
        {this.props.myShops.data && this.props.menuShopSelection === 'all-shops' && (
          <ShopSelector
            shops={this.props.myShops.data}
            className={this.props.classes.shopSelector}
            onChange={this.onChangeShopSelection} />
        )}
        <div className={this.props.classes.mealsContainer}>
          <Tabs
            index={this.state.tabIndex}
            items={TABS.map(tab => ({
              name: tab,
              label: this.props.intl.formatMessage(messages[tab])
            }))}
            onClickTab={this.onClickTab} />
          <Paper className={this.props.classes.meals}>
            {this.state.tabIndex === 0 && meals.map((meal, index) => (
              <div
                key={`${meal.id}-${meal.name}`}
                className={classNames(this.props.classes.meal, {
                  [this.props.classes.mealInactive]: meal.hiddenFromSearch,
                })}>
                {this.isMealLoading(meal) && (
                  <div className={this.props.classes.loadingContainer}>
                    <CircularProgress className={this.props.classes.loading} />
                  </div>
                )}
                <div
                  className={classNames(this.props.classes.mealImage, {
                    [this.props.classes.mealImageInactive]: meal.hiddenFromSearch,
                  })}
                  style={{
                    backgroundImage: `url(${getImageForMeal(meal)})`
                  }} />
                <Typography
                  variant="body1"
                  className={classNames(this.props.classes.mealName, {
                    [this.props.classes.mealNameInactive]: meal.hiddenFromSearch,
                  })}>
                  {meal.name}
                </Typography>
                {!meal.inReview && !meal.hiddenFromSearch && [(
                  <div key="availability-ctas">
                    <Button
                      variant={meal.disabled ? 'outlined' : 'contained'}
                      color="primary"
                      size="large"
                      className={this.props.classes.availableCta}
                      onClick={() => this.onClickEnableMeal(meal)}>
                      <FormattedMessage
                        id="menu.available"
                        defaultMessage="Available" />
                    </Button>
                    <Button
                      variant={meal.disabled ? 'contained' : 'outlined'}
                      color="secondary"
                      size="large"
                      className={this.props.classes.soldOutCta}
                      onClick={() => this.onClickDisableMeal(meal)}>
                      <FormattedMessage
                        id="menu.sold-out"
                        defaultMessage="Sold out" />
                    </Button>
                  </div>
                ), (
                  <Button
                    key="edit-cta"
                    variant="text"
                    color="secondary"
                    size="large"
                    className={this.props.classes.editCta}
                    onClick={() => this.onClickEditMeal(meal)}>
                    <EditIcon className={this.props.classes.editIcon} />
                    <FormattedMessage
                      id="menu.edit"
                      defaultMessage="Edit" />
                  </Button>
                )]}
                {meal.inReview && (
                  <Typography
                    variant="body1"
                    className={this.props.classes.mealUnderReview}>
                    <FormattedMessage
                      id="menu.meal-under-review"
                      defaultMessage="This meal is under review" />
                  </Typography>
                )}
                {meal.hiddenFromSearch && (
                  <Button
                    variant="text"
                    color="secondary"
                    size="large"
                    className={this.props.classes.availableCta}
                    onClick={() => this.onClickEnableMeal(meal)}>
                    <FormattedMessage
                      id="menu.restore"
                      defaultMessage="Restore" />
                  </Button>
                )}
              </div>
            ))}
            {this.state.tabIndex === 1 && sides.map((side, index) => (
              <div
                key={side.id}
                className={classNames(this.props.classes.meal, {
                  [this.props.classes.mealInactive]: side.hiddenFromSearch,
                })}>
                {this.isSideLoading(side) && (
                  <div className={this.props.classes.loadingContainer}>
                    <CircularProgress className={this.props.classes.loading} />
                  </div>
                )}
                <div
                  className={classNames(this.props.classes.mealImage)}
                  style={{
                    backgroundImage: `url(${getImageForMeal(side)})`
                  }} />
                <Typography
                  variant="body1"
                  className={this.props.classes.mealName}>
                  {side.name}
                </Typography>
                {!side.inReview && side.status !== statuses.HIDDEN_FROM_SEARCH && [(
                  <div key="availability-ctas">
                    <Button
                      variant={side.status === statuses.AVAILABLE ? 'contained' : 'outlined'}
                      color="primary"
                      size="large"
                      className={this.props.classes.availableCta}
                      onClick={() => this.onClickEnableSide(side)}>
                      <FormattedMessage
                        id="menu.available"
                        defaultMessage="Available" />
                    </Button>
                    <Button
                      variant={side.status === statuses.AVAILABLE ? 'outlined' : 'contained'}
                      color="secondary"
                      size="large"
                      className={this.props.classes.soldOutCta}
                      onClick={() => this.onClickDisableSide(side)}>
                      <FormattedMessage
                        id="menu.sold-out"
                        defaultMessage="Sold out" />
                    </Button>
                  </div>
                ), (
                  <Button
                    key="edit-cta"
                    variant="text"
                    color="secondary"
                    size="large"
                    className={this.props.classes.editCta}
                    onClick={() => this.onClickEditSide(side)}>
                    <EditIcon className={this.props.classes.editIcon} />
                    <FormattedMessage
                      id="menu.edit"
                      defaultMessage="Edit" />
                  </Button>
                )]}
                {side.inReview && (
                  <Typography
                    variant="body1"
                    className={this.props.classes.mealUnderReview}>
                    <FormattedMessage
                      id="menu.meal-under-review"
                      defaultMessage="This meal is under review" />
                  </Typography>
                )}
                {side.status === statuses.HIDDEN_FROM_SEARCH && (
                  <Button
                    variant="text"
                    color="secondary"
                    size="large"
                    className={this.props.classes.availableCta}
                    onClick={() => this.onClickEnableSide(side)}>
                    <FormattedMessage
                      id="menu.restore"
                      defaultMessage="Restore" />
                  </Button>
                )}
              </div>
            ))}
            {!loading && ((this.state.tabIndex === 0 && meals.length === 0) || (this.state.tabIndex === 1 && sides.length === 0)) && (
              <div className={this.props.classes.emptyList}>
                <FormattedMessage
                  id="menu.list-empty"
                  defaultMessage="Restore" />
              </div>
            )}
          </Paper>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  menuShopSelection: state.features.menuShopSelection,
  shop: state.features.shop,
  myShops: state.api.myShops.default,
  meals: state.api.meals.default,
  sides: state.api.sides.default,
  openRequests: state.api.openRequests.default,
  setMealStatusState: state.api.setMealStatus,
  setSideStatusState: state.api.setSideStatus,
})

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    setMenuShopSelection,
    getMeals,
    getSides,
    setMealStatus,
    setSideStatus,
    getOpenRequests,
  }, dispatch),
  dispatch,
})

Menu.fetchData = fetchData

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(withStyles(styles)(Menu))))
