import React, { useState } from 'react'
import {
  FormattedMessage,
} from 'react-intl'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

export default withStyles(theme => ({
  container: {
    background: '#FFFFFF',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  actions: {
    display: 'flex',
    marginBottom: theme.spacing(),
  },
}))(({
  classes,
  shops,
  initialValue,
  className,
  onChange,
}) => {
  if (!initialValue) {
    initialValue = shops.map(shop => shop.id)
  }

  const [selectedShopIds, setSelectedShopIds] = useState(initialValue)

  const toggleShop = shop => {
    const newSelectedShopIds = [...selectedShopIds]
    const index = newSelectedShopIds.indexOf(shop.id)

    if (index > -1) {
      newSelectedShopIds.splice(index, 1)
    } else {
      newSelectedShopIds.push(shop.id)
    }

    setSelectedShopIds(newSelectedShopIds)
    onChange && onChange(newSelectedShopIds)
  }

  const onClickSelectAllShops = () => {
    const newSelectedShopIds = shops.map(shop => shop.id)

    setSelectedShopIds(newSelectedShopIds)
    onChange && onChange(newSelectedShopIds)
  }

  const onClickDeselectAllShops = () => {
    setSelectedShopIds([])
    onChange && onChange([])
  }

  return (
    <div className={classNames(classes.container, className)}>
      <div className={classes.actions}>
        {selectedShopIds.length < shops.length ? (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={onClickSelectAllShops}>
            <FormattedMessage
              id="sales.select-all-shops"
              defaultMessage="Select all" />
          </Button>
        ) : (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={onClickDeselectAllShops}>
            <FormattedMessage
              id="sales.deselect-all-shops"
              defaultMessage="Deselect all" />
          </Button>
        )}
      </div>
      <FormGroup row>
        {shops.map(shop => (
          <FormControlLabel
            key={shop.id}
            control={(
              <Checkbox
                color="primary"
                checked={selectedShopIds.includes(shop.id)}
                onChange={() => toggleShop(shop)}
                name={shop.name} />
            )}
            label={shop.name} />
        ))}
      </FormGroup>
    </div>
  )
})
