import moment from 'moment-timezone'
import {
  SET_SHOP,
  SET_LOCALE,
  SET_FLAG,
  SET_REDIRECT_TO,
  CLEAR_REDIRECT_TO,
  SET_MESSAGES_BADGE,
  SET_DASHBOARD_DATE,
  SET_HOURS_DATE,
  SET_MENU_SHOP_SELECTION,
} from './actions'

const getInitialState = () => ({
  shop: null,
  locale: 'ja',
  flags: {
    testFlag: true,
  },
  redirectTo: null,
  messagesBadge: 0,
  dashboardDate: moment.tz('Asia/Tokyo'),
  hoursDate: moment.tz('Asia/Tokyo').startOf('month'),
  menuShopSelection: 'this-shop',
})

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case SET_SHOP: {
      return {
        ...state,
        shop: action.shop,
      }
    }
    case SET_LOCALE: {
      return {
        ...state,
        locale: action.locale,
      }
    }
    case SET_FLAG: {
      return {
        ...state,
        flags: {
          ...state.flags,
          [action.name]: action.value,
        }
      }
    }
    case SET_REDIRECT_TO: {
      return {
        ...state,
        redirectTo: action.redirectTo,
      }
    }
    case CLEAR_REDIRECT_TO: {
      return {
        ...state,
        redirectTo: null,
      }
    }
    case SET_MESSAGES_BADGE: {
      return {
        ...state,
        messagesBadge: action.messagesBadge,
      }
    }
    case SET_DASHBOARD_DATE: {
      return {
        ...state,
        dashboardDate: action.dashboardDate,
      }
    }
    case SET_HOURS_DATE: {
      return {
        ...state,
        hoursDate: action.hoursDate,
      }
    }
    case SET_MENU_SHOP_SELECTION: {
      return {
        ...state,
        menuShopSelection: action.menuShopSelection,
      }
    }
    default:
      return state
  }
}
