import React, { Component } from 'react'
import {
  withRouter,
  matchPath,
} from 'react-router-dom'
import {
  defineMessages,
  injectIntl,
  FormattedMessage,
} from 'react-intl'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Badge from '@material-ui/core/Badge'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Hidden from '@material-ui/core/Hidden'
import Drawer from '@material-ui/core/Drawer'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import NotificationsNoneRoundedIcon from '@material-ui/icons/NotificationsNoneRounded'
import CloseIcon from '@material-ui/icons/Close'
import Person from '@material-ui/icons/Person'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import canUseDOM from '../../utils/canUseDOM'
import Logo from '../Logo'
import DashboardIcon from '../icons/DashboardIcon'
import CalendarIcon from '../icons/CalendarIcon'
import MenuIcon from '../icons/MenuIcon'
import AnalyticsIcon from '../icons/AnalyticsIcon'
import SettingsIcon from '../icons/SettingsIcon'
import './Nav.css'

const DEFAULT_ICON_COLOR = '#A8B2B9'

const messages = defineMessages({
  messages: {
    id: 'nav.messages',
    defaultMessage: 'Messages',
  },
  overview: {
    id: 'nav.overview',
    defaultMessage: 'Overview',
  },
  hours: {
    id: 'nav.hours',
    defaultMessage: 'Hours',
  },
  menu: {
    id: 'nav.menu',
    defaultMessage: 'Menu',
  },
  sales: {
    id: 'nav.sales',
    defaultMessage: 'Sales',
  },
  settings: {
    id: 'nav.settings',
    defaultMessage: 'Settings',
  },
})

const SECTIONS = [{
  showMessagesBadge: true,
  label: messages.messages,
  icon: ({ color=DEFAULT_ICON_COLOR }) => (
    <NotificationsNoneRoundedIcon
      style={{
        fontSize: 20,
        marginLeft: -3,
        color,
      }}
      color={color} />
  ),
  link: '/messages',
  path: '/messages',
}, {
  label: messages.overview,
  icon: ({ color=DEFAULT_ICON_COLOR }) => (<DashboardIcon color={color} />),
  link: '/dashboard',
  path: '/(dashboard|reservations)',
  // exact: true,
}, {
  label: messages.hours,
  icon: ({ color=DEFAULT_ICON_COLOR }) => (<CalendarIcon color={color} />),
  link: '/hours',
  path: '/hours',
}, {
  label: messages.menu,
  icon: ({ color=DEFAULT_ICON_COLOR }) => (<MenuIcon color={color} />),
  link: '/menu',
  path: '/(menu|meal|side)',
}, {
  label: messages.sales,
  icon: ({ color=DEFAULT_ICON_COLOR }) => (<AnalyticsIcon color={color} />),
  link: '/sales',
  path: '/sales',
}, {
  label: messages.settings,
  icon: ({ color=DEFAULT_ICON_COLOR }) => (<SettingsIcon color={color} />),
  link: '/settings',
  path: '/settings',
}]

const mobileNavStyles = theme => ({
  appBarRoot: {
    position: 'relative',
    boxShadow: 'none',
    backgroundColor: '#ffffff',
  },
  toolbarRoot: {
    justifyContent: 'flex-end',
  },
})

const MobileNavTransition = React.forwardRef((props, ref) => (<Slide direction="up" ref={ref} {...props} />))

const MobileNav = withStyles(mobileNavStyles)(({
  open,
  classes,
  onToggleOpen,
  children,
}) => (
  <Dialog
    fullScreen
    open={open}
    onClose={onToggleOpen}
    TransitionComponent={MobileNavTransition}>
    <AppBar
      classes={{
        root: classes.appBarRoot,
      }}>
      <Toolbar classes={{
        root: classes.toolbarRoot,
      }}>
        <IconButton onClick={onToggleOpen}>
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
    {children}
  </Dialog>
))

const styles = theme => ({
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
  },
  drawerContent: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logo: {
    width: 78,
    height: 24,
    marginBottom: theme.spacing(),
  },
  shopName: {
    marginBottom: theme.spacing(2),
  },
  shopNameText: {
    fontSize: 16,
    lineHeight: '24px',
    textTransform: 'none',
    fontWeight: 600,
    color: '#292529',
  },
  listItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(),
    borderLeft: '6px solid transparent',
  },
  listItemSelected: {
    [theme.breakpoints.up('md')]: {
      borderLeft: '6px solid #FF6069',
      boxShadow: 'inset 39px 0px 19px -39px rgba(255,96,105,0.42)',
    },
  },
  listItemText: {
    fontSize: 14,
    lineHeight: '21px',
    color: '#A8B2B9',
    fontWeight: 'bold',
  },
  listItemTextSelected: {
    color: '#292529',
  },
  usersIcon: {
    fontSize: 16,
  },
  logoutIcon: {
    fontSize: 16,
    marginLeft: -2,
  },
  mobileListContainer: {
    marginLeft: theme.spacing(4),
  },
  mobileLogo: {
    width: 107,
    height: 33,
    marginLeft: theme.spacing(),
  },
  formFieldMobile: {
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
})

class DrawerUnwrapped extends Component {
  onChangeShop = async event => {
    if (this.props.mobileDrawerOpen) {
      this.props.onDrawerToggle()
    }

    await this.props.onChangeShops(event.target.value)
  }

  onClickItem = path => {
    this.props.history.push(path)

    if (this.props.mobileDrawerOpen) {
      this.props.onDrawerToggle()
    }
  }

  render() {
    let pathname

    if (canUseDOM()) {
      pathname = window.location.pathname
    } else {
      pathname = this.props.location.pathname
    }

    const userSelected = matchPath(pathname, "/customers")

    const list = (
      <List>
        {SECTIONS.map(item => {
          const selected = matchPath(pathname, item)

          let icon

          if (item.showMessagesBadge) {
            icon = (
              <Badge
                color="primary"
                badgeContent={this.props.messagesBadge}>
                {item.icon({
                  color: selected ? '#FF6069' : DEFAULT_ICON_COLOR,
                })}
              </Badge>
            )
          } else {
            icon = item.icon({
              color: selected ? '#FF6069' : DEFAULT_ICON_COLOR,
            })
          }

          return (
            <ListItem
              button
              key={item.path}
              classes={{
                root: classNames(this.props.classes.listItem, {
                  [this.props.classes.listItemSelected]: selected,
                }),
              }}
              onClick={() => this.onClickItem(item.link)}>
              <ListItemIcon>
                {icon}
              </ListItemIcon>
              <ListItemText
                primary={this.props.intl.formatMessage(item.label)}
                classes={{
                  primary: classNames(this.props.classes.listItemText, {
                    [this.props.classes.listItemTextSelected]: selected,
                  }),
                }} />
            </ListItem>
          )
        })}
        <Divider />
        <ListItem
          button
          onClick={this.props.onClickLogout}>
          <ListItemIcon>
            <ExitToAppIcon
              color={DEFAULT_ICON_COLOR}
              classes={{
                root: this.props.classes.logoutIcon,
              }} />
          </ListItemIcon>
          <ListItemText
            primary={(
              <FormattedMessage
                id="nav.logout"
                defaultMessage="Logout" />
            )}
            classes={{
              primary: this.props.classes.listItemText,
            }} />
        </ListItem>
      </List>
    )

    return (
      <div>
        <Hidden mdUp implementation="css">
          <MobileNav
            open={this.props.mobileDrawerOpen}
            onToggleOpen={this.props.onDrawerToggle}>
            <div className={this.props.classes.mobileListContainer}>
              <Logo className={this.props.classes.mobileLogo} />
              {this.props.myShops.data && this.props.myShops.data.length > 1 && (
                <div className={classNames([this.props.classes.formField, this.props.classes.formFieldMobile])}>
                  <Select
                    fullWidth
                    value={this.props.shop ? this.props.shop.id : 0}
                    variant="outlined"
                    classes={{
                      root: this.props.classes.formFieldSelect,
                    }}
                    onChange={this.onChangeShop}>
                    {this.props.myShops.data.map(shop => (
                      <MenuItem
                        key={shop.id}
                        value={shop.id}>
                        {shop.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}
              {list}
            </div>
          </MobileNav>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: this.props.classes.drawerPaper,
            }}
            variant="permanent"
            open>
            <div className={this.props.classes.drawerContent}>
              <Logo className={this.props.classes.logo} />
              {this.props.myShops.data && this.props.myShops.data.length > 1 && (
                <div className={this.props.classes.formField}>
                  <Select
                    fullWidth
                    value={this.props.shop ? this.props.shop.id : 0}
                    variant="outlined"
                    classes={{
                      root: this.props.classes.formFieldSelect,
                    }}
                    onChange={this.onChangeShop}>
                    {this.props.myShops.data.map(shop => (
                      <MenuItem
                        key={shop.id}
                        value={shop.id}>
                        {shop.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}
              {this.props.shop && (!this.props.myShops.data || this.props.myShops.data.length <= 1) && (
                <div className={this.props.classes.shopName}>
                  <Typography
                    variant="body1"
                    className={this.props.classes.shopNameText}>
                    {this.props.shop.name}
                  </Typography>
                </div>
              )}
            </div>
            {list}
          </Drawer>
        </Hidden>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(injectIntl(DrawerUnwrapped)))
