import React from 'react'

const SvgComponent = props => (
  <svg width={83} height={83} fill="none" {...props}>
    <path
      d="M79.227 15.09H3.773A3.773 3.773 0 000 18.865v15.09a11.318 11.318 0 007.545 10.64v34.633A3.773 3.773 0 0011.318 83h60.364a3.773 3.773 0 003.772-3.773V44.594A11.318 11.318 0 0083 33.954v-15.09a3.773 3.773 0 00-3.773-3.773zm-26.409 7.546h7.546v11.319a3.773 3.773 0 11-7.546 0V22.636zm-15.09 0h7.545v11.319a3.773 3.773 0 01-7.546 0V22.636zm-15.092 0h7.546v11.319a3.773 3.773 0 01-7.546 0V22.636zM11.318 37.727a3.773 3.773 0 01-3.773-3.772V22.636h7.546v11.319a3.773 3.773 0 01-3.773 3.772zm37.727 37.727h-15.09V67.91a7.545 7.545 0 1115.09 0v7.545zm18.864 0H56.591V67.91a15.09 15.09 0 10-30.182 0v7.545H15.091v-30.86a11.958 11.958 0 003.773-2.264 11.318 11.318 0 0015.09 0 11.318 11.318 0 0015.091 0 11.318 11.318 0 0015.091 0 11.956 11.956 0 003.773 2.264v30.86zm7.545-41.5a3.773 3.773 0 11-7.545 0V22.637h7.545v11.319zM12.45 7.546h59.232a3.773 3.773 0 100-7.545H12.45a3.773 3.773 0 100 7.545z"
      fill="#A8B2B9"
    />
  </svg>
)

export default SvgComponent
