export default {
  'app.page-title': 'Potluck オーダー ({date})',
  'app.shops': 'Potluckレストラン',

  'nav.messages': 'お知らせ',
  'nav.overview': '本日の予約',
  'nav.hours': '営業日設定',
  'nav.menu': 'メニュー管理',
  'nav.sales': '売上管理',
  'nav.settings': '店舗情報設定',
  'nav.logout': 'ログアウト',

  'login.page-title': 'Potluck レストラン',
  'login.shops': 'レストラン',
  'login.email': 'メールアドレス',
  'login.password': 'パスワード',
  'login.cta': 'ログイン',
  'login.account-not-found': 'メールアドレスかパスワード、どちらかが正しくありません。',
  'login.no-shops-for-account': 'お客様のアカウントにはショップがありません。',
  'login.missing-field': '入力必須事項です',
  'login.email-invalid': 'メールアドレスが正しくありません',

  'forgot-password.shops': 'レストラン',
  'forgot-password.email': 'メールアドレス',
  'forgot-password.cta': '再設定用のメールを送信する',
  'forgot-password.check-email': 'info@pot-luck.jp よりメールを送信しました。添付のURLから、パスワードを再設定してください。',
  'forgot-password.missing-field': '入力必須事項です',
  'forgot-password.email-invalid': 'メールアドレスが正しくありません',

  'messages.time-now': '今すぐ',
  'messages.time-seconds': '{seconds}秒前',
  'messages.time-minutes': '{minutes}分前',
  'messages.time-hours': '{hours}時間前',
  'messages.time-days': '{days}日前',
  'messages.title': 'お知らせ',
  'messages.subtitle': 'POTLUCKからのお知らせを表示します',
  'messages.pagination': '{page} of {totalPages}',
  'messages.cta': '詳細',

  'dashboard.title': '{date} ({dayName})',
  'dashboard.delivery': 'デリバリー',
  'dashboard.hours-closed': '予約受付時間外',
  'dashboard.hours-lunch': 'ランチ営業 ({lunchHours})',
  'dashboard.hours-dinner': 'ディナー営業 ({dinnerHours})',
  'dashboard.hours-lunch-dinner': 'ランチ・ディナー営業 ({lunchHours} | {dinnerHours})',
  'dashboard.lunch': 'ランチ',
  'dashboard.dinner': 'ディナー',
  'dashboard.finalized-status': 'ステータス：予約が確定しました',
  'dashboard.not-finalized-status': 'ステータス：予約は確定していません（追加・変更・キャンセルの可能性があります）',
  'dashboard.reservations-count': '注文数：{count}件',
  'dashboard.no-reservations': 'ご予約受付中',
  'dashboard.reservations-acknowledged': '予約の確認ありがとうございます！本日よろしくお願いします',
  'dashboard.mark-as-seen': '本日の予約を確認しました',
  'dashboard.report-a-problem': '予約に問題がある場合はこちら',
  'dashboard.print': '印刷',
  'dashboard.order-index': '{index}回目',
  'dashboard.active': '予約あり',
  'dashboard.deleted': 'キャンセル(売上なし)',
  'dashboard.canceled': 'キャンセル',
  'dashboard.sides': 'サイドメニュー追加',

  'dashboard-print.header': 'Potluck オーダー',
  'dashboard-print.id': '#',
  'dashboard-print.time': '時間',
  'dashboard-print.item': '項目',
  'dashboard-print.user': 'ユーザー',
  'dashboard-print.status': '予約状況',
  'dashboard-print.completed': 'チェック',
  'dashboard-print.user-cancelation-note': 'メッセージ',

  'reservation.title': '{date} ({dayName}) {pickupTime}のご予約 : {userName}',
  'reservation.meal': '注文商品',
  'reservation.options-title': 'オプション選択',
  'reservation.sides-title': 'サイドメニュー追加',
  'reservation.user': 'ユーザー名',
  'reservation.reservation-count-title': '来店回数',
  'reservation.reservation-count-value': '{count}回目',
  'reservation.order-history': 'このユーザーの注文履歴',
  'reservation.order-index': '{index}回目',
  'reservation.none': '初めてお店に来られるお客さまです',
  'reservation.delivery': 'デリバリー',
  'reservation.delivery-title': 'こちらはデリバリーのご予約です。',
  'reservation.delivery-caption': 'このご注文はデリバリースタッフがお客様にお届けいたします。',

  'hours.edit-hours-for-day': '営業時間設定',
  'hours.set-closed': '休業日に設定',
  'hours.today': '本日',
  'hours.tomorrow': '明日',
  'hours.reservations-warning': '本日{count}件の予約が入っています。{dayName}の営業時間の変更は可能ですが、既に予約を受けているものに関しては必ずご対応をお願いします。',
  'hours.accept': '確認しました',
  'hours.dismiss': '閉じる',
  'hours.calendar-updated': 'カレンダーが{br}変更されました',
  'hours.closed': '店舗定休日',
  'hours.edit-hours': '営業日・時間を登録',

  'edit-hours-dialog.missing-field': '入力必須',
  'edit-hours-dialog.time-range-invalid': '無効',
  'edit-hours-dialog.title': '営業日・時間の登録',
  'edit-hours-dialog.weekdays-title': '平日営業可能な曜日',
  'edit-hours-dialog.hours': '営業時間',
  'edit-hours-dialog.lunch': 'ランチ',
  'edit-hours-dialog.dinner': 'ディナー',
  'edit-hours-dialog.weekends-title': '休日営業可能な曜日',
  'edit-hours-dialog.save': '設定する',

  'edit-hours-for-date-dialog.missing-field': '入力必須',
  'edit-hours-for-date-dialog.time-range-invalid': '無効',
  'edit-hours-for-date.hours': '営業時間',
  'edit-hours-for-date.lunch': 'ランチ',
  'edit-hours-for-date.dinner': 'ディナー',
  'edit-hours-for-date.save': '設定する',

  'edit-holidays-dialog.open': '営業',
  'edit-holidays-dialog.closed': '定休日',
  'edit-holidays-dialog.title': '祝日の営業',
  'edit-holidays-dialog.instructions': '祝日の営業が可能か入力をお願いします。',
  'edit-holidays-dialog.month': '{monthName}の祝日',
  'edit-holidays-dialog.business-hours': '営業時間',
  'edit-holidays-dialog.lunch': 'ランチ',
  'edit-holidays-dialog.dinner': 'ディナー',

  'menu.search-placeholder': 'メニュー検索',
  'menu.meals-tab': 'メインメニュー',
  'menu.sides-tab': 'サイドメニュー',
  'menu.new-meal-cta': '新規メニュー登録',
  'menu.available': '注文可能',
  'menu.sold-out': '売り切れ',
  'menu.edit': 'メニュー編集',
  'menu.meal-under-review': '申請中',
  'menu.restore': '再販する',
  'menu.list-empty': '空のリスト',

  'meal.missing-field': '入力必須',
  'meal.delete-warning': 'お客さまの画面から、このメニューが表示されなくなります。この操作は「再販する」からいつでも元に戻すことができます。',
  'meal.delete-warning-cta': '確定',
  'meal.in-review': '確認中',
  'meal.review-message': 'POTLUCK運営による確認作業中です。ご不便をおかけしますが、連絡をお待ちください。',
  'meal.meal': 'メインメニュー',
  'meal.side': 'サイドメニュー',
  'meal.add-photos': 'ファイルを選択',
  'meal.no-photo-selected': '選択されていません',
  'meal.availability-title': 'メニューの提供スケジュール',
  'meal.availability-disclaimer': '※営業日設定ページで登録中の定休日は自動的に提供スケジュールから除外されます。',
  'meal.lunch': 'ランチ',
  'meal.dinner': 'ディナー',
  'meal.potluck-now-lunch': 'Potluck NOW (ランチ)',
  'meal.potluck-now-dinner': 'Potluck NOW (ディナー)',
  'meal.name': 'メニュー名',
  'meal.price': '価格',
  'meal.daily-reservation-limit': '1日最大提供数',
  'meal.description': 'メニューの説明',
  'meal.description-placeholder': '（例）日替わりで旬の食材を使い、栄養バランスもバッチリのおかずが4種類入っているメニューです！',
  'meal.options-title': 'オプションの追加',
  'meal.options-subtitle': 'ライスの量、トッピングの選択、ソースの選択等のオプション設定が行えます。',
  'meal.option-name': 'オプションの題名',
  'meal.option-placeholder': '例）ごはんの量',
  'meal.option-values': 'オプションの項目',
  'meal.choices-placeholder': '例）小盛り、普通盛り、大盛り',
  'meal.option-costs-extra': '有料にする',
  'meal.option-amount': '追加料金の設定',
  'meal.option-max-cost': '上限は{amount}円です',
  'meal.option-amount-disclaimer': '※設定金額の20%は手数料と差し引かれます。{break}※2020年12月末まで上記手数料無料{break}※通常販売価格以下での設定を推奨',

  'meal.options-add-choice': '+ 項目の追加',
  'meal.options-add': '+ オプション追加',

  'meal.ingredients': '材料',
  'meal.ingredients-placeholder': '（例）卵, 乳, 小麦',
  'meal.option-required': '必須',
  'meal.option-allow-multiple': '複数選択を許可',
  'meal.pickup-instructions': '受け取り方法',
  'meal.cancel': 'キャンセル',
  'meal.save': '保存',
  'meal.delete-meal': 'メニューの販売を取りやめる',
  'meal.change-name': '名前を "{previousValue}" から "{newValue}"に変更する。',
  'meal.change-price': '値段を "{previousValue}" から "{newValue}"に変更する。',
  'meal.change-daily-reservation-limit': '値段を "{previousValue}" から "{newValue}"に変更する。',
  'meal.change-details': 'メニュー詳細を "{previousValue}" から "{newValue}"に変更する。',
  'meal.change-ingredients': 'メニュー詳細を "{previousValue}" から "{newValue}"に変更する。',
  'meal.change-pickup-instructions': '受け取り時の注意事項を "{previousValue}" から "{newValue}"に変更する。',
  'meal.change-category': '料理のカテゴリーを"{previousValue}"から"{newValue}"に変更する。',
  'meal.change-media': 'メニュー写真を変更する。',
  'meal-request.in-review': '確認中',
  'meal-request.review-message': 'POTLUCK運営による確認作業中です。ご不便をおかけしますが、連絡をお待ちください。',
  'meal-request.cancel': 'リクエストをキャンセルする。',

  'side.delete-warning': 'お客さまの画面から、このメニューが表示されなくなります。この操作は「再販する」からいつでも元に戻すことができます。',
  'side.delete-warning-cta': '確定',
  'side.in-review': '確認中',
  'side.review-message': 'POTLUCK運営による確認作業中です。ご不便をおかけしますが、連絡をお待ちください。',
  'side.disclaimer': '上限は{amount}円までです。{break}※設定金額の20%は手数料と差し引かれます。{break}※2020年12月末まで上記手数料無料{break}※通常販売価格以下での設定を推奨',
  'side.cancel': 'キャンセル',
  'side.add-photos': 'ファイルを選択',
  'side.no-photo-selected': '選択されていません',
  'side.availability-title': 'メニューの提供スケジュール',
  'side.availability-disclaimer': '※営業日設定ページで登録中の定休日は自動的に提供スケジュールから除外されます。',
  'side.lunch': 'ランチ',
  'side.dinner': 'ディナー',
  'side.potluck-now-lunch': 'Potluck NOW (ランチ)',
  'side.potluck-now-dinner': 'Potluck NOW (ディナー)',
  'side.meal': 'メインメニュー',
  'side.side': 'サイドメニュー',
  'side.name': 'メニュー名',
  'side.price': '価格',
  'side.daily-reservation-limit': '1日最大提供数',
  'side.save': '保存',
  'side.delete-meal': 'メニューの販売を取りやめる',
  'sides.drink': 'ドリンク',
  'sides.soup': 'スープ',
  'sides.salad': 'サラダ',
  'sides.side': 'サイド',
  'sides.dessert': 'デザート',
  'sides.other': 'その他',

  'sales.title': '売上',
  'sales.daily': '日',
  'sales.weekly': '週',
  'sales.monthly': '月',
  'sales.select-shops': 'お店を選ぶ',
  'sales.select-all-shops': '全てを選択',
  'sales.deselect-all-shops': '全ての選択を外す',
  'sales.revenue': '売上',
  'sales.orders': '総注文数',
  'sales.subscribers': '購読者',
  'sales.no-sales': '売れない',
  'sales.item': 'メニュー名',
  'sales.shop': '店舗名',
  'sales.user': 'お客様',
  'sales.pickup': '注文日',
  'sales.side-option': 'サイドメニュー/オプション',
  'sales.reviews': 'レビュー',
  'sales.reviews-count': 'レビュー数: {count}',
  'sales.reviews-item': 'メニュー名',
  'sales.no-reviews': 'レビューなし',

  'reviews-list.back': '戻る',
  'reviews-list.title': 'ユーザーレビュー',
  'reviews-list.reviews': 'レビュー数',
  'reviews-list.reservation-count': '注文数: {count}',

  'settings.missing-field': '入力必須',
  'settings.cta': '保存',
  'settings.language': '言語',
  'settings.details': 'レストラン詳細',
  'settings.zip': '郵便番号',
  'settings.address': '住所',
  'settings.contact-name': '担当者名',
  'settings.contact-number': '連絡先',
  'settings.show-shop-phone': 'お客様にアプリ上で電話番号を表示をする',
  'settings.notification-emails': '予約確認メールの受け取り',
  'settings.notification-email-add': '＋予約メールを受け取るメールアドレス',
  'settings.login-email': 'ユーザーID',
  'settings.password': 'パスワード',
  'settings.change-password': 'パスワードの変更',
  'settings.preferences': 'オプション',
  'settings.email_on_every_day': '1日分の注文内容をまとめてメールで送信',
  'settings.email_on_every_order': '注文が入る度に注文内容をメールで送信',
  'settings.connect-line-title': 'LINEを追加する',
  'settings.connect-line-text': 'LINEを追加する',
  'settings.connected': '既に追加されています。',

  'settings.custom': 'Custom',
  'settings.new-years': '元日',
  'settings.coming-of-age': '成人の日',
  'settings.national-foundation': '建国記念の日',
  'settings.spring-equinox': '春分の日',
  'settings.golden-week': 'ゴールデンウィーク',
  'settings.showa-day': '昭和の日',
  'settings.national-holiday': '国民の休日',
  'settings.constitution-day': '憲法記念日',
  'emporers-throne-day': '天皇の即位の日',
  'settings.green-day': 'みどりの日',
  'settings.childrens-day': 'こどもの日',
  'settings.transfer-holiday': '振替休日',
  'settings.marine': '海の日',
  'settings.mountain': '山の日',
  'settings.mountain-extra': '振替休日',
  'settings.seniors': '敬老の日',
  'settings.autumn-equinox': '秋分の日',
  'settings.sports': '体育の日',
  'settings.culture': '文化の日',
  'settings.culture-extra': '振替休日',
  'settings.labor-thanksgiving': '勤労感謝の日',

  'edit-password-dialog.title': 'パスワードの変更',
  'edit-password-dialog.new-password': '新しいパスワード',
  'edit-password-dialog.confirm-new-password': '再入力(新しいパスワード)',
  'edit-password-dialog.cta': '設定する',
  'edit-password-dialog.missing-field': '入力必須',
  'edit-password-dialog.password-do-not-match': '上記と同じパスワードを入力して下さい',

  'connect-line-dialog.title': 'LINEを追加する',
  'connect-line-dialog.subtitle': 'LINEからこのQRコードを読み取りいただくと、予約メールをLINEにて通知いたします',
  'connect-line-dialog.success-title': '接続完了！',
  'connect-line-dialog.success-subtitle': 'あなたのLINEアカウントに追加しました。',
  'connect-line-dialog.success-cta': '閉じる',
}
