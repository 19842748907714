import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded'
import StarIcon from '@material-ui/icons/Star'
import {
  getMyShops,
  getReviews,
  getMeals,
} from '../redux/api/actions'
import getImageForMeal from '../utils/getImageForMeal'
import promiseMap from '../utils/promiseMap'
import CommentBubbleIcon from '../components/icons/CommentBubbleIcon'

const DATE_FORMAT = 'YYYYMM'

const round = number => {
  if (typeof number === 'number') {
    return Math.round(number * 10) / 10
  }

  return number
}

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  backButton: {
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: 24,
    display: 'flex',
    alignItems: 'center',
    color: '#535353',
  },
  content: {
    border: '1px solid #EFEFEF',
    marginTop: theme.spacing(3),
  },
  meals: {
    background: '#FFFFFF',
    borderTop: '1px solid #EFEFEF',
    borderLeft: '1px solid #EFEFEF',
    borderRight: '1px solid #EFEFEF',
    marginTop: theme.spacing(4),
  },
  meal: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    padding: theme.spacing(2),
    borderBottom: '1px solid #EFEFEF',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  mealImage: {
    width: 130,
    height: 84,
    backgroundSize: 'cover',
    [theme.breakpoints.down('xs')]: {
      width: 230,
      height: 150,
    },
  },
  mealName: {
    flex: 2,
    fontSize: 16,
    fontWeight: 600,
    color: '#292529',
    textAlign: 'left',
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: theme.spacing(1, 0, 0, 0),
    },
  },
  mealRating: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 600,
    color: '#292529',
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: theme.spacing(1, 0, -1, 0),
    },
  },
  mealRatingStar: {
    height: 16,
    width: 16,
    marginRight: theme.spacing(1),
  },
  mealCommentsContainer: {
    flex: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: 0,
      justifyContent: 'flex-start',
    },
  },
  mealComments: {
    flex: 1,
    fontSize: 16,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    color: '#A8B2B9',
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      marginLeft: 0,
    }
  },
  mealCommentsIcon: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  mealReservations: {
    flex: 1,
    fontSize: 16,
    color: '#A8B2B9',
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      marginLeft: theme.spacing(1),
    }
  },
  reviews: {
    background: '#FFFFFF',
  },
  review: {
    width: '100%',
    minHeight: 100,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderTop: '1px solid #EFEFEF',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    }
  },
  reviewDate: {
    width: 120,
    fontSize: 14,
    fontWeight: 600,
    color: '#A8B2B9',
  },
  reviewRating: {
    width: 120,
    fontSize: 14,
    fontWeight: 600,
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 0),
    }
  },
  reviewRatingNoComment: {
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 0, 0, 0),
    }
  },
  reviewRatingStar: {
    height: 16,
    width: 16,
    marginRight: theme.spacing(1),
    marginBottom: -2,
  },
  reviewComment: {
    flex: 1,
    fontSize: 14,
    color: '#292529'
  },
})

const fetchData = dispatch => promiseMap({
  myShops: dispatch(getMyShops()),
  paymentSchedule: dispatch(getPaymentSchedule({ period: 'month' })),
  reviews: dispatch(getReviews()),
  meals: dispatch(getMeals()),
})

class ReviewsList extends Component {
  hasLoaded = () => {
    return this.props.myShops.loaded
      && this.props.reservationDetails && this.props.reservationDetails.loaded
      && this.props.reviews.loaded
      && this.props.meals.loaded
  }

  onClickBack = () => {
    this.props.history.goBack()
  }

  componentWillMount() {
    if (!this.hasLoaded()) {
      fetchData(this.props.dispatch)
    }
  }

  render() {
    let meal
    let paymentsMeal
    let averageRating = '--'
    let reviews = []
    let commentCount = 0
    const periodStart = moment(this.props.match.params.date, DATE_FORMAT)
    const periodEnd = periodStart.clone().add(1, 'month')
    const mealId = parseInt(this.props.match.params.mealId, 10)

    if (this.props.meals.data && this.props.paymentSchedule.data) {
      meal = this.props.meals.data.find(meal => meal.id === mealId)

      const payments = this.props.paymentSchedule.data.find(payment => moment(payment.beginDate).isBetween(periodStart, periodEnd, null, '[)'))
      paymentsMeal = payments.items.find(({ id }) => id === meal.id)
    }

    if (this.props.reviews.data) {
      reviews = this.props.reviews.data
        .filter(review => moment(review.pickup.date, DATE_FORMAT).isBetween(periodStart, periodEnd, null, '[)'))
        .filter(review => review.meal.id === mealId)

      if (reviews.length > 0) {
        averageRating = reviews.reduce((memo, review) => memo + review.rating, 0) / reviews.length
        commentCount = reviews.filter(review => !!review.comment).length
      }
    }

    return (
      <div className={this.props.classes.container}>
        <Button
          variant="outlined"
          color="secondary"
          size="large"
          className={this.props.classes.backButton}
          onClick={this.onClickBack}>
          <NavigateBeforeRoundedIcon />
          <FormattedMessage
            id="reviews-list.back"
            defaultMessage="Back" />
        </Button>
        <Typography
          variant="h6"
          className={this.props.classes.title}>
          <FormattedMessage
            id="reviews-list.title"
            defaultMessage="Back" />
        </Typography>
        {meal && (
          <div className={this.props.classes.content}>
            <div
              key={meal.id}
              className={this.props.classes.meal}>
              <div
                className={this.props.classes.mealImage}
                style={{
                  backgroundImage: `url(${getImageForMeal(meal)})`
                }} />
              <Typography
                variant="body1"
                className={this.props.classes.mealName}>
                {meal.name}
              </Typography>
              <Typography
                variant="body1"
                className={this.props.classes.mealRating}>
                <StarIcon
                  color="primary"
                  className={this.props.classes.mealRatingStar} />
                {round(averageRating)}
              </Typography>
              <div className={this.props.classes.mealCommentsContainer}>
                <Typography
                  variant="body1"
                  className={this.props.classes.mealComments}>
                  <FormattedMessage
                    id="reviews-list.reviews"
                    defaultMessage="Reviews" />
                  <CommentBubbleIcon
                    fill="#979797"
                    number={commentCount}
                    className={this.props.classes.mealCommentsIcon} />
                </Typography>
                <Typography
                  variant="body1"
                  className={this.props.classes.mealReservations}>
                  <FormattedMessage
                    id="reviews-list.reservation-count"
                    defaultMessage="Orders: {count}"
                    values={{
                      count: paymentsMeal ? paymentsMeal.reservations.length : 0,
                    }} />
                </Typography>
              </div>
            </div>
            <div className={this.props.classes.reviews}>
              {reviews.map((review, index) => (
                <div
                  key={index}
                  className={this.props.classes.review}>
                  <Typography
                    variant="body1"
                    className={this.props.classes.reviewDate}>
                    {moment(review.pickup.date, 'YYYYMMDD').format('M/D (ddd)')}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classNames(this.props.classes.reviewRating, {
                      [this.props.classes.reviewRatingNoComment]: !review.comment,
                    })}>
                    <StarIcon
                      color="primary"
                      className={this.props.classes.reviewRatingStar} />
                    {round(review.rating)}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={this.props.classes.reviewComment}>
                    {review.comment}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  myShops: state.api.myShops.default,
  paymentSchedule: state.api.paymentSchedule[state.api.paymentSchedule.lastRequestedKey],
  reviews: state.api.reviews.default,
  meals: state.api.meals.default,
})

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    getMyShops,
    getReviews,
    getMeals,
  }, dispatch),
  dispatch,
})

ReviewsList.fetchData = fetchData

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(ReviewsList)))
