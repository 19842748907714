import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import classNames from 'classnames'
import {
  Field,
  reduxForm,
  reset,
  formValueSelector,
} from 'redux-form'
import SwipeableViews from 'react-swipeable-views'
import {
  injectIntl,
  defineMessages,
  FormattedMessage,
} from 'react-intl'
import Dropzone from 'react-dropzone'
import {
  TextField,
  Checkbox,
} from 'redux-form-material-ui'
import deepOrange from '@material-ui/core/colors/deepOrange'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import Divider from '@material-ui/core/Divider'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/Close'
import CloudUploadIcon from '@material-ui/icons/CloudUploadRounded'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import InfoIcon from '@material-ui/icons/Info'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  getMeals,
  getSides,
  getOpenRequests,
  addAvailabilityToSide,
  removeAvailabilityFromSide,
  uploadImage,
  createNewSideRequest,
  createSideChangeRequest,
  cancelChangeRequest,
  setSideStatus,
  editSide,
} from '../redux/api/actions'
import promiseMap from '../utils/promiseMap'
import arraysEqual from '../utils/arraysEqual'
import getUrlForImage from '../utils/getUrlForImage'
import makeIntegerNormalizer from '../utils/makeIntegerNormalizer'
import { MEALS_AND_SIDES_SHARED_FIELDS } from './Meal'

const FORM_NAME = 'side'
const PHOTO_CODE_LENGTH = 7

const DEFAULT_CATEGORY = 'ドリンク'

/*
 *  Fields that need to go through a Potluck side change request
 */
const RESTRICTED_FIELDS = [
  'name',
  'price',
  'dailyReservationLimit',
]

const REQUIRED_FIELDS = [
  'name',
  'price',
  'dailyReservationLimit',
]

const MAX_PRICE_YEN = 500
const DAILY_RESERVATION_LIMIT_MIN = 1

const normalizePrice = makeIntegerNormalizer({ min: 0, max: MAX_PRICE_YEN })
const normalizeDailyReservationLimit = makeIntegerNormalizer({ min: DAILY_RESERVATION_LIMIT_MIN })

const messages = defineMessages({
  missingField: {
    id: 'meal.missing-field',
    defaultMessage: 'This field is required.',
  },
  dailyReservationLimitMinimumNotMet: {
    id: 'meal.daily-reservation-limit-minimum-not-met',
    defaultMessage: 'The minimum reservation limit is 1.',
  },
  descriptionPlaceholder: {
    id: 'meal.description-placeholder',
    defaultMessage: '(Ex) This menu item is one of the most popular in our shop, please try it',
  },
  ingredientsPlaceholder: {
    id: 'meal.ingredients-placeholder',
    defaultMessage: '(Ex) Pasta, pepper, cheese',
  },
  changeName: {
    id: 'meal.change-name',
    defaultMessage: 'Change the meal name from "{previousValue}" to "{newValue}"',
  },
  changePrice: {
    id: 'meal.change-price',
    defaultMessage: 'Change the price from {previousValue}円 to {newValue}円',
  },
  changeDailyReservationLimit: {
    id: 'meal.change-daily-reservation-limit',
    defaultMessage: 'Change the reservation limit from {previousValue} to {newValue}',
  },
  changeCategory: {
    id: 'meal.change-category',
    defaultMessage: 'Change the meal category from "{previousValue}" to "{newValue}"',
  },
  changeMedia: {
    id: 'meal.change-media',
    defaultMessage: 'Change the meal images to:',
  },
  categoryDrink: {
    id: 'sides.drink',
    defaultMessage: 'Drink'
  },
  categorySoup: {
    id: 'sides.soup',
    defaultMessage: 'Soup'
  },
  categorySalad: {
    id: 'sides.salad',
    defaultMessage: 'Salad'
  },
  categoryDessert: {
    id: 'sides.dessert',
    defaultMessage: 'Dessert'
  },
  categoryOther: {
    id: 'sides.other',
    defaultMessage: 'Other'
  },
})

const OPERATIONS_TO_MESSAGES = {
  'CHANGE_SIDE_NAME': messages.changeName,
  'CHANGE_SIDE_PRICE': messages.changePrice,
  'CHANGE_SIDE_DAILY_RESERVATION_LIMIT': messages.changeDailyReservationLimit,
  'CHANGE_SIDE_CATEGORY': messages.changeCategory,
  'CHANGE_SIDE_IMAGES': messages.changeMedia,
}

const CATEGORIES = [{
  value: 'ドリンク',
  code: 'drink',
  label: messages.categoryDrink,
}, {
  value: 'スープ',
  code: 'soup',
  label: messages.categorySoup,
}, {
  value: 'サラダ',
  code: 'salad',
  label: messages.categorySalad,
}, {
  value: 'デザート',
  code: 'dessert',
  label: messages.categoryDessert,
}, {
  value: 'その他',
  code: 'other',
  label: messages.categoryOther,
}]

// https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
const generatePhotoCode = () => {
  var text = ""
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

  for (var i = 0; i < PHOTO_CODE_LENGTH; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return text
}

const DeleteConfirmationDialog = withStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  content: {
    textAlign: 'center',
    padding: theme.spacing(4),
  },
  text: {
    fontSize: 18,
  },
  cta: {
    minWidth: 180,
    marginTop: theme.spacing(4),
  },
  buttonProgress: {
    position: 'absolute',
  },
}))(({ open, loading, onClickConfirmDelete, onClose, classes }) => (
  <Dialog
    open={open}
    fullWidth
    maxWidth="sm"
    onClose={onClose}>
    <DialogTitle className={classes.title}>
      <IconButton
        aria-label="Close"
        className={classes.closeButton}
        onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent className={classes.content}>
      <Typography
        variant="body1"
        className={classes.text}>
        <FormattedMessage
          id="side.delete-warning"
          defaultMessage="Are you sure?"
          values={{
            br: (<br />),
          }} />
      </Typography>
      <div>
        <Button
          variant="contained"
          color="primary"
          size="large"
          disabled={loading}
          className={classes.cta}
          onClick={onClickConfirmDelete}>
          <FormattedMessage
            id="side.delete-warning-cta"
            defaultMessage="Delete" />
          {loading && (
            <CircularProgress
              size={24}
              className={classes.buttonProgress} />
          )}
        </Button>
      </div>
    </DialogContent>
  </Dialog>
))

const validate = (values, props) => {
  const errors = {}

  for (let fieldName of REQUIRED_FIELDS) {
    if (values[fieldName] === undefined) {
      errors[fieldName] = props.intl.formatMessage(messages.missingField)
    }
  }

  if (values.dailyReservationLimit < DAILY_RESERVATION_LIMIT_MIN) {
    errors.dailyReservationLimit = props.intl.formatMessage(messages.dailyReservationLimitMinimumNotMet)
  }

  return errors
}

const fetchData = (dispatch, params, shop) => {
  const promises = {
    meals: dispatch(getMeals()),
    sides: dispatch(getSides()),
  }

  if (params.sideId !== 'new') {
    promises.openRequests = dispatch(getOpenRequests(shop.id))
  }

  return promiseMap(promises)
}

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  changeRequest: {
    backgroundColor: deepOrange[500],
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  changeRequestTop: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  changeRequestIcon: {
    color: '#ffffff',
    marginRight: theme.spacing(1),
  },
  changeRequestTitle: {
    flex: 1,
    color: '#ffffff',
    fontWeight: 'bold',
  },
  changeRequestText: {
    color: '#ffffff',
  },
  changeRequestImages: {
    display: 'flex',
  },
  changeRequestImage: {
    height: 50,
    width: 75,
    borderRadius: 2,
    margin: 5,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  content: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  formBody: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  leftSection: {
    width: 310,
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  rightSection: {
    flex: 1,
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginTop: theme.spacing(2),
    },
  },
  carousel: {
    position: 'relative',
    minHeight: 200,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    cursor: 'pointer',
    '&:focus': {
      outline: 0,
    },
  },
  carouselDrag: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  previous: {
    position: 'absolute',
    left: -20,
    top: 'calc(50% - 20px)',
    zIndex: 1,
  },
  next: {
    position: 'absolute',
    right: -20,
    top: 'calc(50% - 20px)',
    zIndex: 1,
  },
  image: {
    position: 'relative',
    height: 200,
    background: '#E0E0E0',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  deleteImageCta: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  imageLoading: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageEmpty: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageEmptyIcon: {
    height: 40,
    width: 40,
  },
  imageCta: {
    display: 'flex',
    marginTop: theme.spacing(4),
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
  availabilitySection: {
    marginTop: theme.spacing(4),
    backgroundColor: 'rgba(255, 96, 105, 0.08)',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
  availabilitySectionPart: {
    padding: theme.spacing(2),
  },
  availabilityTitle: {
    fontSize: 16,
    color: '#292529',
    marginBottom: theme.spacing(1),
  },
  availabilityDisclaimer: {
    fontSize: 12,
    color: '#767676',
  },
  divider: {
  },
  checkboxField: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1, 0),
  },
  checkboxLabel: {
    marginLeft: theme.spacing(2),
  },
  addPhotoCta: {
    position: 'relative',
    flex: 1,
    color: '#A8B2B9',
    marginRight: theme.spacing(1),
  },
  addPhotoCtaInput: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    cursor: 'pointer',
  },
  addPhotoStatus: {
    flex: 1,
    fontSize: 14,
    color: '#292529',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      textAlign: 'center',
    },
  },
  topForm: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  radioTitle: {
    color: '#292529',
    fontSize: 16,
    marginBottom: 8,
  },
  radioGroupColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    columnSount: 2,
    marginBottom: theme.spacing(2),
  },
  radio: {
    minWidth: 100,
  },
  disclaimer: {
    fontSize: 12,
    color: '#828282',
    marginTop: theme.spacing(2),
  },
  formField: {
    marginBottom: theme.spacing(4),
    backgroundColor: '#ffffff',
  },
  formFieldsHorizontal: {
    display: 'flex',
  },
  formFieldHorizontal: {
    flex: 1,
  },
  formFieldHorizontalMargin: {
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(2),
    },
  },
  ctaContainer: {
    display: 'flex',
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  cancelCta: {
    flex: 1,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 0),
    },
  },
  saveCta: {
    flex: 1,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 0),
    },
  },
  ctaProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  deleteCtaContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 0),
    },
  },
  deleteCta: {
    color: '#292529',
    alignSelf: 'center',
  },
})

class Side extends Component {
  constructor(props) {
    super(props)

    let category = DEFAULT_CATEGORY

    if (props.side) {
      category = props.side.category
    }

    this.state = {
      deleteConfirmationDialogOpen: false,
      deleteConfirmationDialogLoading: false,
      type: 'side',
      category,
      loading: false,
      imageIndex: 0,
      mediaLoading: false,
      media: [],
    }
  }

  hasLoaded = () => {
    return this.props.sides.loaded
  }

  isDirty = () => {
    return !this.props.pristine
      || this.categoryHasChanged()
      || (this.props.side && !arraysEqual(this.state.images, this.props.side.images))
  }

  isNewSide = () => this.props.match.params.sideId === 'new'

  shouldDisableTypeField = () => false

  categoryHasChanged = () => this.props.side && (this.state.category !== this.props.side.category)

  onClickChangeType = (event, value) => {
    this.setState({
      type: value,
    })

    if (value === 'meal') {
      this.props.history.push('/meal/new', {
        ...this.props.sharedFields,
        images: this.state.images,
      })
    }
  }

  onChangeImageIndex = imageIndex => {
    this.setState({
      imageIndex,
    })
  }

  onClickPreviousImage = event => {
    event.stopPropagation()

    if (this.state.imageIndex > 0) {
      this.setState({
        imageIndex: this.state.imageIndex - 1,
      })
    }
  }

  onClickNextImage = event => {
    event.stopPropagation()

    if (this.state.imageIndex < this.state.images.length - 1) {
      this.setState({
        imageIndex: this.state.imageIndex + 1,
      })
    }
  }

  onClickDeletePhoto = (event, image) => {
    event.stopPropagation()

    this.setState({
      images: this.state.images.filter(item => item !== image),
      imageIndex: Math.max(this.state.imageIndex - 1, 0),
    })
  }

  onDropFiles = async files => {
    this.setState({ mediaLoading: true })

    for (let file of files) {
      const code = generatePhotoCode()
      const filename = `/potluck-webapp/meal-photos/${code}-${file.name}`

      const upload = await this.props.uploadImage(file, filename)

      this.setState({
        images: [...this.state.images, ...upload.map(item => item.uuid)],
        imageIndex: this.state.images.length,
      })
    }

    this.setState({ mediaLoading: false })
  }

  onUploadFile = async event => {
    this.setState({ mediaLoading: true })
    const file = event.target.files[0]
    const code = generatePhotoCode()
    const filename = `/potluck-webapp/meal-photos/${code}-${file.name}`

    const upload = await this.props.uploadImage(file, filename)

    this.setState({
      images: [...this.state.images, ...upload.map(item => item.uuid)],
      imageIndex: this.state.images.length,
      mediaLoading: false,
    })
  }

  onClickChangeCategory = (event, value) => {
    this.setState({
      category: value,
    })
  }

  onClickCancel = () => {
    this.props.history.push('/menu')
  }

  onClickCancelChangeRequest = async changeRequest => {
    await this.props.cancelChangeRequest(changeRequest.id)
    await this.props.getOpenRequests(this.props.shop.id)

    this.setState({
      images: this.props.side.images,
    })

    this.props.dispatch(reset(FORM_NAME))
  }

  onClickSave = async (values, ...rest)  => {
    this.setState({
      loading: true,
    })

    if (this.isNewSide()) {
      await this.props.createNewSideRequest({
        ...values,
        category: this.state.category,
        images: this.state.images,
        shopId: this.props.shop.id,
      })

      await this.props.getOpenRequests(this.props.shop.id)
      this.props.history.push('/menu')
    } else {
      for (let sideType of ['lunch', 'dinner']) {
        if (values[sideType] && !this.props.initialValues[sideType]) {
          await this.props.addAvailabilityToSide({
            sideId: this.props.side.id,
            type: sideType,
          })
        }

        if (!values[sideType] && this.props.initialValues[sideType]) {
          await this.props.removeAvailabilityFromSide({
            sideId: this.props.side.id,
            type: sideType,
          })
        }
      }

      const restrictedFieldHasChanged = Object.keys(values)
        .filter(field => RESTRICTED_FIELDS.includes(field))
        .some(field => values[field] !== this.props.initialValues[field])

      if (restrictedFieldHasChanged
        || this.categoryHasChanged()
        || !arraysEqual(this.state.images, this.props.side.images)) {
        values.category = this.state.category
        values.images = this.state.images

        await this.props.createSideChangeRequest({
          sideId: this.props.side.id,
          values,
        })
      }

      const freeFieldsChanged = Object.keys(values)
        .filter(field => !RESTRICTED_FIELDS.includes(field))
        .filter(field => values[field] !== this.props.initialValues[field])

      if (freeFieldsChanged.length > 0) {
        const options = freeFieldsChanged.reduce((memo, key) => ({
          ...memo,
          [key]: values[key],
        }), {})

        await this.props.editSide(this.props.side.id, options)
      }
    }

    await fetchData(this.props.dispatch, this.props.match.params, this.props.shop)

    this.props.dispatch(reset(FORM_NAME))

    this.setState({
      loading: false,
    })
  }

  onClickDelete = () => {
    this.setState({
      deleteConfirmationDialogOpen: true,
    })
  }

  onCloseDeleteConfirmationDialog = () => {
    this.setState({
      deleteConfirmationDialogOpen: false,
    })
  }

  onClickConfirmDelete = async () => {
    this.setState({
      deleteConfirmationDialogLoading: true,
    })

    await this.props.setSideStatus({
      sideId: this.props.match.params.sideId,
      status: 'hidden_from_search',
    })

    await fetchData(this.props.dispatch, this.props.match.params, this.props.shop)

    this.setState({
      deleteConfirmationDialogLoading: false,
    })

    this.props.history.push('/menu')
  }

  async componentWillMount() {
    let images = []

    if (!this.hasLoaded()) {
      await fetchData(this.props.dispatch, this.props.match.params, this.props.shop)

      if (!this.isNewSide() && this.props.side) {
        images = this.props.side.images
      }
    } else if (!this.isNewSide()) {
      images = this.props.side.images
    }

    this.setState({
      images,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.side && this.props.side) {
      this.setState({
        category: this.props.side.category,
      })
    }
  }

  render() {
    return (
      <div className={this.props.classes.container}>
        <DeleteConfirmationDialog
          open={this.state.deleteConfirmationDialogOpen}
          loading={this.state.deleteConfirmationDialogLoading}
          onClose={this.onCloseDeleteConfirmationDialog}
          onClickConfirmDelete={this.onClickConfirmDelete} />
        {this.props.openRequestForSide && (
          <Paper className={this.props.classes.changeRequest}>
            <div className={this.props.classes.changeRequestTop}>
              <InfoIcon className={this.props.classes.changeRequestIcon} />
              <div className={this.props.classes.changeRequestTitle}>
              <FormattedMessage
                id="side.in-review"
                defaultMessage="In review" />
              </div>
            </div>
            <div className={this.props.classes.changeRequestText}>
              <FormattedMessage
                id="side.review-message"
                defaultMessage="The Potluck team is reviewing the following change request and will update this menu item as soon as possible:" />
            </div>
            <ul>
              {this.props.openRequestForSide.operations.map(operation => (
                <li
                  key={operation.type}
                  className={this.props.classes.changeRequestText}>
                  {this.props.intl.formatMessage(OPERATIONS_TO_MESSAGES[operation.type], {
                    previousValue: operation.previousValue,
                    newValue: operation.newValue,
                  })}
                  {operation.type === 'CHANGE_SIDE_IMAGES' && (
                    <div className={this.props.classes.changeRequestImages}>
                      {operation.newValue.map(image => (
                        <div
                          key={image}
                          className={this.props.classes.changeRequestImage}
                          style={{
                            backgroundImage: `url(${getUrlForImage(image)})`,
                          }} />
                      ))}
                    </div>
                  )}
                </li>
              ))}
            </ul>
            <Button
              variant="contained"
              className={this.props.classes.button}
              onClick={() => this.onClickCancelChangeRequest(this.props.openRequestForSide)}>
              <FormattedMessage
                id="side.cancel"
                defaultMessage="Cancel" />
            </Button>
          </Paper>
        )}
        <Paper className={this.props.classes.content}>
          <form>
            <div className={this.props.classes.formBody}>
              <div className={this.props.classes.leftSection}>
                <Dropzone onDrop={this.onDropFiles}>
                  {({ getRootProps, getInputProps, isDragActive }) => (
                    <div
                      {...getRootProps()}
                      className={classNames(this.props.classes.carousel, {
                        [this.props.classes.carouselDrag]: isDragActive,
                      })}>
                      <input {...getInputProps()} />
                      {this.state.images && this.state.images.length === 0 && (
                        <div className={this.props.classes.imageEmpty}>
                          <CloudUploadIcon
                            color={isDragActive ? 'primary' : 'disabled'}
                            className={this.props.classes.imageEmptyIcon} />
                        </div>
                      )}
                      {this.state.images && this.state.images.length > 1 && (
                        <Fab
                          size="small"
                          color="primary"
                          disabled={this.state.imageIndex === 0}
                          aria-label="Previous"
                          className={this.props.classes.previous}
                          onClick={this.onClickPreviousImage}>
                          <NavigateBeforeIcon />
                        </Fab>
                      )}
                      <SwipeableViews
                        enableMouseEvents
                        index={this.state.imageIndex}
                        onChangeIndex={this.onChangeImageIndex}>
                        {this.state.images && this.state.images.map((item, index) => (
                          <div
                            key={item}
                            className={this.props.classes.image}
                            style={{
                              backgroundImage: `url(${getUrlForImage(item)})`,
                            }}>
                            {(this.state.images.length > 1) && !this.props.openRequestForMeal && (
                              <Fab
                                color="secondary"
                                size="small"
                                className={this.props.classes.deleteImageCta}
                                onClick={event => this.onClickDeletePhoto(event, item)}>
                                <DeleteIcon />
                              </Fab>
                            )}
                          </div>
                        ))}
                      </SwipeableViews>
                      {this.state.images && this.state.images.length > 1 && (
                        <Fab
                          size="small"
                          color="primary"
                          disabled={this.state.imageIndex === this.state.images.length - 1}
                          aria-label="Next"
                          className={this.props.classes.next}
                          onClick={this.onClickNextImage}>
                          <NavigateNextIcon />
                        </Fab>
                      )}
                      {this.state.mediaLoading && (
                        <div className={this.props.classes.imageLoading}>
                          <CircularProgress />
                        </div>
                      )}
                    </div>
                  )}
                </Dropzone>
                <div className={this.props.classes.imageCta}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    className={this.props.classes.addPhotoCta}>
                    <FormattedMessage
                      id="side.add-photos"
                      defaultMessage="Add photos" />
                    <input
                      type='file'
                      disabled={this.props.openRequestForSide}
                      className={this.props.classes.addPhotoCtaInput}
                      onChange={this.onUploadFile} />
                  </Button>
                  <Typography
                    variant="body1"
                    color="secondary"
                    className={this.props.classes.addPhotoStatus}>
                    <FormattedMessage
                      id="side.no-photo-selected"
                      defaultMessage="Nothing selected" />
                  </Typography>
                </div>
                <div className={this.props.classes.availabilitySection}>
                  <div className={this.props.classes.availabilitySectionPart}>
                    <Typography
                      variant="body1"
                      color="secondary"
                      className={this.props.classes.availabilityTitle}>
                      <FormattedMessage
                        id="side.availability-title"
                        defaultMessage="Meal availability" />
                    </Typography>
                    <Typography
                      variant="caption"
                      color="secondary"
                      className={this.props.classes.availabilityDisclaimer}>
                      <FormattedMessage
                        id="side.availability-disclaimer"
                        defaultMessage="Meals will not be available on closed days (according to the hours page), regardless of this setting." />
                    </Typography>
                  </div>
                  <Divider className={this.props.classes.divider} />
                  <div className={this.props.classes.availabilitySectionPart}>
                    <div className={this.props.classes.checkboxField}>
                      <Field
                        name="lunch"
                        disabled={!!this.props.openRequestForSide}
                        component={Checkbox}
                        color="primary" />
                      <Typography
                        variant="body1"
                        className={this.props.classes.checkboxLabel}>
                        <FormattedMessage
                          id="side.lunch"
                          defaultMessage="Lunch" />
                      </Typography>
                    </div>
                    <div className={this.props.classes.checkboxField}>
                      <Field
                        name="dinner"
                        disabled={!!this.props.openRequestForSide}
                        component={Checkbox}
                        color="primary" />
                      <Typography
                        variant="body1"
                        className={this.props.classes.checkboxLabel}>
                        <FormattedMessage
                          id="side.dinner"
                          defaultMessage="Dinner" />
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
              <div className={this.props.classes.rightSection}>
                <div className={this.props.classes.topForm}>
                  {this.props.sideId === 'new' && (
                    <RadioGroup
                      name="type"
                      value={this.state.type}
                      className={this.props.classes.radioGroup}
                      onChange={this.onClickChangeType}>
                      <FormControl className={this.props.classes.radio}>
                        <FormControlLabel
                          label={(
                            <FormattedMessage
                              id="side.meal"
                              defaultMessage="Meal" />
                          )}
                          control={(
                            <Radio
                              value="meal"
                              color="primary"
                              disabled={this.shouldDisableTypeField()} />
                          )} />
                      </FormControl>
                      <FormControl className={this.props.classes.radio}>
                        <FormControlLabel
                          label={(
                            <FormattedMessage
                              id="side.side"
                              defaultMessage="Side" />
                          )}
                          control={(
                            <Radio
                              value="side"
                              color="primary"
                              disabled={this.shouldDisableTypeField()} />
                          )} />
                      </FormControl>
                    </RadioGroup>
                  )}
                  <Field
                    name="name"
                    variant="outlined"
                    label={(
                      <FormattedMessage
                        id="side.name"
                        defaultMessage="Name" />
                    )}
                    disabled={!!this.props.openRequestForSide}
                    className={this.props.classes.formField}
                    component={TextField} />
                    <div className={this.props.classes.formFieldsHorizontal}>
                      <Field
                        name="price"
                        type="number"
                        variant="outlined"
                        label={(
                          <FormattedMessage
                            id="side.price"
                            defaultMessage="Price" />
                        )}
                        className={classNames(this.props.classes.formField, this.props.classes.formFieldHorizontal)}
                        normalize={normalizePrice}
                        component={TextField} />
                      <Field
                        name="dailyReservationLimit"
                        type="number"
                        variant="outlined"
                        label={(
                          <FormattedMessage
                            id="side.daily-reservation-limit"
                            defaultMessage="Daily order limit" />
                        )}
                        disabled={!!this.props.openRequestForSide}
                        className={classNames(this.props.classes.formField, this.props.classes.formFieldHorizontal, this.props.classes.formFieldHorizontalMargin)}
                        normalize={normalizeDailyReservationLimit}
                        component={TextField} />
                    </div>
                    <Typography
                        variant="body1"
                        className={this.props.classes.radioTitle}>
                        サイドメニューの種類
                      </Typography>
                    <RadioGroup
                      name="category"
                      value={this.state.category}
                      className={this.props.classes.radioGroup}
                      onChange={this.onClickChangeCategory}>
                      <div className={this.props.classes.radioGroupColumn}>
                        {CATEGORIES.filter((category, index) => index % 2 === 0).map(category => (
                          <FormControl
                            key={category.code}
                            className={this.props.classes.radio}>
                            <FormControlLabel
                              label={this.props.intl.formatMessage(category.label)}
                              control={(
                                <Radio
                                  value={category.value}
                                  color="primary" />
                              )} />
                          </FormControl>
                        ))}
                      </div>
                      <div className={this.props.classes.radioGroupColumn}>
                        {CATEGORIES.filter((category, index) => index % 2 === 1).map(category => (
                          <FormControl
                            key={category.code}
                            className={this.props.classes.radio}>
                            <FormControlLabel
                              label={this.props.intl.formatMessage(category.label)}
                              control={(
                                <Radio
                                  value={category.value}
                                  color="primary" />
                              )} />
                          </FormControl>
                        ))}
                      </div>
                      <Typography
                        variant="body1"
                        className={this.props.classes.disclaimer}>
                        <FormattedMessage
                          id="side.disclaimer"
                          defaultMessage="Maximum price {amount}.{break}20% of the amount will be deducted as a commission starting in December 2020. Until then there is no commission."
                          values={{
                            amount: MAX_PRICE_YEN,
                            break: (<br />)
                          }} />
                      </Typography>
                    </RadioGroup>
                </div>
              </div>
            </div>
            <div className={this.props.classes.ctaContainer}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                disabled={this.state.loading}
                className={this.props.classes.cancelCta}
                onClick={this.onClickCancel}>
                <FormattedMessage
                  id="side.cancel"
                  defaultMessage="Cancel" />
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={(this.props.pristine && !this.isDirty()) || this.props.invalid || this.state.loading || !!this.props.openRequestForSide}
                className={this.props.classes.saveCta}
                onClick={this.props.handleSubmit(this.onClickSave)}>
                {this.state.loading && (
                  <CircularProgress
                    size={24}
                    color="primary"
                    className={this.props.classes.ctaProgress} />
                )}
                <FormattedMessage
                  id="side.save"
                  defaultMessage="Save" />
              </Button>
            </div>
            {!this.isNewSide() && (
              <div className={this.props.classes.deleteCtaContainer}>
                <Button
                  variant="text"
                  color="secondary"
                  size="large"
                  disabled={this.state.loading || !!this.props.openRequestForSide}
                  className={this.props.classes.deleteCta}
                  onClick={this.onClickDelete}>
                  <FormattedMessage
                    id="side.delete-meal"
                    defaultMessage="Delete" />
                </Button>
              </div>
            )}
          </form>
        </Paper>
      </div>
    )
  }
}

const selector = formValueSelector(FORM_NAME)

const mapStateToProps = (state, ownProps) => {
  let sideId = ownProps.match.params.sideId
  let initialValues = {
    price: 0,
    dailyReservationLimit: 10,
  }
  let openRequestForSide
  let side

  if (ownProps.history.location.state) {
    initialValues = {
      ...initialValues,
      ...ownProps.history.location.state
    }
  }

  if (sideId !== 'new') {
    sideId = parseInt(ownProps.match.params.sideId, 10)

    if (state.api.sides.default.loaded) {
      side = state.api.sides.default.data.find(side => side.id === sideId)

      if (side) {
        initialValues = {
          name: side.name,
          price: side.price,
          dailyReservationLimit: side.dailyReservationLimit,
          category: side.category,
          lunch: side.availability.lunch.length > 0,
          dinner: side.availability.dinner.length > 0,
        }
      }
    }

    if (state.api.openRequests.default.loaded) {
      openRequestForSide = state.api.openRequests.default.data.find(openRequest => openRequest.operations.some(operation => operation.sideId === sideId))
    }
  }

  return {
    sideId,
    initialValues,
    openRequestForSide,
    side,
    shop: state.features.shop,
    sides: state.api.sides.default,
    price: selector(state, 'price'),
    sharedFields: MEALS_AND_SIDES_SHARED_FIELDS.reduce((memo, field) => ({
      ...memo,
      [field]: selector(state, field),
    }), {}),
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    getSides,
    getOpenRequests,
    addAvailabilityToSide,
    removeAvailabilityFromSide,
    uploadImage,
    createNewSideRequest,
    createSideChangeRequest,
    cancelChangeRequest,
    setSideStatus,
    editSide,
  }, dispatch),
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(reduxForm({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
})((withStyles(styles)(Side)))))
