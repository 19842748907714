import { defineMessages } from 'react-intl'

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i

defineMessages({
  missingField: {
    id: 'login.missing-field',
    defaultMessage: 'This field is required',
  },
  emailInvalid: {
    id: 'login.email-invalid',
    defaultMessage: 'This email is invalid',
  },
})

export default (values, props) => {
  const errors = {}

  if (!values.email) {
    errors.email = { id: 'login.missing-field' }
  } else if (!EMAIL_REGEX.test(values.email)) {
    errors.email = { id: 'login.email-invalid' }
  }

  if (!values.password) {
    errors.password = { id: 'login.missing-field' }
  }

  return errors
}
