import React from 'react'

const SvgComponent = ({ color, ...rest }) => (
  <svg width="1em" height="1em" viewBox="0 0 19 19" fill="none" {...rest}>
    <mask
      id="prefix__a"
      maskUnits="userSpaceOnUse"
      x={9.899}
      y={0}
      width={9}
      height={11}
      fill="#000"
    >
      <path fill="#fff" d="M9.899 0h9v11h-9z" />
      <path d="M14.675 1h.024c2.46.085 3.75 3.348 3.75 5.065.037 1.936-1.668 3.666-3.738 3.775h-.073c-2.058-.11-3.774-1.839-3.738-3.787 0-1.717 1.29-4.968 3.75-5.053h.025zm0 7.415c1.278-.085 2.386-1.168 2.362-2.337 0-1.267-.974-3.593-2.362-3.666-1.388.073-2.363 2.4-2.363 3.653-.012 1.181 1.084 2.277 2.363 2.35z" />
    </mask>
    <path
      d="M14.675 1h.024c2.46.085 3.75 3.348 3.75 5.065.037 1.936-1.668 3.666-3.738 3.775h-.073c-2.058-.11-3.774-1.839-3.738-3.787 0-1.717 1.29-4.968 3.75-5.053h.025zm0 7.415c1.278-.085 2.386-1.168 2.362-2.337 0-1.267-.974-3.593-2.362-3.666-1.388.073-2.363 2.4-2.363 3.653-.012 1.181 1.084 2.277 2.363 2.35z"
      fill={color || '#A8B2B9'}
    />
    <path
      d="M14.675 1h.024c2.46.085 3.75 3.348 3.75 5.065.037 1.936-1.668 3.666-3.738 3.775h-.073c-2.058-.11-3.774-1.839-3.738-3.787 0-1.717 1.29-4.968 3.75-5.053h.025zm0 7.415c1.278-.085 2.386-1.168 2.362-2.337 0-1.267-.974-3.593-2.362-3.666-1.388.073-2.363 2.4-2.363 3.653-.012 1.181 1.084 2.277 2.363 2.35z"
      stroke={color || '#A8B2B9'}
      strokeWidth={0.5}
      mask="url(#prefix__a)"
    />
    <mask
      id="prefix__b"
      maskUnits="userSpaceOnUse"
      x={11.677}
      y={7.537}
      width={6}
      height={12}
      fill="#000"
    >
      <path fill="#fff" d="M11.677 7.537h6v12h-6z" />
      <path d="M13.615 8.537h.024c.39.012.694.341.682.73l-.231 7.307c0 .073.036.122.073.17.11.122.304.183.511.183.207 0 .39-.073.512-.183a.242.242 0 00.073-.158l-.232-7.306a.707.707 0 01.682-.73c.378-.037.719.292.731.681l.231 7.306c.013.439-.146.853-.474 1.194-.378.39-.938.62-1.523.62-.584 0-1.144-.218-1.522-.62a1.654 1.654 0 01-.475-1.194l.232-7.306a.712.712 0 01.706-.694z" />
    </mask>
    <path
      d="M13.615 8.537h.024c.39.012.694.341.682.73l-.231 7.307c0 .073.036.122.073.17.11.122.304.183.511.183.207 0 .39-.073.512-.183a.242.242 0 00.073-.158l-.232-7.306a.707.707 0 01.682-.73c.378-.037.719.292.731.681l.231 7.306c.013.439-.146.853-.474 1.194-.378.39-.938.62-1.523.62-.584 0-1.144-.218-1.522-.62a1.654 1.654 0 01-.475-1.194l.232-7.306a.712.712 0 01.706-.694z"
      fill={color || '#A8B2B9'}
    />
    <path
      d="M13.615 8.537h.024c.39.012.694.341.682.73l-.231 7.307c0 .073.036.122.073.17.11.122.304.183.511.183.207 0 .39-.073.512-.183a.242.242 0 00.073-.158l-.232-7.306a.707.707 0 01.682-.73c.378-.037.719.292.731.681l.231 7.306c.013.439-.146.853-.474 1.194-.378.39-.938.62-1.523.62-.584 0-1.144-.218-1.522-.62a1.654 1.654 0 01-.475-1.194l.232-7.306a.712.712 0 01.706-.694z"
      stroke={color || '#A8B2B9'}
      strokeWidth={0.5}
      mask="url(#prefix__b)"
    />
    <mask
      id="prefix__c"
      maskUnits="userSpaceOnUse"
      x={1.85}
      y={7.537}
      width={6}
      height={12}
      fill="#000"
    >
      <path fill="#fff" d="M1.85 7.537h6v12h-6z" />
      <path d="M3.788 8.537h.025c.39.012.694.341.682.73l-.232 7.307c0 .073.037.122.073.17.11.122.305.183.512.183.207 0 .39-.073.511-.183.037-.036.085-.097.073-.158L5.201 9.28a.707.707 0 01.682-.73c.402-.037.718.292.73.681l.232 7.306c.012.439-.146.865-.475 1.194-.378.39-.938.62-1.522.62-.585 0-1.145-.218-1.522-.62a1.654 1.654 0 01-.475-1.194l.231-7.306a.712.712 0 01.706-.694z" />
    </mask>
    <path
      d="M3.788 8.537h.025c.39.012.694.341.682.73l-.232 7.307c0 .073.037.122.073.17.11.122.305.183.512.183.207 0 .39-.073.511-.183.037-.036.085-.097.073-.158L5.201 9.28a.707.707 0 01.682-.73c.402-.037.718.292.73.681l.232 7.306c.012.439-.146.865-.475 1.194-.378.39-.938.62-1.522.62-.585 0-1.145-.218-1.522-.62a1.654 1.654 0 01-.475-1.194l.231-7.306a.712.712 0 01.706-.694z"
      fill={color || '#A8B2B9'}
    />
    <path
      d="M3.788 8.537h.025c.39.012.694.341.682.73l-.232 7.307c0 .073.037.122.073.17.11.122.305.183.512.183.207 0 .39-.073.511-.183.037-.036.085-.097.073-.158L5.201 9.28a.707.707 0 01.682-.73c.402-.037.718.292.73.681l.232 7.306c.012.439-.146.865-.475 1.194-.378.39-.938.62-1.522.62-.585 0-1.145-.218-1.522-.62a1.654 1.654 0 01-.475-1.194l.231-7.306a.712.712 0 01.706-.694z"
      stroke={color || '#A8B2B9'}
      strokeWidth={0.5}
      mask="url(#prefix__c)"
    />
    <mask
      id="prefix__d"
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={9}
      height={10}
      fill="#000"
    >
      <path fill="#fff" d="M0 0h9v10H0z" />
      <path d="M1.73 1c.39 0 .707.316.707.706v4.006a2.417 2.417 0 002.41 2.411 2.417 2.417 0 002.412-2.41V1.705a.707.707 0 011.412 0v4.006a3.843 3.843 0 01-3.835 3.836A3.843 3.843 0 011 5.712V1.706A.745.745 0 011.73 1z" />
    </mask>
    <path
      d="M1.73 1c.39 0 .707.316.707.706v4.006a2.417 2.417 0 002.41 2.411 2.417 2.417 0 002.412-2.41V1.705a.707.707 0 011.412 0v4.006a3.843 3.843 0 01-3.835 3.836A3.843 3.843 0 011 5.712V1.706A.745.745 0 011.73 1z"
      fill={color || '#A8B2B9'}
    />
    <path
      d="M1.73 1c.39 0 .707.316.707.706v4.006a2.417 2.417 0 002.41 2.411 2.417 2.417 0 002.412-2.41V1.705a.707.707 0 011.412 0v4.006a3.843 3.843 0 01-3.835 3.836A3.843 3.843 0 011 5.712V1.706A.745.745 0 011.73 1z"
      stroke={color || '#A8B2B9'}
      strokeWidth={0.5}
      mask="url(#prefix__d)"
    />
    <mask
      id="prefix__e"
      maskUnits="userSpaceOnUse"
      x={4.189}
      y={0}
      width={3}
      height={6}
      fill="#000"
    >
      <path fill="#fff" d="M4.189 0h3v6h-3z" />
      <path d="M5.895 1c.39 0 .706.316.706.706v2.959a.707.707 0 01-1.412 0V1.706c0-.39.317-.706.706-.706z" />
    </mask>
    <path
      d="M5.895 1c.39 0 .706.316.706.706v2.959a.707.707 0 01-1.412 0V1.706c0-.39.317-.706.706-.706z"
      fill={color || '#A8B2B9'}
    />
    <path
      d="M5.895 1c.39 0 .706.316.706.706v2.959a.707.707 0 01-1.412 0V1.706c0-.39.317-.706.706-.706z"
      stroke={color || '#A8B2B9'}
      strokeWidth={0.5}
      mask="url(#prefix__e)"
    />
    <mask
      id="prefix__f"
      maskUnits="userSpaceOnUse"
      x={2.106}
      y={0}
      width={3}
      height={6}
      fill="#000"
    >
      <path fill="#fff" d="M2.106 0h3v6h-3z" />
      <path d="M3.813 1c.39 0 .706.316.706.706v2.959a.707.707 0 01-1.413 0V1.706c0-.39.317-.706.707-.706z" />
    </mask>
    <path
      d="M3.813 1c.39 0 .706.316.706.706v2.959a.707.707 0 01-1.413 0V1.706c0-.39.317-.706.707-.706z"
      fill={color || '#A8B2B9'}
    />
    <path
      d="M3.813 1c.39 0 .706.316.706.706v2.959a.707.707 0 01-1.413 0V1.706c0-.39.317-.706.707-.706z"
      stroke={color || '#A8B2B9'}
      strokeWidth={0.5}
      mask="url(#prefix__f)"
    />
  </svg>
)

export default SvgComponent
