import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Field,
  reduxForm,
} from 'redux-form'
import {
  TextField,
} from 'redux-form-material-ui'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import {
  createReservationProblem,
} from '../../redux/api/actions'

const validate = values => {
  const errors = {}

  if (!values.problem) {
    errors.problems = 'Required'
  }

  return errors
}

const styles = theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: '#292529',
    textAlign: 'center',
  },
  content: {
    textAlign: 'center',
  },
  cta: {
    minWidth: 180,
    marginTop: theme.spacing(2),
  },
  ctaProgress: {
    position: 'absolute',
  },
})

class ReservationProblemsDialog extends Component {
  state = {
    success: false,
  }

  onSubmit = async values => {
    this.setState({
      loading: true,
    })

    await this.props.createReservationProblem(values.problem)

    this.setState({
      loading: false,
      success: true,
    })
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        fullWidth
        maxWidth="sm"
        onClose={this.props.onClose}>
        <DialogTitle className={this.props.classes.title}>
          問題を報告
          <IconButton
            aria-label="Close"
            className={this.props.classes.closeButton}
            onClick={this.props.onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          dividers
          className={this.props.classes.content}>
          <Collapse in={!this.state.success}>
            <Field
              name="problem"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              label="本文"
              className={this.props.classes.formField}
              component={TextField} />
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={this.state.loading}
              className={this.props.classes.cta}
              onClick={this.props.handleSubmit(this.onSubmit)}>
              {this.state.loading && (
                <CircularProgress
                  size={24}
                  color="primary"
                  className={this.props.classes.ctaProgress} />
              )}
              送信
            </Button>
          </Collapse>
          <Collapse in={this.state.success}>
            <Typography variant="body2">
              POTLUCKカスタマーサクセスにメッセージが送信されました。確認しご連絡させていただきますので、しばらくお待ちください。
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              className={this.props.classes.cta}
              onClick={this.props.onClose}>
              OK
            </Button>
          </Collapse>
        </DialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  createReservationProblem,
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'reservation-problems',
  validate,
  enableReinitialize: true,
})((withStyles(styles)(ReservationProblemsDialog))))
