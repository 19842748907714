import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { bindActionCreators } from 'redux'
import ls from 'local-storage'
import {
  Field,
  reduxForm,
  SubmissionError,
} from 'redux-form'
import {
  Redirect,
  withRouter,
} from 'react-router-dom'
import {
  injectIntl,
  defineMessages,
  FormattedMessage,
} from 'react-intl'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import green from '@material-ui/core/colors/green'
import CheckRoundedIcon from '@material-ui/icons/CheckRounded'
import {
  setShop,
  setLocale,
} from '../redux/features/actions'
import {
  login,
  getProfile,
  getMyShops,
} from '../redux/api/actions'
import FormField from '../components/FormField/FormField'
import Logo from '../components/Logo'
import Constants from '../utils/Constants'
import promiseMap from '../utils/promiseMap'
import validate from '../utils/validators/loginValidator'

const messages = defineMessages({
  pageTitle: {
    id: 'login.page-title',
    defaultMessage: 'Potluck Shops',
  },
})

const fetchData = dispatch => promiseMap({
  profile: dispatch(getProfile()),
  myShops: dispatch(getMyShops()),
})

const styles = theme => ({
  container: {
  },
  content: {
    maxWidth: theme.spacing(40),
    marginTop: theme.spacing(10),
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(2),
  },
  logoContainer: {
    position: 'relative',
    height: 50,
    width: 200,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(4),
  },
  logo: {
    width: '100%',
    height: '100%',
  },
  logoText: {
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
  cta: {},
  ctaSuccess: {
    backgroundColor: green[500],
    boxShadow: `0px 8.72651px 17.453px ${green[500]}`,
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  ctaProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  forgotPasswordCta: {
    marginTop: theme.spacing(2),
    fontSize: 12,
  },
})

class Login extends Component {
  state = {
    loading: false,
    success: false,
  }

  hasLoaded = () => {
    return this.props.profile.loaded
      && this.props.myShops.loaded
  }

  onClickSubmit = async values => {
    this.setState({ loading: true })

    await this.props.login(values)
    const {
      profile,
      myShops,
    } = await fetchData(this.props.dispatch)

    if (!profile || profile.status >= 300) {
      this.setState({ loading: false })

      throw new SubmissionError({
        _error: (
          <FormattedMessage
            id="login.account-not-found"
            defaultMessage="Email or password is incorrect." />
        ),
      })
    }

    if (!myShops || myShops.length === 0) {
      this.setState({ loading: false })

      throw new SubmissionError({
        _error: (
          <FormattedMessage
            id="login.no-shops-for-account"
            defaultMessage="Your account does not have a shop." />
        ),
      })
    }

    this.setState({
      loading: false,
      success: true,
    })

    const savedShopId = ls('shopId')
    const savedShop = myShops.find(shop => shop.id === savedShopId)

    const selectedShop = savedShop || myShops[0]

    this.props.setShop(selectedShop)

    if (selectedShop.locale) {
      this.props.setLocale(selectedShop.locale)
    }

    setTimeout(() => {
      this.props.history.push('/dashboard')
    }, Constants.FAKE_LATENCY_MS)
  }

  onClickForgotPassword = () => {
    this.props.history.push('/forgot-password')
  }

  componentWillMount() {
    if (!this.hasLoaded()) {
      fetchData(this.props.dispatch)
    }
  }

  render() {
    const {
      handleSubmit,
      invalid,
      error,
    } = this.props

    if (!this.state.success && !this.state.loading && this.props.myShops.data) {
      return (<Redirect to="/dashboard" />)
    }

    return (
      <div className={this.props.classes.container}>
        <Helmet>
          <title>
            {this.props.intl.formatMessage(messages.pageTitle)}
          </title>
        </Helmet>
        <Paper classes={{
          root: this.props.classes.content,
        }}>
          <div className={this.props.classes.logoContainer}>
            <Logo className={this.props.classes.logo} />
            <Typography
              variant="body1"
              color="primary"
              align="right"
              style={styles.logoText}>
              <FormattedMessage
                id="login.shops"
                defaultMessage="Shops" />
            </Typography>
          </div>
          <form>
            {error && (
              <Typography
                variant="caption"
                color="error">
                {error}
              </Typography>
            )}
            <Field
              name="email"
              type="email"
              component={FormField}
              label={(
                <FormattedMessage
                  id="login.email"
                  defaultMessage="Email" />
              )} />
            <Field
              name="password"
              type="password"
              component={FormField}
              label={(
                <FormattedMessage
                  id="login.password"
                  defaultMessage="Password" />
              )} />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              className={classNames(this.props.classes.cta, {
                [this.props.classes.ctaSuccess]: this.state.success,
              })}
              disabled={invalid || this.state.loading}
              onClick={handleSubmit(this.onClickSubmit)}>
              {this.state.loading && (
                <CircularProgress
                  size={24}
                  color="primary"
                  className={this.props.classes.ctaProgress} />
              )}
              {!this.state.success && (
                <FormattedMessage
                  id="login.cta"
                  defaultMessage="Login" />
              )}
              {this.state.success && (<CheckRoundedIcon />)}
            </Button>
            <Button
              fullWidth
              variant="text"
              size="large"
              className={this.props.classes.forgotPasswordCta}
              onClick={this.onClickForgotPassword}>
              パスワードをお忘れですか？
            </Button>
          </form>
        </Paper>
      </div>
    )
  }
}

Login.fetchData = fetchData

const mapStateToProps = state => ({
  profile: state.api.profile.default,
  myShops: state.api.myShops.default,
  loginState: state.api.login.default,
  redirectTo: state.features.redirectTo,
})

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    login,
    setShop,
    setLocale,
  }, dispatch),
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'login',
  validate,
})(withRouter(injectIntl(withStyles(styles)(Login)))))
