import React from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

export default withStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding:  theme.spacing(2, 2, 0, 2),
    margin:  theme.spacing(-2, -2, 0, -2),
    overflow: 'hidden',
  },
  item: {
    border: 'none',
    backgroundColor: '#C4C4C4',
    color: '#ffffff',
    boxShadow: 'none',
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    height: 56,
    borderTopLeftRadius: theme.spacing(),
    borderTopRightRadius: theme.spacing(),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.down('sm')]: {
      flex: 1,
    },
  },
  itemFirst: {
    marginLeft: 0,
  },
  itemLast: {
    marginRight: 0,
  },
  itemSelected: {
    color: '#4F4F4F',
    backgroundColor: '#ffffff',
    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      backgroundColor: '#ffffff',
    }
  }
}))(({ classes, items, index, className, onClickTab, ...rest }) => (
  <div
    {...rest}
    className={classNames(classes.container, className)}>
    {items.map((item, itemIndex) => (
      <Button
        variant="contained"
        size="large"
        className={classNames(classes.item, {
          [classes.itemFirst]: itemIndex === 0,
          [classes.itemLast]: itemIndex === items.length - 1,
          [classes.itemSelected]: itemIndex === index,
        })}
        onClick={onClickTab ? () => onClickTab(itemIndex, item.name) : null}>
        {item.label}
      </Button>
    ))}
  </div>
))
