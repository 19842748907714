import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import classNames from 'classnames'
import {
  Field,
  reduxForm,
  reset,
  formValueSelector,
} from 'redux-form'
import SwipeableViews from 'react-swipeable-views'
import {
  injectIntl,
  defineMessages,
  FormattedMessage,
} from 'react-intl'
import Dropzone from 'react-dropzone'
import {
  TextField,
  Checkbox,
} from 'redux-form-material-ui'
import deepOrange from '@material-ui/core/colors/deepOrange'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import Divider from '@material-ui/core/Divider'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grow from '@material-ui/core/Grow'
import Collapse from '@material-ui/core/Collapse'
import CloseIcon from '@material-ui/icons/Close'
import CloudUploadIcon from '@material-ui/icons/CloudUploadRounded'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import InfoIcon from '@material-ui/icons/Info'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  getMeals,
  getTagsForMeal,
  getOpenRequests,
  addAvailabilityToMeal,
  removeAvailabilityFromMeal,
  uploadImage,
  createNewMealRequest,
  createMealChangeRequest,
  cancelChangeRequest,
  setMealStatus,
  editMeal,
  getOptionsForMeal,
} from '../redux/api/actions'
import promiseMap from '../utils/promiseMap'
import arraysEqual from '../utils/arraysEqual'
import getUrlForImage from '../utils/getUrlForImage'
import makeIntegerNormalizer from '../utils/makeIntegerNormalizer'
import GrayDotIcon from '../components/icons/GrayDotIcon'

const FORM_NAME = 'MEAL'
const PHOTO_CODE_LENGTH = 7
const MAX_OPTION_COST = 300

/*
 *  Fields that need to go through a Potluck meal change request
 */
const RESTRICTED_FIELDS = [
  'name',
  'price',
  'dailyReservationLimit',
  'details',
  'ingredients',
  'pickupInstructions',
]

const REQUIRED_FIELDS = [
  'name',
  'price',
  'dailyReservationLimit',
  'details',
]

export const MEALS_AND_SIDES_SHARED_FIELDS = [
  'name',
  'dailyReservationLimit',
  'lunch',
  'dinner',
  'potluckNowLunch',
  'potluckNowDinner',
]

const DAILY_RESERVATION_LIMIT_MIN = 1

const normalizeDailyReservationLimit = makeIntegerNormalizer({ min: DAILY_RESERVATION_LIMIT_MIN })

const getCostOfValue = value => {
  let cost = value.cost || 0

  if (value.showCost !== undefined && !value.showCost) {
    cost = 0
  }

  return cost
}

const compareOptions = (a, b) => {
  if (a.length !== b.length) {
    return false
  }

  for (let i = 0; i < a.length; i++) {
    const optionA = a[i]
    const optionB = b[i]

    for (let field of ['id', 'name', 'required', 'allowMultiple']) {
      if (optionA[field] !== optionB[field]) {
        return false
      }
    }

    if (optionA.values.length !== optionB.values.length) {
      return false
    }

    for (let j = 0; j < optionA.values.length; j++) {
      const valueA = optionA.values[j]
      const valueB = optionB.values[j]

      for (let field of ['name']) {
        if (valueA[field] !== valueB[field]) {
          return false
        }
      }

      if (getCostOfValue(valueA) !== getCostOfValue(valueB)) {
        return false
      }
    }
  }

  return true
}

const sanitizeOptions = options => options.map(option => ({
  id: option.id,
  name: option.name,
  required: option.required,
  allowMultiple: option.allowMultiple,
  values: option.values.map(value => ({
    name: value.name,
    cost: value.showCost ? value.cost : 0,
  }))
}))

const isOptionsValid = options => {
  for (let option of options) {
    if (option.error || !option.name) {
      return false
    }

    for (let value of option.values) {
      if (value.error || !value.name) {
        return false
      }
    }
  }

  return true
}

const messages = defineMessages({
  missingField: {
    id: 'meal.missing-field',
    defaultMessage: 'This field is required.',
  },
  dailyReservationLimitMinimumNotMet: {
    id: 'meal.daily-reservation-limit-minimum-not-met',
    defaultMessage: 'The minimum reservation limit is 1.',
  },
  descriptionPlaceholder: {
    id: 'meal.description-placeholder',
    defaultMessage: '(Ex) This menu item is one of the most popular in our shop, please try it',
  },
  ingredientsPlaceholder: {
    id: 'meal.ingredients-placeholder',
    defaultMessage: '(Ex) Pasta, pepper, cheese',
  },
  optionsRequired: {
    id: 'meal.option-required',
    defaultMessage: 'Required',
  },
  optionsAllowMultiple: {
    id: 'meal.option-allow-multiple',
    defaultMessage: 'Allows multiple selections',
  },
  changeName: {
    id: 'meal.change-name',
    defaultMessage: 'Change the meal name from "{previousValue}" to "{newValue}"',
  },
  changePrice: {
    id: 'meal.change-price',
    defaultMessage: 'Change the price from {previousValue}円 to {newValue}円',
  },
  changeDailyReservationLimit: {
    id: 'meal.change-daily-reservation-limit',
    defaultMessage: 'Change the reservation limit from {previousValue} to {newValue}',
  },
  changeDetails: {
    id: 'meal.change-details',
    defaultMessage: 'Change the meal details from "{previousValue}" to "{newValue}"',
  },
  changeIngredients: {
    id: 'meal.change-ingredients',
    defaultMessage: 'Change the ingredients from "{previousValue}" to "{newValue}"',
  },
  changePickupInstructions: {
    id: 'meal.change-pickup-instructions',
    defaultMessage: 'Change the pickup instructions from "{previousValue}" to "{newValue}"',
  },
  changeMedia: {
    id: 'meal.change-media',
    defaultMessage: 'Change the meal images to:',
  },
  changeOptions: {
    id: 'meal.change-options',
    defaultMessage: 'Change the meal options to:',
  },
  optionPlaceholder: {
    id: 'meal.option-placeholder',
    defaultMessage: 'Ex) Amount of rice',
  },
  choicesPlaceholder: {
    id: 'meal.choices-placeholder',
    defaultMessage: 'Ex) Medium',
  },
})

const OPERATIONS_TO_MESSAGES = {
  'CHANGE_MEAL_NAME': messages.changeName,
  'CHANGE_MEAL_PRICE': messages.changePrice,
  'CHANGE_MEAL_DAILY_RESERVATION_LIMIT': messages.changeDailyReservationLimit,
  'CHANGE_MEAL_DETAILS': messages.changeDetails,
  'CHANGE_MEAL_INGREDIENTS': messages.changeIngredients,
  'CHANGE_MEAL_PICKUP_INSTRUCTIONS': messages.changePickupInstructions,
  'CHANGE_MEAL_IMAGES': messages.changeMedia,
  'CHANGE_MEAL_OPTIONS': messages.changeOptions,
}

// https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
const generatePhotoCode = () => {
  var text = ""
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

  for (var i = 0; i < PHOTO_CODE_LENGTH; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return text
}

const DeleteConfirmationDialog = withStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  content: {
    textAlign: 'center',
    padding: theme.spacing(4),
  },
  text: {
    fontSize: 18,
  },
  cta: {
    minWidth: 180,
    marginTop: theme.spacing(4),
  },
  buttonProgress: {
    position: 'absolute',
  },
}))(({ open, loading, onClickConfirmDelete, onClose, classes }) => (
  <Dialog
    open={open}
    fullWidth
    maxWidth="sm"
    onClose={onClose}>
    <DialogTitle className={classes.title}>
      <IconButton
        aria-label="Close"
        className={classes.closeButton}
        onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent className={classes.content}>
      <Typography
        variant="body1"
        className={classes.text}>
        <FormattedMessage
          id="meal.delete-warning"
          defaultMessage="Are you sure?"
          values={{
            br: (<br />),
          }} />
      </Typography>
      <div>
        <Button
          variant="contained"
          color="primary"
          size="large"
          disabled={loading}
          className={classes.cta}
          onClick={onClickConfirmDelete}>
          <FormattedMessage
            id="meal.delete-warning-cta"
            defaultMessage="Delete" />
          {loading && (
            <CircularProgress
              size={24}
              className={classes.buttonProgress} />
          )}
        </Button>
      </div>
    </DialogContent>
  </Dialog>
))

const validate = (values, props) => {
  const errors = {}

  for (let fieldName of REQUIRED_FIELDS) {
    if (values[fieldName] === undefined) {
      errors[fieldName] = props.intl.formatMessage(messages.missingField)
    }
  }

  if (values.dailyReservationLimit < DAILY_RESERVATION_LIMIT_MIN) {
    errors.dailyReservationLimit = props.intl.formatMessage(messages.dailyReservationLimitMinimumNotMet)
  }

  return errors
}

const fetchData = (dispatch, params, shop) => {
  const promises = {
    meals: dispatch(getMeals()),
  }

  if (params.mealId !== 'new') {
    promises.tagsForMeal = dispatch(getTagsForMeal(params.mealId))
    promises.openRequests = dispatch(getOpenRequests(shop.id))
    promises.optionsForMeal = dispatch(getOptionsForMeal(params.mealId))
  }

  return promiseMap(promises)
}

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  changeRequest: {
    backgroundColor: deepOrange[500],
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  changeRequestTop: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  changeRequestIcon: {
    color: '#ffffff',
    marginRight: theme.spacing(1),
  },
  changeRequestTitle: {
    flex: 1,
    color: '#ffffff',
    fontWeight: 'bold',
  },
  changeRequestText: {
    color: '#ffffff',
  },
  changeRequestImages: {
    display: 'flex',
  },
  changeRequestImage: {
    height: 50,
    width: 75,
    borderRadius: 2,
    margin: 5,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  content: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  formBody: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  leftSection: {
    width: 310,
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  rightSection: {
    flex: 1,
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginTop: theme.spacing(2),
    },
  },
  optionsSection: {
    backgroundColor: '#FCFBFC',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  optionsSectionHeader: {
    display: 'flex',
    flexDirection: 'row',
  },
  optionsSectionHeaderText: {
    flex: 1,
  },
  optionsSectionHeaderExpandIcon: {
    flex: 1,
  },
  optionsTitle: {
    fontSize: 16,
    color: '#292529',
  },
  optionsDisclaimer: {
    fontSize: 12,
    color: '#767676',
  },
  optionsDivider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  optionsSectionPart: {
    marginBottom: theme.spacing(2),
  },
  optionsSectionPartTitle: {
    fontWeight: 'bold',
    fontSize: 14,
    color: '#8A888A',
    marginBottom: theme.spacing(2),
  },
  optionsSectionFormFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  optionsSectionFormField: {
    backgroundColor: '#ffffff',
  },
  optionsSectionFormFieldSmall: {
    backgroundColor: '#ffffff',
    maxWidth: 100,
  },
  optionsSectionIcon: {
    backgroundColor: '#FCFBFC',
    boxShadow: 'none',
    marginLeft: theme.spacing(1),
  },
  optionsSectionCheckboxField: {
    display: 'flex',
    alignItems: 'center',
  },
  optionsSectionOptionFormField: {
    marginLeft: theme.spacing(3),
    backgroundColor: '#ffffff',
  },
  optionsSectionOptionCheckboxFieldContainer: {
    marginBottom: theme.spacing(2),
  },
  optionsSectionOptionCheckboxField: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    marginBottom: -4,
  },
  optionsSectionOptionCheckbox: {
    marginLeft: theme.spacing(2),
  },
  optionsSectionOptionCostContainer: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.23)',
    marginLeft: theme.spacing(5),
    paddingLeft: theme.spacing(2),
  },
  optionsSectionOptionCostTitle: {
    fontSize: 14,
    color: '#535353',
    marginBottom: theme.spacing(1),
  },
  optionsSectionOptionCostField: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  optionsSectionOptionCostCurrencyText: {
    fontSize: 14,
    color: '#535353',
    marginLeft: theme.spacing(1),
  },
  optionsSectionOptionCostSubtitle: {
    fontSize: 12,
    color: '#535353',
    marginBottom: theme.spacing(1),
  },
  optionsSectionOptionCostDisclaimer: {
    fontSize: 12,
    color: '#828282',
  },
  optionsSectionCta: {
    backgroundColor: '#ffffff',
  },
  carousel: {
    position: 'relative',
    minHeight: 200,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    cursor: 'pointer',
    '&:focus': {
      outline: 0,
    },
  },
  carouselDrag: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  previous: {
    position: 'absolute',
    left: -20,
    top: 'calc(50% - 20px)',
    zIndex: 1,
  },
  next: {
    position: 'absolute',
    right: -20,
    top: 'calc(50% - 20px)',
    zIndex: 1,
  },
  image: {
    position: 'relative',
    height: 200,
    background: '#E0E0E0',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  deleteImageCta: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  imageLoading: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageEmpty: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageEmptyIcon: {
    height: 40,
    width: 40,
  },
  imageCta: {
    display: 'flex',
    marginTop: theme.spacing(4),
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
  availabilitySection: {
    marginTop: theme.spacing(4),
    backgroundColor: 'rgba(255, 96, 105, 0.08)',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
  availabilitySectionPart: {
    padding: theme.spacing(2),
  },
  availabilityTitle: {
    fontSize: 16,
    color: '#292529',
    marginBottom: theme.spacing(1),
  },
  availabilityDisclaimer: {
    fontSize: 12,
    color: '#767676',
  },
  divider: {
  },
  checkboxField: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1, 0),
  },
  checkboxLabel: {
    marginLeft: theme.spacing(2),
  },
  addPhotoCta: {
    position: 'relative',
    flex: 1,
    color: '#A8B2B9',
    marginRight: theme.spacing(1),
  },
  addPhotoCtaInput: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    cursor: 'pointer',
  },
  addPhotoStatus: {
    flex: 1,
    fontSize: 14,
    color: '#292529',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      textAlign: 'center',
    },
  },
  topForm: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
  },
  radio: {
    minWidth: 100,
  },
  formField: {
    marginBottom: theme.spacing(4),
    backgroundColor: '#ffffff',
  },
  formFieldsHorizontal: {
    display: 'flex',
  },
  formFieldHorizontal: {
    flex: 1,
  },
  formFieldHorizontalMargin: {
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(2),
    },
  },
  ctaContainer: {
    display: 'flex',
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  cancelCta: {
    flex: 1,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 0),
    },
  },
  saveCta: {
    flex: 1,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 0),
    },
  },
  ctaProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  deleteCtaContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 0),
    },
  },
  deleteCta: {
    color: '#292529',
    alignSelf: 'center',
  },
})

class Meal extends Component {
  state = {
    deleteConfirmationDialogOpen: false,
    deleteConfirmationDialogLoading: false,
    loading: false,
    type: 'meal',
    imageIndex: 0,
    mediaLoading: false,
    optionsExpanded: true,
    media: [],
    options: [],
  }

  hasLoaded = () => {
    return this.props.meals.loaded
      && this.props.tagsForMeal && this.props.tagsForMeal.loaded
      && this.props.optionsForMeal && this.props.optionsForMeal.loaded
  }

  isDirty = () => {
    return !this.props.pristine
      || (this.props.meal && !arraysEqual(this.state.images, this.props.meal.images))
      || (this.props.optionsForMeal && this.props.optionsForMeal.data && !compareOptions(this.state.options, this.props.optionsForMeal.data))
  }

  isNewMeal = () => this.props.match.params.mealId === 'new'

  shouldDisableTypeField = () => {
    return this.props.mealId !== 'new'
      || this.state.options.length > 0
  }

  onClickChangeType = (event, value) => {
    this.setState({
      type: value,
    })

    if (value === 'side') {
      this.props.history.push('/side/new', {
        ...this.props.sharedFields,
        images: this.state.images,
      })
    }
  }

  onClickToggleOptionsExpanded = () => {
    this.setState({
      optionsExpanded: !this.state.optionsExpanded,
    })
  }

  onChangeOptionName = (option, event) => {
    this.setState({
      options: this.state.options.map(stateOption => {
        if (stateOption.id === option.id) {
          return {
            ...stateOption,
            name: event.target.value,
            error: event.target.value.length === 0 ? 'Required' : null,
          }
        }

        return stateOption
      })
    })
  }

  onChangeOptionRequired = (option, value) => {
    this.setState({
      options: this.state.options.map(stateOption => {
        if (stateOption.id === option.id) {
          return {
            ...stateOption,
            required: value,
          }
        }

        return stateOption
      })
    })
  }

  onChangeOptionAllowMultiple = (option, value) => {
    this.setState({
      options: this.state.options.map(stateOption => {
        if (stateOption.id === option.id) {
          return {
            ...stateOption,
            allowMultiple: value,
          }
        }

        return stateOption
      })
    })
  }

  onClickDeleteOption = option => {
    this.setState({
      options: this.state.options.filter(stateOption => stateOption.id !== option.id),
    })
  }

  onClickDeleteOptionValue = (option, index) => {
    this.setState({
      options: this.state.options.map(stateOption => {
        if (stateOption.id === option.id) {
          return {
            ...stateOption,
            values: stateOption.values.filter((stateValue, stateIndex) => stateIndex !== index),
          }
        }

        return stateOption
      })
    })
  }

  onChangeOptionValueName = (option, index, event) => {
    this.setState({
      options: this.state.options.map(stateOption => {
        if (stateOption.id === option.id) {
          return {
            ...stateOption,
            values: stateOption.values.map((stateValue, stateIndex) => {
              if (stateIndex === index) {
                return {
                  ...stateValue,
                  name: event.target.value,
                  error: event.target.value.length === 0 ? 'Required' : null,
                }
              }

              return stateValue
            })
          }
        }

        return stateOption
      })
    })
  }

  onChangeOptionValueRequired = (option, index) => {
    this.setState({
      options: this.state.options.map(stateOption => {
        if (stateOption.id === option.id) {
          return {
            ...stateOption,
            values: stateOption.values.map((stateValue, stateIndex) => {
              if (stateIndex === index) {
                return {
                  ...stateValue,
                  showCost: !stateValue.showCost,
                }
              }

              return stateValue
            })
          }
        }

        return stateOption
      })
    })
  }

  onChangeOptionValueCost = (option, index, event) => {
    this.setState({
      options: this.state.options.map(stateOption => {
        if (stateOption.id === option.id) {
          return {
            ...stateOption,
            values: stateOption.values.map((stateValue, stateIndex) => {
              if (stateIndex === index) {
                return {
                  ...stateValue,
                  cost: Math.max(0, Math.min(MAX_OPTION_COST, parseInt(event.target.value, 10))),
                }
              }

              return stateValue
            })
          }
        }

        return stateOption
      })
    })
  }

  onClickAddOption = () => {
    let id = 1

    for (let option of this.state.options) {
      if (option.id >= id) {
        id = option.id + 1
      }
    }

    this.setState({
      options: [...this.state.options, {
        id,
        values: [{
          name: '',
          cost: 0,
        }]
      }]
    })
  }

  onClickAddOptionValue = option => {
    this.setState({
      options: this.state.options.map(stateOption => {
        if (stateOption.id === option.id) {
          return {
            ...stateOption,
            values: [...stateOption.values, {
              name: '',
              cost: 0,
            }]
          }
        }

        return stateOption
      })
    })
  }

  onChangeImageIndex = imageIndex => {
    this.setState({
      imageIndex,
    })
  }

  onClickPreviousImage = event => {
    event.stopPropagation()

    if (this.state.imageIndex > 0) {
      this.setState({
        imageIndex: this.state.imageIndex - 1,
      })
    }
  }

  onClickNextImage = event => {
    event.stopPropagation()

    if (this.state.imageIndex < this.state.images.length - 1) {
      this.setState({
        imageIndex: this.state.imageIndex + 1,
      })
    }
  }

  onClickDeletePhoto = (event, image) => {
    event.stopPropagation()

    this.setState({
      images: this.state.images.filter(item => item !== image),
      imageIndex: Math.max(this.state.imageIndex - 1, 0),
    })
  }

  onDropFiles = async files => {
    this.setState({ mediaLoading: true })

    for (let file of files) {
      const code = generatePhotoCode()
      const filename = `/potluck-webapp/meal-photos/${code}-${file.name}`

      const upload = await this.props.uploadImage(file, filename)

      this.setState({
        images: [...this.state.images, ...upload.map(item => item.uuid)],
        imageIndex: this.state.images.length,
      })
    }

    this.setState({ mediaLoading: false })
  }

  onUploadFile = async event => {
    this.setState({ mediaLoading: true })
    const file = event.target.files[0]
    const code = generatePhotoCode()
    const filename = `/potluck-webapp/meal-photos/${code}-${file.name}`

    const upload = await this.props.uploadImage(file, filename)

    this.setState({
      images: [...this.state.images, ...upload.map(item => item.uuid)],
      imageIndex: this.state.images.length,
      mediaLoading: false,
    })
  }

  onClickCancel = () => {
    this.props.history.push('/menu')
  }

  onClickCancelChangeRequest = async changeRequest => {
    await this.props.cancelChangeRequest(changeRequest.id)
    await this.props.getOpenRequests(this.props.shop.id)

    this.setState({
      images: this.props.meal.images,
    })

    this.props.dispatch(reset(FORM_NAME))
  }

  onClickSave = async (values, ...rest)  => {
    this.setState({
      loading: true,
    })

    if (this.isNewMeal()) {
      await this.props.createNewMealRequest({
        ...values,
        images: this.state.images,
        options: this.state.options,
        shopId: this.props.shop.id,
      })

      await this.props.getOpenRequests(this.props.shop.id)
      this.props.history.push('/menu')
    } else {
      for (let mealType of ['lunch', 'dinner']) {
        if (values[mealType] && !this.props.initialValues[mealType]) {
          await this.props.addAvailabilityToMeal({
            mealId: this.props.meal.id,
            type: mealType,
          })
        }

        if (!values[mealType] && this.props.initialValues[mealType]) {
          await this.props.removeAvailabilityFromMeal({
            mealId: this.props.meal.id,
            type: mealType,
          })
        }
      }



      const restrictedFieldHasChanged = Object.keys(values)
        .filter(field => RESTRICTED_FIELDS.includes(field))
        .some(field => values[field] !== this.props.initialValues[field])

      if (restrictedFieldHasChanged
        || !arraysEqual(this.state.images, this.props.meal.images)
        || !compareOptions(this.state.options, this.props.optionsForMeal.data)) {
        values.images = this.state.images
        values.options = sanitizeOptions(this.state.options)

        await this.props.createMealChangeRequest({
          mealId: this.props.meal.id,
          values,
        })
      }

      const freeFieldsChanged = Object.keys(values)
        .filter(field => !RESTRICTED_FIELDS.includes(field))
        .filter(field => values[field] !== this.props.initialValues[field])

      if (freeFieldsChanged.length > 0) {
        const options = freeFieldsChanged.reduce((memo, key) => ({
          ...memo,
          [key]: values[key],
        }), {})

        await this.props.editMeal(this.props.meal.id, options)
      }
    }

    await fetchData(this.props.dispatch, this.props.match.params, this.props.shop)

    this.props.dispatch(reset(FORM_NAME))

    this.setState({
      loading: false,
    })
  }

  onClickDelete = () => {
    this.setState({
      deleteConfirmationDialogOpen: true,
    })
  }

  onCloseDeleteConfirmationDialog = () => {
    this.setState({
      deleteConfirmationDialogOpen: false,
    })
  }

  onClickConfirmDelete = async () => {
    this.setState({
      deleteConfirmationDialogLoading: true,
    })

    await this.props.setMealStatus({
      mealId: this.props.match.params.mealId,
      status: 'hidden_from_search',
    })

    await fetchData(this.props.dispatch, this.props.match.params, this.props.shop)

    this.setState({
      deleteConfirmationDialogLoading: false,
    })

    this.props.history.push('/menu')
  }

  async componentWillMount() {
    let images = []
    let options = []

    if (!this.hasLoaded()) {
      const results = await fetchData(this.props.dispatch, this.props.match.params, this.props.shop)

      if (!this.isNewMeal()) {
        const meal = results.meals.find(meal => meal.id === parseInt(this.props.match.params.mealId, 10))
        images = meal.images
        options = results.optionsForMeal
      }
    } else if (!this.isNewMeal()) {
      const meal = this.props.meals.data.find(meal => meal.id === parseInt(this.props.match.params.mealId, 10))
      images = meal.images
      options = this.props.optionsForMeal.data
    }

    for (let option of options) {
      for (let value of option.values) {
        if (value.cost > 0) {
          value.showCost = true
        }
      }
    }

    this.setState({
      images,
      options,
    })
  }

  render() {
    return (
      <div className={this.props.classes.container}>
        <DeleteConfirmationDialog
          open={this.state.deleteConfirmationDialogOpen}
          loading={this.state.deleteConfirmationDialogLoading}
          onClose={this.onCloseDeleteConfirmationDialog}
          onClickConfirmDelete={this.onClickConfirmDelete} />
        {this.props.openRequestForMeal && (
          <Paper className={this.props.classes.changeRequest}>
            <div className={this.props.classes.changeRequestTop}>
              <InfoIcon className={this.props.classes.changeRequestIcon} />
              <div className={this.props.classes.changeRequestTitle}>
              <FormattedMessage
                id="meal.in-review"
                defaultMessage="In review" />
              </div>
            </div>
            <div className={this.props.classes.changeRequestText}>
              <FormattedMessage
                id="meal.review-message"
                defaultMessage="The Potluck team is reviewing the following change request and will update this menu item as soon as possible:" />
            </div>
            <ul>
              {this.props.openRequestForMeal.operations.map(operation => (
                <li
                  key={operation.type}
                  className={this.props.classes.changeRequestText}>
                  {this.props.intl.formatMessage(OPERATIONS_TO_MESSAGES[operation.type], {
                    previousValue: operation.previousValue,
                    newValue: operation.newValue,
                  })}
                  {operation.type === 'CHANGE_MEAL_IMAGES' && (
                    <div className={this.props.classes.changeRequestImages}>
                      {operation.newValue.map(image => (
                        <div
                          key={image}
                          className={this.props.classes.changeRequestImage}
                          style={{
                            backgroundImage: `url(${getUrlForImage(image)})`,
                          }} />
                      ))}
                    </div>
                  )}
                  {operation.type === 'CHANGE_MEAL_OPTIONS' && (
                    <ul className="meal__change-request-options">
                      {operation.newValue.map(option => (
                        <li key={option.id}>
                          {option.name}
                          {option.required && ` (${this.props.intl.formatMessage(messages.optionsRequired)})`}
                          {option.allowMultiple && ` (${this.props.intl.formatMessage(messages.optionsAllowMultiple)})`}
                          <ul className="meal__change-request-option">
                            {option.values.map((value, index) => (
                              <li key={index}>
                                {value.name} ({value.cost > 0 ? `￥${value.cost}` : 'free' })
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
            <Button
              variant="contained"
              className={this.props.classes.button}
              onClick={() => this.onClickCancelChangeRequest(this.props.openRequestForMeal)}>
              <FormattedMessage
                id="meal.cancel"
                defaultMessage="Cancel" />
            </Button>
          </Paper>
        )}
        <Paper className={this.props.classes.content}>
          <form>
            <div className={this.props.classes.formBody}>
              <div className={this.props.classes.leftSection}>
                <Dropzone onDrop={this.onDropFiles}>
                  {({ getRootProps, getInputProps, isDragActive }) => (
                    <div
                      {...getRootProps()}
                      className={classNames(this.props.classes.carousel, {
                        [this.props.classes.carouselDrag]: isDragActive,
                      })}>
                      <input {...getInputProps()} />
                      {this.state.images && this.state.images.length === 0 && (
                        <div className={this.props.classes.imageEmpty}>
                          <CloudUploadIcon
                            color={isDragActive ? 'primary' : 'disabled'}
                            className={this.props.classes.imageEmptyIcon} />
                        </div>
                      )}
                      {this.state.images && this.state.images.length > 1 && (
                        <Fab
                          size="small"
                          color="primary"
                          disabled={this.state.imageIndex === 0}
                          aria-label="Previous"
                          className={this.props.classes.previous}
                          onClick={this.onClickPreviousImage}>
                          <NavigateBeforeIcon />
                        </Fab>
                      )}
                      <SwipeableViews
                        enableMouseEvents
                        index={this.state.imageIndex}
                        onChangeIndex={this.onChangeImageIndex}>
                        {this.state.images && this.state.images.map((item, index) => (
                          <div
                            key={item}
                            className={this.props.classes.image}
                            style={{
                              backgroundImage: `url(${getUrlForImage(item)})`,
                            }}>
                            {(this.state.images.length > 1) && !this.props.openRequestForMeal && (
                              <Fab
                                color="secondary"
                                size="small"
                                className={this.props.classes.deleteImageCta}
                                onClick={event => this.onClickDeletePhoto(event, item)}>
                                <DeleteIcon />
                              </Fab>
                            )}
                          </div>
                        ))}
                      </SwipeableViews>
                      {this.state.images && this.state.images.length > 1 && (
                        <Fab
                          size="small"
                          color="primary"
                          disabled={this.state.imageIndex === this.state.images.length - 1}
                          aria-label="Next"
                          className={this.props.classes.next}
                          onClick={this.onClickNextImage}>
                          <NavigateNextIcon />
                        </Fab>
                      )}
                      {this.state.mediaLoading && (
                        <div className={this.props.classes.imageLoading}>
                          <CircularProgress />
                        </div>
                      )}
                    </div>
                  )}
                </Dropzone>
                <div className={this.props.classes.imageCta}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    className={this.props.classes.addPhotoCta}>
                    <FormattedMessage
                      id="meal.add-photos"
                      defaultMessage="Add photos" />
                    <input
                      type='file'
                      disabled={this.props.openRequestForMeal}
                      className={this.props.classes.addPhotoCtaInput}
                      onChange={this.onUploadFile} />
                  </Button>
                  <Typography
                    variant="body1"
                    color="secondary"
                    className={this.props.classes.addPhotoStatus}>
                    <FormattedMessage
                      id="meal.no-photo-selected"
                      defaultMessage="Nothing selected" />
                  </Typography>
                </div>
                <div className={this.props.classes.availabilitySection}>
                  <div className={this.props.classes.availabilitySectionPart}>
                    <Typography
                      variant="body1"
                      color="secondary"
                      className={this.props.classes.availabilityTitle}>
                      <FormattedMessage
                        id="meal.availability-title"
                        defaultMessage="Meal availability" />
                    </Typography>
                    <Typography
                      variant="caption"
                      color="secondary"
                      className={this.props.classes.availabilityDisclaimer}>
                      <FormattedMessage
                        id="meal.availability-disclaimer"
                        defaultMessage="Meals will not be available on closed days (according to the hours page), regardless of this setting." />
                    </Typography>
                  </div>
                  <Divider className={this.props.classes.divider} />
                  <div className={this.props.classes.availabilitySectionPart}>
                    <div className={this.props.classes.checkboxField}>
                      <Field
                        name="lunch"
                        disabled={!!this.props.openRequestForMeal}
                        component={Checkbox}
                        color="primary" />
                      <Typography
                        variant="body1"
                        className={this.props.classes.checkboxLabel}>
                        <FormattedMessage
                          id="meal.lunch"
                          defaultMessage="Lunch" />
                      </Typography>
                    </div>
                    <div className={this.props.classes.checkboxField}>
                      <Field
                        name="dinner"
                        disabled={!!this.props.openRequestForMeal}
                        component={Checkbox}
                        color="primary" />
                      <Typography
                        variant="body1"
                        className={this.props.classes.checkboxLabel}>
                        <FormattedMessage
                          id="meal.dinner"
                          defaultMessage="Dinner" />
                      </Typography>
                    </div>
                  </div>
                  <Divider className={this.props.classes.divider} />
                  <div className={this.props.classes.availabilitySectionPart}>
                    <div className={this.props.classes.checkboxField}>
                      <Field
                        name="potluckNowLunch"
                        disabled={!!this.props.openRequestForMeal}
                        component={Checkbox}
                        color="primary" />
                      <Typography
                        variant="body1"
                        className={this.props.classes.checkboxLabel}>
                        <FormattedMessage
                          id="meal.potluck-now-lunch"
                          defaultMessage="Potluck NOW (lunch)" />
                      </Typography>
                    </div>
                    <div className={this.props.classes.checkboxField}>
                      <Field
                        name="potluckNowDinner"
                        disabled={!!this.props.openRequestForMeal}
                        component={Checkbox}
                        color="primary" />
                      <Typography
                        variant="body1"
                        className={this.props.classes.checkboxLabel}>
                        <FormattedMessage
                          id="meal.potluck-now-dinner"
                          defaultMessage="Potluck NOW (dinner)" />
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
              <div className={this.props.classes.rightSection}>
                <div className={this.props.classes.topForm}>
                  {this.props.mealId === 'new' && (
                    <RadioGroup
                      name="type"
                      value={this.state.type}
                      className={this.props.classes.radioGroup}
                      onChange={this.onClickChangeType}>
                      <FormControl className={this.props.classes.radio}>
                        <FormControlLabel
                          label={(
                            <FormattedMessage
                              id="meal.meal"
                              defaultMessage="Meal" />
                          )}
                          control={(
                            <Radio
                              value="meal"
                              color="primary"
                              disabled={this.shouldDisableTypeField()} />
                          )} />
                      </FormControl>
                      <FormControl className={this.props.classes.radio}>
                        <FormControlLabel
                          label={(
                            <FormattedMessage
                              id="meal.side"
                              defaultMessage="Side" />
                          )}
                          control={(
                            <Radio
                              value="side"
                              color="primary"
                              disabled={this.shouldDisableTypeField()} />
                          )} />
                      </FormControl>
                    </RadioGroup>
                  )}
                  <Field
                    name="name"
                    variant="outlined"
                    label={(
                      <FormattedMessage
                        id="meal.name"
                        defaultMessage="Name" />
                    )}
                    disabled={!!this.props.openRequestForMeal}
                    className={this.props.classes.formField}
                    component={TextField} />
                    <div className={this.props.classes.formFieldsHorizontal}>
                      <Field
                        name="price"
                        variant="outlined"
                        label={(
                          <FormattedMessage
                            id="meal.price"
                            defaultMessage="Price" />
                        )}
                        disabled={!this.props.shop.canControlPrice}
                        className={classNames(this.props.classes.formField, this.props.classes.formFieldHorizontal)}
                        component={TextField} />
                      <Field
                        name="dailyReservationLimit"
                        type="number"
                        variant="outlined"
                        label={(
                          <FormattedMessage
                            id="meal.daily-reservation-limit"
                            defaultMessage="Daily order limit" />
                        )}
                        disabled={!!this.props.openRequestForMeal}
                        className={classNames(this.props.classes.formField, this.props.classes.formFieldHorizontal, this.props.classes.formFieldHorizontalMargin)}
                        normalize={normalizeDailyReservationLimit}
                        component={TextField} />
                    </div>
                    <Field
                      name="details"
                      variant="outlined"
                      multiline
                      rows={4}
                      label={(
                        <FormattedMessage
                          id="meal.description"
                          defaultMessage="Description" />
                      )}
                      placeholder={this.props.intl.formatMessage(messages.descriptionPlaceholder)}
                      disabled={!!this.props.openRequestForMeal}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className={this.props.classes.formField}
                      component={TextField} />
                      <div className={this.props.classes.optionsSection}>
                        <div className={this.props.classes.optionsSectionHeader}>
                          <div className={this.props.classes.optionsSectionHeaderText}>
                            <Typography
                              variant="body1"
                              color="secondary"
                              className={this.props.classes.optionsTitle}>
                              <FormattedMessage
                                id="meal.options-title"
                                defaultMessage="Meal Options" />
                            </Typography>
                            <Typography
                              variant="body1"
                              color="secondary"
                              className={this.props.classes.optionsDisclaimer}>
                              <FormattedMessage
                                id="meal.options-subtitle"
                                defaultMessage="You can set options such as size, spiciness, etc." />
                            </Typography>
                          </div>
                          <Fab
                            size="small"
                            className={this.props.classes.optionsSectionIcon}
                            onClick={() => this.onClickToggleOptionsExpanded()}>
                            <ExpandMoreIcon />
                          </Fab>
                        </div>
                        <Collapse in={this.state.optionsExpanded}>
                          <Divider className={this.props.classes.optionsDivider} />
                          {this.state.options.map((option, optionIndex) => [(
                            <div
                              key={option.id}
                              className={this.props.classes.optionsSectionPart}>
                              <div className={this.props.classes.optionsSectionFormFieldContainer}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  label={(
                                    <FormattedMessage
                                      id="meal.option-name"
                                      defaultMessage="Name" />
                                  )}
                                  placeholder={this.props.intl.formatMessage(messages.optionPlaceholder)}
                                  value={option.name}
                                  disabled={this.props.openRequestForMeal}
                                  className={this.props.classes.optionsSectionFormField}
                                  onChange={event => this.onChangeOptionName(option, event)} />
                                <Grow in={!this.props.openRequestForMeal}>
                                  <Fab
                                    size="small"
                                    className={this.props.classes.optionsSectionIcon}
                                    onClick={() => this.onClickDeleteOption(option)}>
                                    <DeleteIcon />
                                  </Fab>
                                </Grow>
                              </div>
                              <div className={this.props.classes.optionsSectionOptionCheckboxFieldContainer}>
                                <div className={this.props.classes.optionsSectionOptionCheckboxField}>
                                  <Checkbox
                                    color="primary"
                                    disabled={this.props.openRequestForMeal}
                                    className={this.props.classes.optionsSectionCheckboxField}
                                    input={{
                                      value: option.required,
                                      onChange: event => this.onChangeOptionRequired(option, event),
                                    }} />
                                  <Typography variant="body1">
                                    {this.props.intl.formatMessage(messages.optionsRequired)}
                                  </Typography>
                                </div>
                                <div className={this.props.classes.optionsSectionOptionCheckboxField}>
                                  <Checkbox
                                    color="primary"
                                    disabled={this.props.openRequestForMeal}
                                    className={this.props.classes.optionsSectionCheckboxField}
                                    input={{
                                      value: option.allowMultiple,
                                      onChange: event => this.onChangeOptionAllowMultiple(option, event),
                                    }} />
                                  <Typography variant="body1">
                                    {this.props.intl.formatMessage(messages.optionsAllowMultiple)}
                                  </Typography>
                                </div>
                              </div>
                              <Typography
                                variant="body1"
                                color="secondary"
                                className={this.props.classes.optionsSectionPartTitle}>
                                <FormattedMessage
                                  id="meal.option-values"
                                  defaultMessage="Choices" />
                              </Typography>
                              {option.values.map((value, index) => (
                                <div key={index}>
                                  <div className={this.props.classes.optionsSectionFormFieldContainer}>
                                    <GrayDotIcon />
                                    <TextField
                                      variant="outlined"
                                      placeholder={this.props.intl.formatMessage(messages.choicesPlaceholder)}
                                      value={value.name}
                                      error={value.error}
                                      disabled={this.props.openRequestForMeal}
                                      className={this.props.classes.optionsSectionOptionFormField}
                                      onChange={event => this.onChangeOptionValueName(option, index, event)} />
                                    <Grow in={!this.props.openRequestForMeal && option.values.length > 1}>
                                      <Fab
                                        size="small"
                                        className={this.props.classes.optionsSectionIcon}
                                        onClick={() => this.onClickDeleteOptionValue(option, index)}>
                                        <DeleteIcon />
                                      </Fab>
                                    </Grow>
                                  </div>
                                  <div className={this.props.classes.optionsSectionOptionCheckboxFieldContainer}>
                                    <div className={this.props.classes.optionsSectionOptionCheckboxField}>
                                      <Checkbox
                                        color="primary"
                                        disabled={this.props.openRequestForMeal}
                                        className={this.props.classes.optionsSectionOptionCheckbox}
                                        input={{
                                          value: value.showCost,
                                          onChange: event => this.onChangeOptionValueRequired(option, index),
                                        }} />
                                      <Typography variant="body1">
                                        <FormattedMessage
                                          id="meal.option-costs-extra"
                                          defaultMessage="Costs extra" />
                                      </Typography>
                                    </div>
                                    <Collapse in={value.showCost}>
                                      <div className={this.props.classes.optionsSectionOptionCostContainer}>
                                        <Typography
                                          variant="body1"
                                          className={this.props.classes.optionsSectionOptionCostTitle}>
                                          <FormattedMessage
                                            id="meal.option-amount"
                                            defaultMessage="Amount" />
                                        </Typography>
                                        <div className={this.props.classes.optionsSectionOptionCostField}>
                                          <TextField
                                            variant="outlined"
                                            type="number"
                                            value={value.cost}
                                            disabled={this.props.openRequestForMeal}
                                            className={this.props.classes.optionsSectionFormFieldSmall}
                                            onChange={event => this.onChangeOptionValueCost(option, index, event)} />
                                          <Typography
                                            variant="body1"
                                            className={this.props.classes.optionsSectionOptionCostCurrencyText}>
                                            円
                                          </Typography>
                                        </div>
                                        <Typography
                                          variant="body1"
                                          className={this.props.classes.optionsSectionOptionCostSubtitle}>
                                          <FormattedMessage
                                            id="meal.option-max-cost"
                                            defaultMessage="Limit {amount} yen"
                                            values={{
                                              amount: MAX_OPTION_COST,
                                            }} />
                                        </Typography>
                                        <Typography
                                          variant="body1"
                                          className={this.props.classes.optionsSectionOptionCostDisclaimer}>
                                          <FormattedMessage
                                            id="meal.option-amount-disclaimer"
                                            defaultMessage="20% of the amount will be deducted as a commission starting in December 2020. Until then there is no commission."
                                            values={{
                                              break: (<br />)
                                            }} />
                                        </Typography>
                                      </div>
                                    </Collapse>
                                  </div>
                                </div>
                              ))}
                              <Button
                                variant="outlined"
                                color="secondary"
                                size="small"
                                disabled={this.props.openRequestForMeal}
                                className={this.props.classes.optionsSectionCta}
                                onClick={() => this.onClickAddOptionValue(option)}>
                                <FormattedMessage
                                  id="meal.options-add-choice"
                                  defaultMessage="+ Add choice" />
                              </Button>
                            </div>
                          ), (
                            <Divider
                              key={`divider-${optionIndex}`}
                              className={this.props.classes.optionsDivider} />
                          )])}
                          <Button
                            variant="outlined"
                            color="secondary"
                            size="small"
                            disabled={this.props.openRequestForMeal}
                            className={this.props.classes.optionsSectionCta}
                            onClick={this.onClickAddOption}>
                            <FormattedMessage
                              id="meal.options-add"
                              defaultMessage="+ Add option" />
                          </Button>
                        </Collapse>
                      </div>
                      <Field
                        name="ingredients"
                        variant="outlined"
                        label={(
                          <FormattedMessage
                            id="meal.ingredients"
                            defaultMessage="Ingredients" />
                        )}
                        placeholder={this.props.intl.formatMessage(messages.ingredientsPlaceholder)}
                        disabled={!!this.props.openRequestForMeal}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={this.props.classes.formField}
                        component={TextField} />
                      <Field
                        name="pickupInstructions"
                        variant="outlined"
                        multiline
                        rows={4}
                        label={(
                          <FormattedMessage
                            id="meal.pickup-instructions"
                            defaultMessage="Pickup instructions" />
                        )}
                        disabled={!!this.props.openRequestForMeal}
                        component={TextField} />
                </div>
              </div>
            </div>
            <div className={this.props.classes.ctaContainer}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                disabled={this.state.loading}
                className={this.props.classes.cancelCta}
                onClick={this.onClickCancel}>
                <FormattedMessage
                  id="meal.cancel"
                  defaultMessage="Cancel" />
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={(this.props.pristine && !this.isDirty()) || this.props.invalid || this.state.loading || !!this.props.openRequestForMeal || !isOptionsValid(this.state.options)}
                className={this.props.classes.saveCta}
                onClick={this.props.handleSubmit(this.onClickSave)}>
                {this.state.loading && (
                  <CircularProgress
                    size={24}
                    color="primary"
                    className={this.props.classes.ctaProgress} />
                )}
                <FormattedMessage
                  id="meal.save"
                  defaultMessage="Save" />
              </Button>
            </div>
            {!this.isNewMeal() && (
              <div className={this.props.classes.deleteCtaContainer}>
                <Button
                  variant="text"
                  color="secondary"
                  size="large"
                  disabled={this.state.loading || !!this.props.openRequestForMeal}
                  className={this.props.classes.deleteCta}
                  onClick={this.onClickDelete}>
                  <FormattedMessage
                    id="meal.delete-meal"
                    defaultMessage="Delete" />
                </Button>
              </div>
            )}
          </form>
        </Paper>
      </div>
    )
  }
}


const selector = formValueSelector(FORM_NAME)

const mapStateToProps = (state, ownProps) => {
  let mealId = ownProps.match.params.mealId
  let initialValues = {
    price: 500,
    dailyReservationLimit: 10,
    pickupInstructions: '1時間以上遅れた場合、キャンセルとみなし基本的に破棄させていただきます。1時間以上遅れる場合は、必ず事前にお店にお問い合わせください。また、閉店時間を過ぎると受け取れないことがあります。',
  }
  let openRequestForMeal
  let meal
  let tagsForMeal
  let optionsForMeal

  if (ownProps.history.location.state) {
    initialValues = {
      ...initialValues,
      ...ownProps.history.location.state
    }
  }

  if (mealId !== 'new') {
    mealId = parseInt(ownProps.match.params.mealId, 10)

    if (state.api.meals.default.loaded) {
      meal = state.api.meals.default.data.find(meal => meal.id === mealId)

      if (meal) {
        initialValues = {
          name: meal.name,
          price: meal.price,
          dailyReservationLimit: meal.dailyReservationLimit,
          details: meal.details,
          ingredients: meal.ingredients,
          pickupInstructions: meal.pickupInstructions,
          lunch: meal.availability.lunch.length > 0,
          dinner: meal.availability.dinner.length > 0,
          potluckNowLunch: meal.potluckNowLunch,
          potluckNowDinner: meal.potluckNowDinner,
        }
      }
    }

    if (state.api.openRequests.default.loaded) {
      openRequestForMeal = state.api.openRequests.default.data.find(openRequest => openRequest.operations.some(operation => operation.mealId === mealId))
    }

    tagsForMeal = state.api.tagsForMeal[mealId]
    optionsForMeal = state.api.optionsForMeal[mealId]
  }

  return {
    mealId,
    initialValues,
    openRequestForMeal,
    shop: state.features.shop,
    meal,
    meals: state.api.meals.default,
    tagsForMeal,
    optionsForMeal,
    sharedFields: MEALS_AND_SIDES_SHARED_FIELDS.reduce((memo, field) => ({
      ...memo,
      [field]: selector(state, field),
    }), {}),
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    getMeals,
    getTagsForMeal,
    getOpenRequests,
    addAvailabilityToMeal,
    removeAvailabilityFromMeal,
    uploadImage,
    createNewMealRequest,
    createMealChangeRequest,
    cancelChangeRequest,
    setMealStatus,
    editMeal,
  }, dispatch),
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(reduxForm({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
})((withStyles(styles)(Meal)))))
