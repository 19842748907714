export default {
  'app.page-title': 'Potluck Orders ({date})',
  'app.shops': 'Potluck Shops',

  'nav.messages': 'Messages',
  'nav.overview': 'Overview',
  'nav.menu': 'Menu',
  'nav.hours': 'Hours',
  'nav.sales': 'Sales',
  'nav.settings': 'Settings',
  'nav.logout': 'Logout',

  'login.page-title': 'Potluck Shops',
  'login.shops': 'Shops',
  'login.email': 'Email',
  'login.password': 'Password',
  'login.cta': 'Login',
  'login.account-not-found': 'Email or password is incorrect.',
  'login.no-shops-for-account': 'Your account does not have a shop.',
  'login.missing-field': 'This field is required',
  'login.email-invalid': 'This email is invalid',

  'forgot-password.shops': 'Shops',
  'forgot-password.email': 'Email',
  'forgot-password.cta': 'Send reset link',
  'forgot-password.check-email': 'Please check hour email for a password reset link.',
  'forgot-password.missing-field': 'This field is required',
  'forgot-password.email-invalid': 'This email is invalid',

  'messages.time-now': 'Now',
  'messages.time-seconds': '{seconds} {seconds, plural, one {second} other {seconds}',
  'messages.time-minutes': '{minutes} {minutes, plural, one {minute} other {minutes}',
  'messages.time-hours': '{hours} {hours, plural, one {hour} other {hours}',
  'messages.time-days': '{days} {days, plural, one {day} other {days}',
  'messages.title': 'Messages',
  'messages.subtitle': 'Notifications from Potluck',
  'messages.pagination': '{page} of {totalPages}',
  'messages.cta': 'Details',

  'dashboard.title': '{date} ({dayName})',
  'dashboard.delivery': 'Delivery',
  'dashboard.hours-closed': 'Closed',
  'dashboard.hours-lunch': 'Lunch ({lunchHours})',
  'dashboard.hours-dinner': 'Dinner ({dinnerHours})',
  'dashboard.hours-lunch-dinner': 'Lunch・Dinner ({lunchHours} | {dinnerHours})',
  'dashboard.lunch': 'Lunch',
  'dashboard.dinner': 'Dinner',
  'dashboard.finalized-status': 'Status: Finalized',
  'dashboard.not-finalized-status': 'Status: Not finalized (reservations may change)',
  'dashboard.reservations-count': 'Reservations: {count}',
  'dashboard.no-reservations': 'No reservations',
  'dashboard.reservations-acknowledged': 'Acknowledged! Thank you for your time today',
  'dashboard.mark-as-seen': 'Mark as seen',
  'dashboard.report-a-problem': 'Report a problem',
  'dashboard.print': 'Print',
  'dashboard.order-index': '{index, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} visit',
  'dashboard.active': 'Active',
  'dashboard.deleted': 'Deleted (not paid)',
  'dashboard.canceled': 'Canceled',
  'dashboard.sides': 'Sides',

  'dashboard-print.header': 'Potluck Orders',
  'dashboard-print.id': '#',
  'dashboard-print.time': 'Time',
  'dashboard-print.item': 'Item',
  'dashboard-print.user': 'User',
  'dashboard-print.status': 'Status',
  'dashboard-print.completed': 'Completed',
  'dashboard-print.user-cancelation-note': 'User says:',

  'reservation.title': '{date} ({dayName}) {pickupTime} Reservation : {userName}',
  'reservation.meal': 'Meal',
  'reservation.options-title': 'Options',
  'reservation.sides-title': 'Sides',
  'reservation.user': 'User',
  'reservation.reservation-count-title': 'Number of reservations',
  'reservation.reservation-count-value': '{count}',
  'reservation.order-history': 'Past reservations',
  'reservation.order-index': '{index, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} visit',
  'reservation.none': 'None',
  'reservation.delivery': 'Delivery',
  'reservation.delivery-title': 'This is a delivery order',
  'reservation.delivery-caption': 'This order will be pickup up by a Potluck delivery person and taken to the customer',

  'hours.edit-hours-for-day': 'Edit hours',
  'hours.set-closed': 'Set closed',
  'hours.today': 'today',
  'hours.tomorrow': 'tomorrow',
  'hours.reservations-warning': 'You have {count} {count, plural, one {reservation} other {reservations}} {dayName}. You can still change your hours, but you will need to serve those existing reservations',
  'hours.accept': 'Accept',
  'hours.dismiss': 'Dismiss',
  'hours.calendar-updated': 'Calendar is updated',
  'hours.closed': 'Closed',
  'hours.edit-hours': 'Edit hours',

  'edit-hours-dialog.missing-field': 'required',
  'edit-hours-dialog.time-range-invalid': 'invalid',
  'edit-hours-dialog.title': 'Edit hours',
  'edit-hours-dialog.weekdays-title': 'Weekdays',
  'edit-hours-dialog.hours': 'Hours',
  'edit-hours-dialog.lunch': 'Lunch',
  'edit-hours-dialog.dinner': 'Dinner',
  'edit-hours-dialog.weekends-title': 'Weekends',
  'edit-hours-dialog.save': 'Save',

  'edit-hours-for-date-dialog.missing-field': 'required',
  'edit-hours-for-date-dialog.time-range-invalid': 'invalid',
  'edit-hours-for-date.hours': 'Hours',
  'edit-hours-for-date.lunch': 'Lunch',
  'edit-hours-for-date.dinner': 'Dinner',
  'edit-hours-for-date.save': 'Save',

  'edit-holidays-dialog.open': 'Open',
  'edit-holidays-dialog.closed': 'Closed',
  'edit-holidays-dialog.title': 'Holiday schedule',
  'edit-holidays-dialog.instructions': 'Please input your holiday schedule',
  'edit-holidays-dialog.month': '{monthName} holidays',
  'edit-holidays-dialog.business-hours': 'Business hours',
  'edit-holidays-dialog.lunch': 'Lunch',
  'edit-holidays-dialog.dinner': 'Dinner',

  'menu.search-placeholder': 'Search meals',
  'menu.meals-tab': 'Meals',
  'menu.sides-tab': 'Sides',
  'menu.new-meal-cta': 'New meal',
  'menu.available': 'Available',
  'menu.sold-out': 'Sold out',
  'menu.edit': 'Edit',
  'menu.meal-under-review': 'This meal is under review',
  'menu.restore': 'Restore',
  'menu.list-empty': 'List empty',

  'meal.missing-field': 'This field is required.',
  'meal.delete-warning': 'Are you sure?',
  'meal.delete-warning-cta': 'Delete',
  'meal.in-review': 'In review',
  'meal.review-message': 'The Potluck team is reviewing the following change request and will update this menu item as soon as possible:',
  'meal.meal': 'Meal',
  'meal.side': 'Side',
  'meal.add-photos': 'Add photos',
  'meal.no-photo-selected': 'Nothing selected',
  'meal.availability-title': 'Meal availability',
  'meal.availability-disclaimer': 'Meals will not be available on closed days (according to the hours page), regardless of this setting.',
  'meal.lunch': 'Lunch',
  'meal.dinner': 'Dinner',
  'meal.potluck-now-lunch': 'Potluck NOW (lunch)',
  'meal.potluck-now-dinner': 'Potluck NOW (dinner)',
  'meal.name': 'Name',
  'meal.price': 'Price',
  'meal.daily-reservation-limit': 'Daily order limit',
  'meal.description': 'Description',
  'meal.description-placeholder': '(Ex) This menu item is one of the most popular in our shop, please try it',
  'meal.options-title': 'Meal Options',
  'meal.options-subtitle': 'You can set options such as size, spiciness, etc.',
  'meal.option-name': 'Name',
  'meal.option-placeholder': 'Ex) Amount of rice',
  'meal.option-values': 'Choices',
  'meal.choices-placeholder': 'Ex) Medium',
  'meal.option-costs-extra': 'Costs extra',
  'meal.option-amount': 'Amount',
  'meal.option-max-cost': 'Limit {amount} yen',
  'meal.option-amount-disclaimer': '20% of the amount will be deducted as a commission starting in December 2020. Until then there is no commission.',

  'meal.options-add-choice': '+ Add choice',
  'meal.options-add': '+ Add option',

  'meal.ingredients': 'Ingredients',
  'meal.ingredients-placeholder': '(Ex) Pasta, pepper, cheese',
  'meal.option-required': 'Required',
  'meal.option-allow-multiple': 'Allows multiple selections',
  'meal.pickup-instructions': 'Pickup instructions',
  'meal.cancel': 'Cancel',
  'meal.save': 'Save',
  'meal.delete-meal': 'Delete',
  'meal.change-name': 'Change the meal name from "{previousValue}" to "{newValue}"',
  'meal.change-price': 'Change the price from {previousValue}円 to {newValue}円',
  'meal.change-daily-reservation-limit': 'Change the reservation limit from {previousValue} to {newValue}',
  'meal.change-details': 'Change the meal details from "{previousValue}" to "{newValue}"',
  'meal.change-ingredients': 'Change the ingredients from "{previousValue}" to "{newValue}"',
  'meal.change-pickup-instructions': 'Change the pickup instructions from "{previousValue}" to "{newValue}"',
  'meal.change-category': 'Change the meal category from "{previousValue}" to "{newValue}"',
  'meal.change-media': 'Change the meal images to:',
  'meal-request.in-review': 'In review',
  'meal-request.review-message': 'The Potluck team is reviewing this new meal will update this menu item as soon as possible.',
  'meal-request.cancel': 'Cancel',

  'side.delete-warning': 'Are you sure?',
  'side.delete-warning-cta': 'Delete',
  'side.in-review': 'In review',
  'side.review-message': 'The Potluck team is reviewing the following change request and will update this menu item as soon as possible:',
  'side.disclaimer': 'Maximum price {amount}.{break}20% of the amount will be deducted as a commission starting in December 2020. Until then there is no commission.',
  'side.cancel': 'Cancel',
  'side.add-photos': 'Add photos',
  'side.no-photo-selected': 'Nothing selected',
  'side.availability-title': 'Meal availability',
  'side.availability-disclaimer': 'Meals will not be available on closed days (according to the hours page), regardless of this setting.',
  'side.lunch': 'Lunch',
  'side.dinner': 'Dinner',
  'side.potluck-now-lunch': 'Potluck NOW (lunch)',
  'side.potluck-now-dinner': 'Potluck NOW (dinner)',
  'side.meal': 'Meal',
  'side.side': 'Side',
  'side.name': 'Name',
  'side.price': 'Price',
  'side.daily-reservation-limit': 'Daily order limit',
  'side.save': 'Save',
  'side.delete-meal': 'Delete',
  'sides.drink': 'Drink',
  'sides.soup': 'Soup',
  'sides.salad': 'Salad',
  'sides.side': 'Side',
  'sides.dessert': 'Dessert',
  'sides.other': 'Other',

  'sales.title': 'Sales',
  'sales.daily': 'Daily',
  'sales.weekly': 'Weekly',
  'sales.monthly': 'Monthly',
  'sales.select-shops': 'Select shops',
  'sales.select-all-shops': 'Select all',
  'sales.deselect-all-shops': 'Deselect all',
  'sales.revenue': 'Revenue',
  'sales.orders': 'Orders',
  'sales.subscribers': 'Subscribers',
  'sales.no-sales': 'No sales',
  'sales.item': 'Item',
  'sales.shop': 'Shop',
  'sales.user': 'User',
  'sales.pickup': 'Pickup',
  'sales.side-option': 'Side/Option',
  'sales.reviews': 'Reviews',
  'sales.reviews-count': 'Reviews: {count}',
  'sales.reviews-item': 'Item',
  'sales.no-reviews': 'No reviews',

  'reviews-list.back': 'Back',
  'reviews-list.title': 'Reviews',
  'reviews-list.reviews': 'Reviews',
  'reviews-list.reservation-count': 'Orders: {count}',

  'settings.missing-field': 'This field is required',
  'settings.cta': 'Save',
  'settings.language': 'Language',
  'settings.details': 'Details',
  'settings.zip': 'Postal',
  'settings.address': 'Address',
  'settings.contact-name': 'Contact Name',
  'settings.contact-number': 'Contact Phone',
  'settings.show-shop-phone': 'Display shop phone number publicly',
  'settings.notification-emails': 'Notification emails',
  'settings.notification-email-add': 'Add notification email',
  'settings.login-email': 'Login Email',
  'settings.password': 'Password',
  'settings.change-password': 'Change',
  'settings.preferences': 'Preferences',
  'settings.email_on_every_day': 'Recieve a daily email of your orders',
  'settings.email_on_every_order': 'Recieve an email every time an order is placed',
  'settings.connect-line-title': 'Connect Line',
  'settings.connect-line-text': 'Connect with Line',
  'settings.connected': 'Connected',

  'settings.custom': 'Custom',
  'settings.new-years': 'New Years Day',
  'settings.coming-of-age': 'Coming of Age Day',
  'settings.national-foundation': 'National Foundation Day',
  'settings.spring-equinox': 'Spring Equinox Day',
  'settings.golden-week': 'Golden Week',
  'settings.showa-day': 'Showa Day',
  'settings.national-holiday': 'National Holiday',
  'settings.constitution-day': 'Constitution Day',
  'emporers-throne-day': 'Emporer\'s Throne Day',
  'settings.green-day': 'Green Day',
  'settings.childrens-day': 'Children\'s Day',
  'settings.transfer-holiday': 'Transfer Holiday',
  'settings.marine': 'Marine Day',
  'settings.mountain': 'Mountain Day',
  'settings.mountain-extra': 'Mountain Day (extra)',
  'settings.seniors': 'Seniors Day',
  'settings.autumn-equinox': 'Autumn Equinox Day',
  'settings.sports': 'Sports Day',
  'settings.culture': 'Culture Day',
  'settings.culture-extra': 'Culture Day (extra)',
  'settings.labor-thanksgiving': 'Labor Thanksgiving Day',

  'edit-password-dialog.title': 'Reset password',
  'edit-password-dialog.new-password': 'New password',
  'edit-password-dialog.confirm-new-password': 'Confirm new password',
  'edit-password-dialog.cta': 'Save',
  'edit-password-dialog.missing-field': 'This field is required',
  'edit-password-dialog.password-do-not-match': 'Passwords do not match',

  'connect-line-dialog.title': 'Connect Line',
  'connect-line-dialog.subtitle': 'Please scan this QR code in your Line app and then enter the confirmation number that is sent to you.',
  'connect-line-dialog.success-title': 'Success!',
  'connect-line-dialog.success-subtitle': 'Your Line account is now connected.',
  'connect-line-dialog.success-cta': 'Close',
}
