import React from 'react'

const SvgComponent = props => (
  <svg width={20} height={20} fill="none" {...props}>
    <path
      d="M15 12.223H5s-5 0-5 3.888c0 1.754 2.222 3.89 2.222 3.89h15.556S20 17.864 20 16.11c0-3.888-5-3.888-5-3.888z"
      fill="#4289C1"
    />
    <path
      d="M10 17.222a1.667 1.667 0 100-3.333 1.667 1.667 0 000 3.333z"
      fill="#F9CA55"
    />
    <path d="M5 5.52v6.146a5 5 0 1010 0V5.694L5 5.52z" fill="#FFDC5D" />
    <path
      d="M6.026 9.842c0 .878-.444 1.59-.993 1.59-.549 0-.993-.712-.993-1.59 0-.877.445-1.59.993-1.59s.993.713.993 1.59zm9.935 0c0 .878-.445 1.59-.994 1.59-.549 0-.993-.712-.993-1.59 0-.877.444-1.59.993-1.59.55 0 .994.713.994 1.59z"
      fill="#FFDC5D"
    />
    <path
      d="M10 2.68c-3.9 0-5.633 2.602-5.633 4.77 0 2.17.746 2.77.867 2.17.433-2.17 1.733-2.835 1.733-2.835 2.6 2.17 2.167.434 2.167.434 2.6 2.602 1.3 0 1.3 0 .867.868 3.466.868 3.466.868s.433.665.866 1.533c.433.868.867 0 .867-2.169 0-2.17-2.167-4.771-5.633-4.771z"
      fill="#FFAC33"
    />
    <path
      d="M9.444 18.889l-2.777-.556-1.112-.556H3.89L3.333 20h6.111c.556 0 .556-.556.556-.556 0-.307-.556-.555-.556-.555zm5-1.112l-1.11.556-2.778.556s-.556.248-.556.555c0 0 0 .556.556.556h6.11l-.555-2.223h-1.667z"
      fill="#FFDC5D"
    />
    <path
      d="M12.222 12.222a.555.555 0 01-.555-.555v-.556a.555.555 0 111.11 0v.556a.555.555 0 01-.555.555zm-4.444 0a.555.555 0 01-.556-.555v-.556a.555.555 0 111.111 0v.556a.555.555 0 01-.555.555z"
      fill="#662113"
    />
    <path
      d="M10.556 14.723H9.445a.278.278 0 010-.556h1.11a.278.278 0 010 .556z"
      fill="#C1694F"
    />
    <path
      d="M3.055 4.445a.552.552 0 01-.333-.111L.5 2.667a.556.556 0 01.667-.889l2.222 1.667a.556.556 0 01-.334 1zm1.668-1.667a.555.555 0 01-.463-.247L3.149.864a.556.556 0 01.924-.616l1.111 1.666a.556.556 0 01-.461.864zm12.222 1.667a.555.555 0 01-.334-1l2.222-1.667a.555.555 0 11.666.889l-2.222 1.667a.55.55 0 01-.332.11zm-1.668-1.667a.555.555 0 01-.462-.864L15.928.248a.555.555 0 11.924.616L15.74 2.531a.554.554 0 01-.463.247z"
      fill="#5DADEC"
    />
  </svg>
)

export default SvgComponent
