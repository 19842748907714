import axios from 'axios'
import canUseDOM from '../../utils/canUseDOM'
import apiAction, { getReceiveType } from '../tools/apiAction'

/* for server-side render */
export const BASE_URL = canUseDOM() ? '/_api' : process.env.API_BASE_URL

export const ACTIONS = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  NEIGHBORHOODS: 'NEIGHBORHOODS',
  MESSAGES: 'MESSAGES',
  MARK_MESSAGE_AS_READ: 'MARK_MESSAGE_AS_READ',
  PROFILE: 'PROFILE',
  MY_SHOP: 'MY_SHOP',
  MY_SHOPS: 'MY_SHOPS',
  CHANGE_SHOPS: 'CHANGE_SHOPS',
  EDIT_MY_SHOP: 'EDIT_MY_SHOP',
  GET_NOTIFICATION_EMAILS: 'GET_NOTIFICATION_EMAILS',
  SET_NOTIFICATION_EMAILS: 'SET_NOTIFICATION_EMAILS',
  UPLOAD_IMAGE: 'UPLOAD_IMAGE',
  ADD_MEDIA_TO_MEAL: 'ADD_MEDIA_TO_MEAL',
  REMOVE_MEDIA_FROM_MEAL: 'REMOVE_MEDIA_FROM_MEAL',
  ADD_AVAILABILITY_TO_MEAL: 'ADD_AVAILABILITY_TO_MEAL',
  REMOVE_AVAILABILITY_FROM_MEAL: 'REMOVE_AVAILABILITY_FROM_MEAL',
  RESET_PASSWORD: 'RESET_PASSWORD',
  MY_SHOP_PREFERENCES: 'MY_SHOP_PREFERENCES',
  MY_SHOP_HOURS: 'MY_SHOP_HOURS',
  EDIT_SHOP_HOURS: 'EDIT_SHOP_HOURS',
  MY_SHOP_HOURS_HISTORY: 'MY_SHOP_HOURS_HISTORY',
  MY_SHOP_HOLIDAYS: 'MY_SHOP_HOLIDAYS',
  EDIT_SHOP_HOLIDAYS: 'EDIT_SHOP_HOLIDAYS',
  EDIT_MY_SHOP_PREFERENCES: 'EDIT_MY_SHOP_PREFERENCES',
  LINE_ACCOUNTS: 'LINE_ACCOUNTS',
  USE_LINE_CONFIRMATION_CODE: 'USE_LINE_CONFIRMATION_CODE',
  DELETE_LINE_ACCOUNT: 'DELETE_LINE_ACCOUNT',
  ORDER_CONFIRMATIONS: 'ORDER_CONFIRMATIONS',
  CREATE_ORDER_CONFIRMATIONS: 'CREATE_ORDER_CONFIRMATIONS',
  CREATE_RESERVATION_PROBLEM: 'CREATE_RESERVATION_PROBLEM',
  MEALS: 'MEALS',
  EDIT_MEAL: 'EDIT_MEAL',
  CREATE_MEAL: 'CREATE_MEAL',
  OPTIONS_FOR_MEAL: 'OPTIONS_FOR_MEAL',
  SET_MEAL_STATUS: 'SET_MEAL_STATUS',
  TAGS_FOR_MEAL: 'TAGS_FOR_MEAL',
  SIDES: 'SIDES',
  EDIT_SIDE: 'EDIT_SIDE',
  SET_SIDE_STATUS: 'SET_SIDE_STATUS',
  ADD_AVAILABILITY_TO_SIDE: 'ADD_AVAILABILITY_TO_SIDE',
  REMOVE_AVAILABILITY_FROM_SIDE: 'REMOVE_AVAILABILITY_FROM_SIDE',
  RESERVATIONS: 'RESERVATIONS',
  RESERVATION_DETAILS: 'RESERVATION_DETAILS',
  RESERVATIONS_TODAY: 'RESERVATIONS_TODAY',
  RESERVATIONS_TOMORROW: 'RESERVATIONS_TOMORROW',
  RESERVATIONS_FOR_DATE: 'RESERVATIONS_FOR_DATE',
  EDIT_RESERVATION_STATUSES: 'EDIT_RESERVATION_STATUSES',
  MISSED_RESERVATION_REPORTS: 'MISSED_RESERVATION_REPORTS',
  CREATE_MISSED_RESERVATION_REPORT: 'CREATE_MISSED_RESERVATION_REPORT',
  PAYMENT_SCHEDULE: 'PAYMENT_SCHEDULE',
  SHOP_PAYMENTS: 'SHOP_PAYMENTS',
  LOGIN_LINK: 'LOGIN_LINK',
  REQUEST_PASSWORD_RESET_LINK: 'REQUEST_PASSWORD_RESET_LINK',
  OPEN_REQUESTS: 'OPEN_REQUESTS',
  CREATE_MEAL_CHANGE_REQUEST: 'CREATE_MEAL_CHANGE_REQUEST',
  CREATE_NEW_MEAL_REQUEST: 'CREATE_NEW_MEAL_REQUEST',
  CREATE_SIDE_CHANGE_REQUEST: 'CREATE_SIDE_CHANGE_REQUEST',
  CREATE_NEW_SIDE_REQUEST: 'CREATE_NEW_SIDE_REQUEST',
  CANCEL_CHANGE_REQUEST: 'CANCEL_CHANGE_REQUEST',
  MARK_CANCELATION_NOTES_SEEN: 'MARK_CANCELATION_NOTES_SEEN',
  REVIEWS: 'REVIEWS',
  HOLIDAYS: 'HOLIDAYS',
  MARK_DIALOG_AS_SEEN: 'MARK_DIALOG_AS_SEEN',
}

export const mergeDataFromSocketIO = ({ action, key, data }) => ({
  type: getReceiveType(action),
  key,
  data,
})

export const getPaymentScheduleKey = ({ period, shopSelection, shopIds }) => `${period}-${shopSelection}-${shopIds.join(',')}`
export const getShopPaymentsKey = ({ shopIds, startsAt, endsAt }) => `${(shopIds || []).join(',')}-${startsAt}-${endsAt}`
export const getReviewsKey = ({ shopIds, startsAt, endsAt }) => `${(shopIds || []).join(',')}-${startsAt}-${endsAt}`

// Login

export const login = ({ email, password }) => apiAction(ACTIONS.LOGIN, () =>
  axios.post(`${BASE_URL}/shops/login`, { email, password }, { withCredentials: true }))

export const logout = () => apiAction(ACTIONS.LOGOUT, () =>
  axios.post(`${BASE_URL}/shops/logout`, {}, { withCredentials: true }))

export const requestPasswordResetLink = email => apiAction(ACTIONS.REQUEST_PASSWORD_RESET_LINK, () =>
  axios.post(`${BASE_URL}/shops/request-password-reset-link`, { email }, { withCredentials: true }))

export const useLoginLink = code => apiAction(ACTIONS.LOGIN_LINK, () =>
  axios.post(`${BASE_URL}/shops/use-login-link`, { code }, { withCredentials: true }))

export const getNeighborhoods = () => apiAction(ACTIONS.NEIGHBORHOODS, () =>
  axios.get(`${BASE_URL}/shops/neighborhoods`, { withCredentials: true }))

// Password

export const resetPassword = password => apiAction(ACTIONS.RESET_PASSWORD, () =>
  axios.post(`${BASE_URL}/shops/password`, { password }, { withCredentials: true }))

// Messages

export const getMessages = (shopId, page) => apiAction(ACTIONS.MESSAGES, () =>
  axios.get(`${BASE_URL}/shops/${shopId}/messages/${page}`, { withCredentials: true }))

export const markMessageAsRead = (shopId, messageId) => apiAction(ACTIONS.MARK_MESSAGE_AS_READ, () =>
  axios.post(`${BASE_URL}/shops/${shopId}/messages/${messageId}/mark-as-read`, { withCredentials: true }))

// Profile

export const getProfile = () => apiAction(ACTIONS.PROFILE, () =>
  axios.get(`${BASE_URL}/shops/profile`, { withCredentials: true }))

// Shops
export const getMyShops = () => apiAction(ACTIONS.MY_SHOPS, () =>
  axios.get(`${BASE_URL}/shops/my-shops`, { withCredentials: true }))

export const editShop = (shopId, values) => apiAction(ACTIONS.EDIT_MY_SHOP, () =>
  axios.post(`${BASE_URL}/shops/${shopId}`, values, { withCredentials: true }))

export const getNotificationEmails = shopId => apiAction(ACTIONS.GET_NOTIFICATION_EMAILS, () =>
  axios.get(`${BASE_URL}/shops/${shopId}/notification-emails`, { withCredentials: true }))

export const setNotificationEmails = (shopId, emails) => apiAction(ACTIONS.SET_NOTIFICATION_EMAILS, () =>
  axios.post(`${BASE_URL}/shops/${shopId}/notification-emails`, { emails }, { withCredentials: true }))

export const getMyShopPreferences = shopId => apiAction(ACTIONS.MY_SHOP_PREFERENCES, () =>
  axios.get(`${BASE_URL}/shops/${shopId}/preferences`, { shopId }, { withCredentials: true }))

export const editMyShopPreferences = (shopId, preferences) => apiAction(ACTIONS.EDIT_MY_SHOP_PREFERENCES, () =>
  axios.post(`${BASE_URL}/shops/${shopId}/preferences`, { preferences }, { withCredentials: true }))

// Hours

export const getShopHours = shopId => apiAction(ACTIONS.MY_SHOP_HOURS, () =>
  axios.get(`${BASE_URL}/shops/${shopId}/hours`, { withCredentials: true }))

export const editShopHours = ({ shopId, hours, startsAt, endsAt, important=false }) => apiAction(ACTIONS.EDIT_SHOP_HOURS, () =>
  axios.post(`${BASE_URL}/shops/${shopId}/hours`, { hours, startsAt, endsAt, important }, { withCredentials: true }))

export const getShopHoursIfNeeded = shopId => apiAction(ACTIONS.MY_SHOP_HOURS, () =>
  axios.get(`${BASE_URL}/shops/${shopId}/hours`, { withCredentials: true }), { useCache: true })

export const getShopHoursHistory = shopId => apiAction(ACTIONS.MY_SHOP_HOURS_HISTORY, () =>
  axios.get(`${BASE_URL}/shops/${shopId}/hours-history`, { withCredentials: true }))

// Line

export const getLineAccounts = shopId => apiAction(ACTIONS.LINE_ACCOUNTS, () =>
  axios.get(`${BASE_URL}/shops/${shopId}/line-accounts`, { withCredentials: true }))

export const useLineConfirmationCode = (shopId, code) => apiAction(ACTIONS.USE_LINE_CONFIRMATION_CODE, () =>
  axios.post(`${BASE_URL}/shops/${shopId}/line-confirmation`, { code }, { withCredentials: true }))

export const deleteLineAccount = (shopId, lineUserId) => apiAction(ACTIONS.DELETE_LINE_ACCOUNT, () =>
  axios.delete(`${BASE_URL}/shops/${shopId}/line-accounts`, { data: { lineUserId } }, { withCredentials: true }))

export const getOrderConfirmations = shopId => apiAction(ACTIONS.ORDER_CONFIRMATIONS, () =>
  axios.get(`${BASE_URL}/shops/${shopId}/order-confirmations`, { withCredentials: true }))

export const createOrderConfirmation = (shopId, { date, type }) => apiAction(ACTIONS.CREATE_ORDER_CONFIRMATIONS, () =>
  axios.post(`${BASE_URL}/shops/${shopId}/order-confirmations`, { date, type }, { withCredentials: true }))

// Meals

export const getMeals = () => apiAction(ACTIONS.MEALS, () =>
  axios.get(`${BASE_URL}/shops/meals`, { withCredentials: true }))

export const editMeal = (mealId, meal) => apiAction(ACTIONS.EDIT_MEAL, () =>
  axios.post(`${BASE_URL}/shops/meals/${mealId}`, meal, { withCredentials: true }), { key: mealId })

export const getOptionsForMeal = mealId => apiAction(ACTIONS.OPTIONS_FOR_MEAL, () =>
  axios.get(`${BASE_URL}/shops/meals/${mealId}/options`, { withCredentials: true }), { key: mealId })

export const setMealStatus =({ mealId, status }) => apiAction(ACTIONS.SET_MEAL_STATUS, () =>
  axios.post(`${BASE_URL}/shops/meals/${mealId}/status`, { status }, { withCredentials: true }), { key: mealId })

export const getTagsForMeal = mealId => apiAction(ACTIONS.TAGS_FOR_MEAL, () =>
  axios.get(`${BASE_URL}/shops/meals/${mealId}/tags`, { withCredentials: true }), { key: mealId })

export const addMediaToMeal = ({ mealId, path }) => apiAction(ACTIONS.ADD_MEDIA_TO_MEAL, () =>
  axios.post(`${BASE_URL}/shops/meals/${mealId}/media`, { path }, { withCredentials: true }))

export const removeMediaFromMeal = ({ mealId, path }) => apiAction(ACTIONS.REMOVE_MEDIA_FROM_MEAL, () =>
  axios.post(`${BASE_URL}/shops/meals/${mealId}/delete-media`, { path }, { withCredentials: true }))

export const addAvailabilityToMeal = ({ mealId, type }) => apiAction(ACTIONS.ADD_AVAILABILITY_TO_MEAL, () =>
  axios.post(`${BASE_URL}/shops/meals/${mealId}/availability`, { type }, { withCredentials: true }))

export const removeAvailabilityFromMeal = ({ mealId, type }) => apiAction(ACTIONS.REMOVE_AVAILABILITY_FROM_MEAL, () =>
  axios.post(`${BASE_URL}/shops/meals/${mealId}/remove-availability`, { type }, { withCredentials: true }))

// Sides

export const getSides = () => apiAction(ACTIONS.SIDES, () =>
  axios.get(`${BASE_URL}/shops/sides`, { withCredentials: true }))

export const editSide = (sideId, side) => apiAction(ACTIONS.EDIT_SIDE, () =>
  axios.post(`${BASE_URL}/shops/sides/${sideId}`, side, { withCredentials: true }), { key: sideId })

export const setSideStatus =({ sideId, status }) => apiAction(ACTIONS.SET_SIDE_STATUS, () =>
  axios.post(`${BASE_URL}/shops/sides/${sideId}/status`, { status }, { withCredentials: true }), { key: sideId })

export const addAvailabilityToSide = ({ sideId, type }) => apiAction(ACTIONS.ADD_AVAILABILITY_TO_SIDE, () =>
  axios.post(`${BASE_URL}/shops/sides/${sideId}/availability`, { type }, { withCredentials: true }))

export const removeAvailabilityFromSide = ({ sideId, type }) => apiAction(ACTIONS.REMOVE_AVAILABILITY_FROM_SIDE, () =>
  axios.post(`${BASE_URL}/shops/sides/${sideId}/remove-availability`, { type }, { withCredentials: true }))

// Reservations

export const createReservationProblem = (shopId, problem) => apiAction(ACTIONS.CREATE_RESERVATION_PROBLEM, () =>
  axios.post(`${BASE_URL}/shops/${shopId}/reservation-problems`, { problem }, { withCredentials: true }))

export const getReservationsToday = shopId => apiAction(ACTIONS.RESERVATIONS_TODAY, () =>
  axios.get(`${BASE_URL}/shops/${shopId}/reservations/today`, { withCredentials: true }))

export const getReservationsTomorrow = shopId => apiAction(ACTIONS.RESERVATIONS_TOMORROW, () =>
  axios.get(`${BASE_URL}/shops/${shopId}/reservations/tomorrow`, { withCredentials: true }))

export const getReservationsForDate = (shopId, reservationDate) => apiAction(ACTIONS.RESERVATIONS_FOR_DATE, () =>
  axios.get(`${BASE_URL}/shops/${shopId}/reservations/${reservationDate}`, { withCredentials: true }), { key: reservationDate })

export const getReservationDetails = reservationId => apiAction(ACTIONS.RESERVATION_DETAILS, () =>
  axios.get(`${BASE_URL}/shops/reservation/${reservationId}`, { withCredentials: true }), { key: reservationId })

export const editReservationStatuses = (shopId, statuses) => apiAction(ACTIONS.EDIT_RESERVATION_STATUSES, () =>
  axios.post(`${BASE_URL}/shops/${shopId}/reservations/statuses`, { statuses }, { withCredentials: true }))

// Payment

export const getShopPayments = ({ companyId, shopIds, startsAt, endsAt }) => apiAction(ACTIONS.SHOP_PAYMENTS, () =>
  axios.post(`${BASE_URL}/shops/shop-payments`, { companyId, shopIds, startsAt, endsAt }, { withCredentials: true }), { key: getShopPaymentsKey({ shopIds, startsAt, endsAt }), useCache: true })

// Requests

export const getOpenRequests = shopId => apiAction(ACTIONS.OPEN_REQUESTS, () =>
  axios.get(`${BASE_URL}/shops/${shopId}/requests/open`, { withCredentials: true }))

export const createNewMealRequest = values  => apiAction(ACTIONS.CREATE_NEW_MEAL_REQUEST, () =>
  axios.post(`${BASE_URL}/shops/${values.shopId}/requests/meals/new`, values, { withCredentials: true }))

export const createMealChangeRequest = ({ mealId, values }) => apiAction(ACTIONS.CREATE_MEAL_CHANGE_REQUEST, () =>
  axios.post(`${BASE_URL}/shops/requests/meals/${mealId}`, values, { withCredentials: true }))

export const createNewSideRequest = values => apiAction(ACTIONS.CREATE_NEW_SIDE_REQUEST, () =>
  axios.post(`${BASE_URL}/shops/requests/sides/new`, values, { withCredentials: true }))

export const createSideChangeRequest = ({ sideId, values }) => apiAction(ACTIONS.CREATE_SIDE_CHANGE_REQUEST, () =>
  axios.post(`${BASE_URL}/shops/requests/sides/${sideId}`, values, { withCredentials: true }))

export const cancelChangeRequest = changeRequestId => apiAction(ACTIONS.CANCEL_CHANGE_REQUEST, () =>
  axios.delete(`${BASE_URL}/shops/requests/${changeRequestId}`, { withCredentials: true }))

// Reviews

export const getReviews = ({ companyId, shopIds, startsAt, endsAt }) => apiAction(ACTIONS.REVIEWS, () =>
  axios.post(`${BASE_URL}/shops/reviews`, { companyId, shopIds, startsAt, endsAt }, { withCredentials: true }), { key: getReviewsKey({ shopIds, startsAt, endsAt }), useCache: true })

// Holidays

export const getHolidays = () => apiAction(ACTIONS.HOLIDAYS, () =>
  axios.get(`${BASE_URL}/shops/holidays`, { withCredentials: true }))

export const getMyShopHolidays = shopId => apiAction(ACTIONS.MY_SHOP_HOLIDAYS, () =>
  axios.get(`${BASE_URL}/shops/${shopId}/holidays`, { shopId }, { withCredentials: true }))

export const editMyShopHolidays = (shopId, holidays) => apiAction(ACTIONS.EDIT_SHOP_HOLIDAYS, () =>
  axios.post(`${BASE_URL}/shops/${shopId}/holidays`, { shopId, holidays }, { withCredentials: true }))

// Media

export const uploadImage = (file, filename) => apiAction(ACTIONS.UPLOAD_IMAGE, () => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('filename', filename)

  return axios.post(`${BASE_URL}/shops/media`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    withCredentials: true,
  })
})
