import React, { Component } from 'react'
import {
  StaticRouter,
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  withRouter,
} from 'react-router-dom'
import io from 'socket.io-client'
import ls from 'local-storage'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  injectIntl,
  IntlProvider,
  defineMessages,
  addLocaleData,
} from 'react-intl'
import queryString from 'query-string'
import jaLocaleData from 'react-intl/locale-data/ja'
import esLocaleData from 'react-intl/locale-data/es'
import moment from 'moment-timezone'
import 'moment/locale/ja'
import { Helmet } from 'react-helmet'
import {
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import locales from './i18n/locales'
import routes from './routes'
import {
  mergeDataFromSocketIO,
  getProfile,
  getMyShop,
  getMyShops,
  getMessages,
  logout,
} from './redux/api/actions'
import {
  setShop,
  setLocale,
  setFlag,
  setRedirectTo,
  setMessagesBadge,
} from './redux/features/actions'
import promiseMap from './utils/promiseMap'
import Login from './containers/Login'
import ForgotPassword from './containers/ForgotPassword'
import LoginLink from './containers/LoginLink'
import Nav from './components/Nav/Nav'
import Logo from './components/Logo'

const messageTypes = {
  API_ACTION: 'api-action',
  NOTIFICATION: 'notification',
  DIALOG: 'dialog',
}

addLocaleData(jaLocaleData)
addLocaleData(esLocaleData)

const messages = defineMessages({
  pageTitle: {
    id: 'app.page-title',
    defaultMessage: 'Potluck Orders ({date})',
  },
})

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: ['Hiragino Sans'],
  },
  palette: {
    primary: {
      contrastText: '#fff',
      dark: '#FF6069',
      light: '#FF6069',
      main: '#FF6069',
    },
    secondary: {
      contrastText: '#fff',
      dark: '#8A888A',
      light: '#8A888A',
      main: '#8A888A',
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        boxShadow: '0px 8.72651px 17.453px rgba(243, 0, 0, 0.096892)',
      },
      containedSecondary: {
        boxShadow: 'none',
      },
      outlinedPrimary: {
        border: '2px solid #FF6069',
        backgroundColor: '#ffffff',
        borderRadius: 4,
        '&:hover': {
          border: '2px solid #FF6069',
          backgroundColor: '#ffffff',
          borderRadius: 4,
        },
      },
    },
  },
})

class LoggedInRouteUnwrapped extends Component {
  hasLoaded = () => this.props.profile.loaded

  shouldComponentUpdate(nextProps) {
    return nextProps.profile.receivedAt !== this.props.profile.receivedAt
      || nextProps.component !== this.props.component
  }

  componentWillMount() {
    const search = queryString.parse(this.props.location.search)
    this.props.setRedirectTo(search.redirectTo)
  }

  render() {
    if (!this.hasLoaded()) {
      return false
    }

    const {
      component: Component,
      ...rest
    } = this.props

    if (!this.props.profile.data) {
      this.props.setRedirectTo(this.props.path)
      return (
        <Redirect to="/login" />
      )
    }

    return (
      <Route {...rest} render={(props) => (<Component {...props} />)} />
    )
  }
}

const routeMapStateToProps = state => ({
  profile: state.api.profile.default
})

const routeMapDispatchToProps = {
  setRedirectTo,
}

const LoggedInRoute = connect(routeMapStateToProps, routeMapDispatchToProps)(withRouter(LoggedInRouteUnwrapped))

const drawerWidth = 240

class SocketIOListenerUnwrapped extends Component {
  socket = null
  state = {
    dialog: null
  }

  openSocketConnection = () => {
    try {
      this.socket = io({
        path: '/_api/ws',
      })

      this.socket.on('data', data => {
        if (data.type === messageTypes.API_ACTION) {
          this.props.mergeDataFromSocketIO(data)
        } else if (data.type === messageTypes.DIALOG) {
          this.setState({
            dialog: data.dialog,
          })
        }
      })

      this.socket.on('error', error => {
        console.log('error')
        console.log(error)
      })

      this.socket.on('connect_error', () => {
        setTimeout(() => {
          this.socket.connect()
        }, 1000)
      })
    } catch (error) {
      console.log('error')
      console.log(error)
    }
  }

  closeSocketConnection = () => {
    this.socket.disconnect()
    delete this.socket
  }

  onCloseDialog = () => {
    this.setState({
      dialog: null,
    })
  }

  onClickDialogCta = cta => {
    if (cta.action && cta.action.type === 'navigation') {
      this.props.history.push(cta.action.destination)
    }

    this.onCloseDialog()
  }

  componentDidMount() {
    if (this.props.profile.data && !this.socket) {
      this.openSocketConnection()
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.profile.data && this.props.profile.data && !this.socket) {
      this.openSocketConnection()
    }

    if (prevProps.profile.data && !this.props.profile.data) {
      this.closeSocketConnection()
    }
  }

  render() {
    return (
      <div>
        <Dialog
          open={!!this.state.dialog}
          onClose={this.onCloseDialog}>
          <DialogTitle>{this.state.dialog && this.state.dialog.title}</DialogTitle>
          {this.state.dialog && this.state.dialog.subtitle && (
            <DialogContent>
              <DialogContentText>
                {this.state.dialog.subtitle}
              </DialogContentText>
            </DialogContent>
          )}
          <DialogActions>
            {this.state.dialog && this.state.dialog.ctas.map(cta => (
              <Button
                onClick={() => this.onClickDialogCta(cta)}
                color={cta.color || 'primary'}>
                {cta.text}
              </Button>
            ))}
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const SocketIOListener = withRouter(SocketIOListenerUnwrapped)

const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBarRoot: {
    position: 'relative',
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    borderBottom: '1px solid #D8D8D8',
    backgroundColor: '#ffffff',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    '@media print': {
      display: 'none',
    },
  },
  toolbarRoot: {
    justifyContent: 'space-between',
  },
  menuButton: {
    // marginRight: 20,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  logo: {
    height: 19,
    width: 61,
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 240,
    },
  },
})

class App extends Component {
  state = {
    mobileDrawerOpen: false,
  }

  onDrawerToggle = () => {
    this.setState({
      mobileDrawerOpen: !this.state.mobileDrawerOpen,
    })
  }

  onDrawerOpen = () => {
    this.setState({
      mobileDrawerOpen: true,
    })
  }

  onDrawerClose = () => {
    this.setState({
      mobileDrawerOpen: false,
    })
  }

  onChangeShops = shopId => {
    const newShop = this.props.myShops.data.find(shop => shop.id === shopId)
    this.props.setShop(newShop)

    ls('shopId', newShop.id)
  }

  onClickHeaderIcon = () => {
    this.props.setFlag('profileDropdownOpen', !this.props.profileDropdownOpen)
  }

  onClickNavLink = () => {
    this.props.setFlag('profileDropdownOpen', false)
  }

  onClickLogout = async () => {
    ls.clear()

    await this.props.logout()
    window.location = '/login'
  }

  render() {
    const content = (
      <div className="app">
        <MuiThemeProvider theme={theme}>
          <Helmet>
            <title>
              {this.props.intl.formatMessage(messages.pageTitle, {
                date: this.props.dashboardDate.format('MMMM Do'),
              })}
            </title>
          </Helmet>
          <Switch>
            <Route
              path="/login"
              exact
              component={Login} />
            <Route
              path="/forgot-password"
              exact
              component={ForgotPassword} />
            <Route
              path="/ll/:code"
              exact
              component={LoginLink} />
            <Route path="*">
              <div>
                <CssBaseline />
                <SocketIOListener
                  profile={this.props.profile}
                  mergeDataFromSocketIO={this.props.mergeDataFromSocketIO} />
                <AppBar
                  position="fixed"
                  classes={{
                    root: this.props.classes.appBarRoot,
                  }}>
                  <Toolbar classes={{
                    root: this.props.classes.toolbarRoot,
                  }}>
                    <Logo className={this.props.classes.logo} />
                    <IconButton
                      color="secondary"
                      aria-label="Open drawer"
                      onClick={this.onDrawerToggle}
                      className={this.props.classes.menuButton}>
                      <MenuIcon />
                    </IconButton>
                  </Toolbar>
                </AppBar>
                <div className={this.props.classes.content}>
                  <Nav
                    shop={this.props.shop}
                    myShops={this.props.myShops}
                    location={this.props.location}
                    messagesBadge={this.props.messagesBadge}
                    mobileDrawerOpen={this.state.mobileDrawerOpen}
                    onDrawerToggle={this.onDrawerToggle}
                    onChangeShops={this.onChangeShops}
                    onClickLogout={this.onClickLogout} />
                  <Switch>
                    <Route
                      path="/"
                      exact>
                      <div>
                        {this.props.profile && this.props.profile.data && this.props.profile.data.id && (
                          <Redirect to="/dashboard" />
                        )}
                        {!(this.props.profile && this.props.profile.data && this.props.profile.data.id) && (
                          <Redirect to="/login" />
                        )}
                      </div>
                    </Route>
                    {routes.map(route => {
                      if (route.requireLoggedIn) {
                        return (<LoggedInRoute key={route.path} {...route} />)
                      }

                      return (<Route key={route.path} {...route} />)
                    })}
                  </Switch>
                </div>
              </div>
            </Route>
          </Switch>
        </MuiThemeProvider>
      </div>
    )

    if (this.props.server) {
      return (
        <StaticRouter
          location={this.props.location}
          context={this.props.context}>
          {content}
        </StaticRouter>
      )
    }

    return (
      <BrowserRouter>
        {content}
      </BrowserRouter>
    )
  }
}

const AppIntl = injectIntl(withStyles(styles)(App))

export const fetchData = async dispatch => {
  const {
    profile,
    myShops,
  } = await promiseMap({
    profile: dispatch(getProfile()),
    myShops: dispatch(getMyShops()),
  })

  let selectedShop

  if (profile && myShops && myShops.length > 0) {
    const savedShopId = ls('shopId')
    const savedShop = myShops.find(shop => shop.id === savedShopId)

    selectedShop = savedShop || myShops[0]

    const messagesResult = await dispatch(getMessages(selectedShop.id, 0))

    dispatch(setMessagesBadge(messagesResult.unreadCount))
    dispatch(setShop(selectedShop))
  }

  return {
    selectedShop,
    profile,
    myShops,
  }
}

class AppWrapper extends Component {
  hasLoaded = () => {
    return this.props.profile.loaded
      && this.props.myShops.loaded
      && (!this.props.myShops.data
        || this.props.shop)
  }

  async componentWillMount() {
    let locale = 'ja'

    if (!this.hasLoaded()) {
      const results = await fetchData(this.props.dispatch)

      if (results.selectedShop) {
        this.props.setShop(results.selectedShop)
      }

      if (results.selectedShop && results.selectedShop.locale) {
        locale = results.selectedShop.locale
      }
    } else {
      if (this.props.myShops.data) {
        const savedShopId = ls('shopId')
        const savedShop = this.props.myShops.data.find(shop => shop.id === savedShopId)

        const selectedShop = savedShop || this.props.myShops.data[0]
        this.props.setShop(selectedShop)
        locale = selectedShop.locale
      }
    }

    this.props.setLocale(locale)
  }

  componentDidUpdate(prevProps) {
    if (this.props.messages.data
      && prevProps.messages.data
      && this.props.messages.data.unreadCount !== prevProps.messages.data.unreadCount
      && !window.location.pathname.includes('messages')) {
      this.props.setMessagesBadge(this.props.messages.data.unreadCount)
    }
  }

  render() {
    if (!this.hasLoaded()) {
      return false
    }

    moment.locale(this.props.locale)
    const messages = locales[this.props.locale]

    return (
      <IntlProvider
        locale={this.props.locale}
        key={this.props.locale}
        messages={messages}>
        <AppIntl {...this.props}/>
      </IntlProvider>
    )
  }
}

const mapStateToProps = state => ({
  shop: state.features.shop,
  locale: state.features.locale,
  messages: state.api.messages.default,
  messagesBadge: state.features.messagesBadge,
  dashboardDate: moment(state.features.dashboardDate),
  profileDropdownOpen: state.features.flags.profileDropdownOpen,
  profile: state.api.profile.default,
  myShops: state.api.myShops.default,
})

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    mergeDataFromSocketIO,
    setMessagesBadge,
    setShop,
    setLocale,
    setFlag,
    logout,
  }, dispatch),
  dispatch,
})

AppWrapper.fetchData = fetchData

export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper)
