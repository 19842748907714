import React from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'

export default withStyles({
  container: {
    display: 'flex',
  },
  cta: {
    minWidth: 100,
    borderRadius: 0,
    borderLeft: 'none',
    borderRight: 'none',
    '&:hover': {
      borderLeft: 'none',
      borderRight: 'none',
    },
  },
  ctaFirst: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    borderLeft: '1px solid rgba(138, 136, 138, 0.5)',
    '&:hover': {
      borderLeft: '1px solid rgba(138, 136, 138, 0.5)',
    }
  },
  ctaLast: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderRight: '1px solid rgba(138, 136, 138, 0.5)',
    '&:hover': {
      borderRight: '1px solid rgba(138, 136, 138, 0.5)',
    }
  },
  ctaSelected: {
    backgroundColor: 'white',
    color: '#FF6069',
    border: 'none',
    '&:hover': {
      backgroundColor: 'white',
      color: '#FF6069',
      border: 'none',
    },
  },
})(({ items, value, onSelect, className, classes }) => (
  <ButtonGroup className={classNames(classes.container, className)}>
    {items.map((item, index) => {
      const selected = item.value === value

      return (
        <Button
          key={index}
          variant={selected ? 'contained' : 'outlined'}
          color={selected ? 'primary' : 'secondary'}
          size="large"
          className={classNames(classes.cta, {
            [classes.ctaFirst]: index === 0,
            [classes.ctaLast]: index === items.length - 1,
            [classes.ctaSelected]: selected,
          })}
          onClick={() => onSelect(item.value)}>
          {item.label}
        </Button>
      )
    })}
  </ButtonGroup>
))
