import React from 'react'

const SvgComponent = ({ color, ...rest }) => (
  <svg width="1em" height="1em" viewBox="0 0 19 19" fill="none" {...rest}>
    <path
      d="M17.273 17.583H3.09C2.489 17.583 2 17.08 2 16.458V1.82M5.273 14.204V8.575M8.545 4.07v10.134M11.818 14.206v-2.251M15.09 14.205V7.45"
      stroke={color || '#A8B2B9'}
      strokeWidth={2.182}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgComponent
