import React from 'react'

const SvgComponent = ({ color, ...rest }) => (
  <svg width="1em" height="1em" viewBox="0 0 19 20" fill="none" {...rest}>
    <path
      d="M17.871 12.197l-1.316-1.125a7.224 7.224 0 000-2.322l1.316-1.126a.644.644 0 00.187-.707l-.018-.052a8.913 8.913 0 00-1.601-2.77l-.037-.043a.645.645 0 00-.705-.19l-1.633.58a7.08 7.08 0 00-2.003-1.157l-.316-1.708a.644.644 0 00-.518-.516l-.054-.01a9.076 9.076 0 00-3.195 0l-.054.01a.644.644 0 00-.518.516l-.318 1.716A7.07 7.07 0 005.1 4.446l-1.645-.584a.643.643 0 00-.705.19l-.036.043a8.96 8.96 0 00-1.602 2.77l-.018.052a.645.645 0 00.187.707l1.332 1.138a7.069 7.069 0 00-.092 1.147c0 .385.03.771.092 1.147L1.28 12.193a.644.644 0 00-.187.707l.018.053a8.938 8.938 0 001.602 2.77l.036.042a.645.645 0 00.705.191l1.645-.585a7.024 7.024 0 001.99 1.154l.317 1.715a.644.644 0 00.518.517l.054.01c1.057.19 2.139.19 3.195 0l.054-.01a.643.643 0 00.518-.517l.316-1.707a7.031 7.031 0 002.003-1.158l1.633.581a.643.643 0 00.706-.19l.036-.043a8.958 8.958 0 001.6-2.77l.019-.053a.64.64 0 00-.187-.703zm-2.742-3.21a5.689 5.689 0 010 1.852l-.133.806 1.5 1.283a7.432 7.432 0 01-.855 1.48l-1.864-.662-.631.518a5.617 5.617 0 01-1.593.92l-.766.288-.36 1.949a7.583 7.583 0 01-1.707 0l-.36-1.953-.76-.291a5.62 5.62 0 01-1.58-.919l-.631-.52-1.877.667a7.591 7.591 0 01-.856-1.479l1.517-1.295-.13-.804a5.784 5.784 0 01-.075-.914c0-.308.024-.615.075-.914l.13-.804L2.656 6.9a7.434 7.434 0 01.856-1.478l1.877.667.63-.52a5.621 5.621 0 011.582-.919l.761-.287.36-1.953a7.584 7.584 0 011.708 0l.36 1.949.765.287a5.643 5.643 0 011.593.92l.63.519 1.865-.661c.342.46.627.956.856 1.478l-1.5 1.284.13.802zM9.578 6.176a3.536 3.536 0 100 7.072 3.536 3.536 0 000-7.072zm1.59 5.127a2.242 2.242 0 01-1.59.66 2.242 2.242 0 01-2.08-1.39 2.242 2.242 0 01-.17-.861c0-.6.235-1.165.658-1.591.426-.426.99-.66 1.592-.66.6 0 1.165.234 1.59.66a2.242 2.242 0 01.66 1.59c0 .602-.235 1.166-.66 1.592z"
      fill={color || '#A8B2B9'}
      stroke={color || '#A8B2B9'}
      strokeWidth={0.7}
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgComponent
