import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import {
  injectIntl,
  defineMessages,
  FormattedMessage,
} from 'react-intl'
import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Divider from '@material-ui/core/Divider'
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded'
import DirectionsBikeRoundedIcon from '@material-ui/icons/DirectionsBikeRounded'
import {
  getReservationDetails,
} from '../redux/api/actions'
import getImageForMeal from '../utils/getImageForMeal'
import promiseMap from '../utils/promiseMap'

const DATE_FORMAT = 'YYYYMMDD'

const messages = defineMessages({
  delivery: {
    id: 'reservation.delivery',
    defaultMessage: 'Delivery',
  },
})

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  backButton: {
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: 24,
    display: 'flex',
    alignItems: 'center',
    color: '#535353',
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  reservations: {
    marginTop: theme.spacing(2),
  },
  reservationInfoContainer: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  reservationImage: {
    width: 315,
    height: 203,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 280,
      backgroundRepeat: 'no-repeat',
    },
  },
  reservationInfo: {
    flex: 1,
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginTop: theme.spacing(3),
    },
  },
  reservationInfoTitleText: {
    fontSize: 16,
    color: '#292529',
  },
  reservationInfoValueText: {
    fontSize: 16,
    fontWeight: 600,
    color: '#292529',
    marginBottom: theme.spacing(3),
  },
  reservation: {
    width: '100%',
    display: 'flex',
    backgroundColor: 'white',
    border: '1px solid #EFEFEF',
    borderRadius: 4,
    boxShadow: '0px 3.27px 12px rgba(189, 159, 189, 0.11)',
    padding: theme.spacing(3),
    marginBottom: 6,
    cursor: 'default',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      textAlign: 'left',
    },
  },
  reservationCanceled: {
    background: '#EFEFEF',
    boxShadow: 'none',
  },
  reservationIndexText: {
    fontSize: 14,
    color: '#A8B2B9',
    minWidth: 70,
    marginRight: 4,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  orderType: {
    width: 60,
    height: 20,
  },
  deliveryIcon: {
    marginTop: -2,
    marginLeft: theme.spacing(),
  },
  reservationMealNameText: {
    fontSize: 14,
    color: '#292529',
    fontWeight: 600,
    flex: 1,
    marginRight: 4,
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(),
    },
  },
  reservationDetails: {
    display: 'flex',
    flex: 1,
  },
  reservationUser: {
    fontSize: 14,
    color: '#292529',
    flex: 1,
    marginRight: 4,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  reservationUserCountText: {
    fontSize: 14,
    color: '#292529',
    minWidth: 70,
    marginRight: 4,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  reservationStatusText: {
    fontSize: 14,
    color: '#292529',
    minWidth: 70,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  reservationStatusCanceledText: {
    color: '#ED5454',
    fontWeight: 600,
  },
  reservationNotes: {
    flex: 1,
    background: '#F5F5F5',
    boxShadow: '0px 3.27px 12px rgba(189, 159, 189, 0.11)',
    borderRadius: '0px 0px 4px 4px',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  reservationNotesTitle: {
    fontSize: 16,
    fontWeight: 600,
    color: '#292529',
  },
  reservationNoteValues: {
    margin: 0,
  },
  reservationNotesName: {
    fontSize: 14,
    fontWeight: 600,
  },
  reservationNotesValue: {
    fontSize: 14,
    fontWeight: 400,
  },
  deliverySection: {

  },
  deliveryTitle: {
    display: 'flex',
  },
  deliveryTitleText: {
    marginLeft: theme.spacing(),
    marginTop: 2,
  },
})

const fetchData = (dispatch, params) => promiseMap({
  reservationDetails: dispatch(getReservationDetails(params.reservationId)),
})

class Reservation extends Component {
  hasLoaded = () => {
    return this.props.reservationDetails && this.props.reservationDetails.loaded
  }

  componentWillMount() {
    if (!this.hasLoaded()) {
      fetchData(this.props.dispatch, this.props.match.params)
    }
  }

  onClickBack = () => {
    this.props.history.push(`/dashboard/${this.props.reservationDetails.data.pickup.date}`)
  }

  render() {
    let image, date, dayName, pickupTime, userName = ''
    let pastReservations = []

    if (this.hasLoaded()) {
      image = getImageForMeal(this.props.reservationDetails.data.meal)
      date = moment(this.props.reservationDetails.data.pickup.date).format('M/D')
      dayName = moment(this.props.reservationDetails.data.pickup.date).format('ddd')
      pickupTime = this.props.reservationDetails.data.pickup.time
      userName = this.props.reservationDetails.data.user

      pastReservations = [...this.props.reservationDetails.data.pastReservations]
      pastReservations.sort((first, second) => first.orderIndex > second.orderIndex ? -1 : 1)
    }

    return (
      <div className={this.props.classes.container}>
        <Button
          variant="outlined"
          color="secondary"
          size="large"
          className={this.props.classes.backButton}
          onClick={this.onClickBack}>
          <NavigateBeforeRoundedIcon />
          戻る
        </Button>
        <Typography
          variant="h6"
          className={this.props.classes.title}>
          <FormattedMessage
            id="reservation.title"
            defaultMessage="{date} ({dayName}) {pickupTime}のご予約 : {userName}"
            values={{
              date,
              dayName,
              pickupTime,
              userName,
            }} />
        </Typography>
        <Divider className={this.props.classes.divider} />
        <div className={this.props.classes.reservationInfoContainer}>
          <div
            className={this.props.classes.reservationImage}
            style={{
              backgroundImage: `url(${image})`,
            }} />
          {this.hasLoaded() && (
            <div className={this.props.classes.reservationInfo}>
              <Typography
                variant="body1"
                className={this.props.classes.reservationInfoTitleText}>
                <FormattedMessage
                  id="reservation.meal"
                  defaultMessage="Meal" />
              </Typography>
              <Typography
                variant="body1"
                className={this.props.classes.reservationInfoValueText}>
                {this.props.reservationDetails.data.meal.name}
              </Typography>
              {this.props.reservationDetails.data.options && this.props.reservationDetails.data.options.length > 0 && (
                <div className={this.props.classes.reservationNotes}>
                  <Typography
                    variant="body1"
                    className={this.props.classes.reservationNotesTitle}>
                    <FormattedMessage
                      id="reservation.options-title"
                      defaultMessage="Options" />
                  </Typography>
                  <ul className={this.props.classes.reservationNoteValues}>
                    {this.props.reservationDetails.data.options.map(option => (
                      <li key={option.name}>
                        <Typography
                          variant="body1"
                          className={this.props.classes.reservationNotesName}>
                          {option.name}: <span className={this.props.classes.reservationNotesValue}>{option.value}</span>
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {this.props.reservationDetails.data.sides && this.props.reservationDetails.data.sides.length > 0 && (
                <div className={this.props.classes.reservationNotes}>
                  <Typography
                    variant="body1"
                    className={this.props.classes.reservationNotesTitle}>
                    <FormattedMessage
                      id="reservation.sides"
                      defaultMessage="Sides" />
                  </Typography>
                  <ul className={this.props.classes.reservationNoteValues}>
                    {this.props.reservationDetails.data.sides.map(side => (
                      <li key={side.name}>
                        <Typography
                          variant="body1"
                          className={this.props.classes.reservationNotesValue}>
                          {side.name}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <Typography
                variant="body1"
                className={this.props.classes.reservationInfoTitleText}>
                <FormattedMessage
                  id="reservation.user"
                  defaultMessage="User" />
              </Typography>
              <Typography
                variant="body1"
                className={this.props.classes.reservationInfoValueText}>
                {this.props.reservationDetails.data.user}
              </Typography>
              <Typography
                variant="body1"
                className={this.props.classes.reservationInfoTitleText}>
                <FormattedMessage
                  id="reservation.reservation-count-title"
                  defaultMessage="Number of reservations" />
              </Typography>
              <Typography
                variant="body1"
                className={this.props.classes.reservationInfoValueText}>
                <FormattedMessage
                  id="reservation.reservation-count-value"
                  defaultMessage="{count}"
                  values={{
                    count: this.props.reservationDetails.data.orderIndex,
                  }} />
              </Typography>
              {this.props.reservationDetails.data.deliveryDetails && (
                <div className={this.props.classes.deliverySection}>
                  <div className={this.props.classes.deliveryTitle}>
                    <DirectionsBikeRoundedIcon
                      color="primary"
                      className={this.props.classes.deliveryIcon} />
                    <Typography
                      variant="body1"
                      color="primary"
                      className={this.props.classes.deliveryTitleText}>
                      <FormattedMessage
                        id="reservation.delivery-title"
                        defaultMessage="This is a delivery order" />
                    </Typography>
                  </div>
                  <Typography
                    variant="caption"
                    className={this.props.classes.deliveryText}>
                    <FormattedMessage
                      id="reservation.delivery-caption"
                      defaultMessage="This order will be pickup up by a Potluck delivery person and taken to the customer" />
                  </Typography>
                </div>
              )}
            </div>
          )}
        </div>
        <Divider className={this.props.classes.divider} />
        <Typography
          variant="h6"
          className={this.props.classes.title}>
          <FormattedMessage
            id="reservation.order-history"
            defaultMessage="Past reservations" />
        </Typography>
        {this.hasLoaded() && (
          <div className={this.props.classes.reservations}>
            {pastReservations.map((reservation, index) => (
              <div
                key={reservation.id}
                className={classNames(this.props.classes.reservation, {
                  [this.props.classes.reservationCanceled]: reservation.canceled,
                })}>
                <Typography
                  variant="body1"
                  className={this.props.classes.reservationIndexText}>
                  {moment(reservation.pickup.date, DATE_FORMAT).format('M/D(ddd)')}
                </Typography>
                <div className={this.props.classes.orderType}>
                  {reservation.deliveryDetails && (
                    <Tooltip
                      title={this.props.intl.formatMessage(messages.delivery)}
                      aria-label={this.props.intl.formatMessage(messages.delivery)}>
                      <DirectionsBikeRoundedIcon
                        color="primary"
                        className={this.props.classes.deliveryIcon} />
                    </Tooltip>
                  )}
                </div>
                <Typography
                  variant="body1"
                  className={this.props.classes.reservationMealNameText}>
                  {reservation.meal.name}
                </Typography>
                <div className={this.props.classes.reservationDetails}>
                  <Typography
                    variant="body1"
                    className={this.props.classes.reservationUser}>
                    {reservation.user}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={this.props.classes.reservationUserCountText}>
                    <FormattedMessage
                      id="reservation.order-index"
                      defaultMessage="{index, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} visit"
                      values={{
                        index: reservation.orderIndex,
                      }} />
                  </Typography>
                </div>
              </div>
            ))}
            {pastReservations.length === 0 && (
              <Typography
                variant="body1"
                className={this.props.classes.reservationsEmptyText}>
                <FormattedMessage
                  id="reservation.none"
                  defaultMessage="None" />
              </Typography>
            )}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  reservationDetails: state.api.reservationDetails[ownProps.match.params.reservationId],
})

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    getReservationDetails,
  }, dispatch),
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(withStyles(styles)(Reservation))))
