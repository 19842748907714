import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded'
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded'
import {
  setMessagesBadge
} from '../redux/features/actions'
import {
  getMessages,
  markMessageAsRead,
} from '../redux/api/actions'
import promiseMap from '../utils/promiseMap'

const formatDateDiff = (first, second) => {
  if (first.diff(second, 'seconds') < 2) {
    return (
      <FormattedMessage
        id="messages.time-now"
        defaultMessage="Now" />
    )
  } else if (first.diff(second, 'minutes') < 1) {
    return (
      <FormattedMessage
        id="messages.time-seconds"
        defaultMessage="{seconds} {seconds, plural, one {second} other {seconds}} ago"
        values={{
          seconds: first.diff(second, 'seconds')
        }} />
    )
  } else if (first.diff(second, 'hours') < 1) {
    return (
      <FormattedMessage
        id="messages.time-minutes"
        defaultMessage="{minutes} {minutes, plural, one {minute} other {minutes}} ago"
        values={{
          minutes: first.diff(second, 'minutes')
        }} />
    )
  } else if (first.diff(second, 'days') < 1) {
    return (
      <FormattedMessage
        id="messages.time-hours"
        defaultMessage="{hours} {hours, plural, one {hour} other {hours}} ago"
        values={{
          hours: first.diff(second, 'hours')
        }} />
    )
  } else if (first.diff(second, 'days') < 5) {
    return (
      <FormattedMessage
        id="messages.time-days"
        defaultMessage="{days} {days, plural, one {day} other {days}} ago"
        values={{
          days: first.diff(second, 'days')
        }} />
    )
  }

  return second.format('MMMM Do')
}

const getMessageTitle = message => {
  let title = 'New message'

  if (message.data && message.data.notification) {
    title = message.data.notification
  }

  if (message.data && message.data.dialog) {
    title = message.data.dialog.title
  }

  return title
}

const getMainActionForMessage = message => {
  if (message.data) {
    if (message.data.action) {
      return message.data.action
    }

    if (message.data.dialog && message.data.dialog.ctas) {
      const navigationCta = message.data.dialog.ctas.find(cta => cta.action && cta.action.type === 'navigation')

      if (navigationCta) {
        return navigationCta.action
      }
    }
  }
}

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  topBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #D8D8D8',
    paddingBottom: theme.spacing(2),
  },
  topBarLeft: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      flex: 1,
    },
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  title: {
    fontSize: 24,
    display: 'flex',
    alignItems: 'center',
    color: '#535353',
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'flex-end',
    letterSpacing: '-0.112198px',
    color: '#8A888A',
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      marginLeft: 0,
    },
  },
  pageText: {
    marginRight: theme.spacing(),
  },
  pageButtons: {
    alignSelf: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  pageButtonsMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  content: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #EFEFEF',
    margin: theme.spacing(3, 0),
  },
  message: {
    padding: theme.spacing(3),
  },
  messageBorder: {
    borderBottom: '1px solid #EFEFEF',
  },
  messageContent: {
    minHeight: 36,
    display: 'flex',
    alignItems: 'center',
  },
  messageTime: {
    flex: 1,
    minwidth: 82,
    fontSize: 16,
    lineHeight: '24px',
    color: '#A8B2B9',
  },
  messageTitle: {
    flex: 4,
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '24px',
    color: '#292529',
  },
})

const fetchData = (dispatch, params, shop) => {
  dispatch(setMessagesBadge(0))

  return promiseMap({
    messages: dispatch(getMessages(shop.id, params.page || 0)),
    markMessageAsRead: dispatch(markMessageAsRead(shop.id, 'all'))
  })
}

class Messages extends Component {
  hasLoaded = () => {
    return this.props.messages.loaded
  }

  onClickBack = () => {
    this.props.history.goBack()
  }

  onClickPagePrevious = () => {
    this.props.getMessages(this.props.shop.id, this.props.messages.data.page - 1)
  }

  onClickPageNext = () => {
    this.props.getMessages(this.props.shop.id, this.props.messages.data.page + 1)
  }

  onClickMessageAction = action => {
    if (action.type === 'navigation') {
      this.props.history.push(action.destination)
    }
  }

  componentWillMount() {
    fetchData(this.props.dispatch, this.props.match.params, this.props.shop)
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.shop.id !== this.props.shop.id) {
      this.setState({
        loading: true,
      })

    await fetchData(this.props.dispatch, this.props.match.params, this.props.shop)

      if (this.props.messages.data
        && prevProps.messages.data
        && this.props.messages.data.unreadCount !== prevProps.messages.data.unreadCount) {
        this.props.markMessageAsRead(this.props.shop.id, 'all')
      }
    }
  }

  render() {
    let messages = []
    let page
    let totalPages
    let canClickPagePrevious = false
    let canClicPageNext = false

    if (this.props.messages.data) {
      ({ messages, page, totalPages } = this.props.messages.data)
      canClickPagePrevious = page > 0
      canClicPageNext = page < totalPages - 1
    }

    const now = moment.tz('Asia/Tokyo')

    return (
      <div className={this.props.classes.container}>
        <div className={this.props.classes.topBar}>
          <div className={this.props.classes.topBarLeft}>
            <div className={this.props.classes.titleContainer}>
              <Typography
                variant="h6"
                className={this.props.classes.title}>
                <FormattedMessage
                  id="messages.title"
                  defaultMessage="Messages" />
              </Typography>
            </div>
            <Typography
              variant="body1"
              className={this.props.classes.subtitle}>
              <FormattedMessage
                id="messages.subtitle"
                defaultMessage="Notifications from Potluck" />
            </Typography>
          </div>
          <div className={this.props.classes.pageButtons}>
            {typeof page === 'number' && typeof totalPages === 'number' && (
              <Typography
                variant="caption"
                className={this.props.classes.pageText}>
                <FormattedMessage
                  id="messages.pagination"
                  defaultMessage="{page} of {totalPages}"
                  values={{
                    page: page + 1,
                    totalPages,
                  }} />
              </Typography>
            )}
            <IconButton
              size="small"
              disabled={!canClickPagePrevious}
              onClick={this.onClickPagePrevious}>
              <NavigateBeforeRoundedIcon />
            </IconButton>
            <IconButton
              size="small"
              disabled={!canClicPageNext}
              onClick={this.onClickPageNext}>
              <NavigateNextRoundedIcon />
            </IconButton>
          </div>
        </div>
        <div className={this.props.classes.content}>
          {messages.map((message, index) => (
            <div
              key={message.id}
              className={classNames(this.props.classes.message, {
                [this.props.classes.messageBorder]: index < messages.length - 1,
              })}>
              <div className={this.props.classes.messageContent}>
                <Typography className={this.props.classes.messageTime}>
                  {formatDateDiff(now, moment.tz(message.createdAt, 'Asia/Tokyo'))}
                </Typography>
                <Typography className={this.props.classes.messageTitle}>
                  {getMessageTitle(message)}
                </Typography>
                {getMainActionForMessage(message) && (
                  <Button
                    variant="outlined"
                    className={this.props.classes.messageCta}
                    onClick={() => this.onClickMessageAction(getMainActionForMessage(message))}>
                    <FormattedMessage
                      id="messages.cta"
                      defaultMessage="Details" />
                  </Button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  messages: state.api.messages.default,
  shop: state.features.shop,
})

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    getMessages,
    setMessagesBadge,
    markMessageAsRead,
  }, dispatch),
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Messages)))
