import 'core-js'
import 'raf/polyfill'
import 'es7-object-polyfill'
import 'promise-polyfill/src/polyfill'
import { polyfill } from 'es6-object-assign'

import React from 'react'
import { hydrate } from 'react-dom'
import { Provider } from 'react-redux'
// import logger from 'redux-logger'
import {
  createStore,
  combineReducers,
  applyMiddleware,
} from 'redux'
import thunk from 'redux-thunk'
import { reducer as formReducer } from 'redux-form'
import './index.css'
import App from './App'
import api from './redux/api/reducer'
import features from './redux/features/reducer'

polyfill()

// https://stackoverflow.com/questions/36213455/add-startswith-in-ie-11
if (!String.prototype.startsWith) {
  // eslint-disable-next-line
  String.prototype.startsWith = function(searchString, position) {
    position = position || 0
    return this.substr(position, searchString.length) === searchString
  }
}

const preloadedState = window.__PRELOADED_STATE__
delete window.__PRELOADED_STATE__

const store = createStore(combineReducers({
  api,
  features,
  form: formReducer,
}), preloadedState, applyMiddleware(thunk/*, logger */))

hydrate((
  <Provider store={store}>
    <App server={false} />
  </Provider>
), document.getElementById('root'))
