import React from 'react'
import { withStyles } from '@material-ui/core/styles'

export default withStyles({
  container: {
    position: 'relative',
  },
  number: {
    position: 'absolute',
    top: 2,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 12,
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    color: '#FFFFFF',
  },
})(({ number=0, fill='#FF6069', classes, ...rest }) => (
  <div {...rest}>
    <div className={classes.container}>
      <div className={classes.number}>
        {number}
      </div>
      <svg width={30} height={29} fill="none">
        <path
          d="M26.47 0H3.53C1.587 0 0 1.566 0 3.48v15.21c0 1.914 1.588 3.48 3.53 3.48h1.64v4.785c0 .662.389 1.27 1.006 1.567.248.121.512.174.76.174.405 0 .793-.122 1.111-.383l7.888-6.143h10.536c1.94 0 3.529-1.566 3.529-3.48V3.48C30 1.566 28.412 0 26.47 0zm0 18.69H15.319c-.389 0-.777.139-1.095.382L8.7 23.371V20.43c0-.957-.776-1.74-1.765-1.74H3.53V3.48h22.942v15.21z"
          fill={fill}
        />
        <path
          d="M27.466 3.016v16.765H15.343c-.422 0-.844.153-1.19.422L8.15 24.94v-3.242a1.912 1.912 0 00-1.918-1.918H2.53V3.016h24.936z"
          fill={fill}
        />
      </svg>
    </div>
  </div>
))
