import React from 'react'
import { injectIntl } from 'react-intl'
import './FormField.css'

export default injectIntl(({
  input,
  disabled,
  placeholder,
  label,
  intl,
  type,
  loading,
  meta: {
    touched,
    error,
  },
}) => (
  <div className="form-field__item">
    <div className="form-field__item-key">
      {label}
    </div>
    <div className="form-field__item-value">
      <input {...input} placeholder={placeholder} type={type} disabled={disabled} />
      {loading && (<div className="form-field__loading" />)}
      {touched && (error && (
        <span className="form-field__item-error">
          {error.id ? intl.formatMessage(error) : error}
        </span>
      ))}
    </div>
  </div>
))
