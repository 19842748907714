import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ls from 'local-storage'
import {
  injectIntl,
  defineMessages,
  FormattedMessage,
} from 'react-intl'
import classNames from 'classnames'
import {
  Field,
  reduxForm,
  reset,
} from 'redux-form'
import {
  withRouter,
} from 'react-router-dom'
import {
  Select,
  TextField,
  Checkbox,
} from 'redux-form-material-ui'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import Chip from '@material-ui/core/Chip'
import MuiCheckbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  setShop,
  setLocale,
} from '../redux/features/actions'
import {
  getNeighborhoods,
  getMyShops,
  getLineAccounts,
  deleteLineAccount,
  editShop,
  getMyShopPreferences,
  editMyShopPreferences,
  getNotificationEmails,
  setNotificationEmails,
} from '../redux/api/actions'
import EditPasswordDialog from './dialogs/EditPasswordDialog'
import ConnectLineDialog from './dialogs/ConnectLineDialog'
import promiseMap from '../utils/promiseMap'
import arraysEqual from '../utils/arraysEqual'
import LineButton from '../components/LineButton'

const FORM_NAME = 'settings'

const LOCALES = [{
  label: '日本語',
  value: 'ja',
}, {
  label: 'English',
  value: 'en',
}]

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i

const REQUIRED_FIELDS = [
  'details',
  'zip',
  'neighborhoodId',
  'address',
  'locale',
  'contactName',
  'contactPhone',
  'contactEmail',
]

const HOLIDAYS = [{
  name: '元旦',
  code: 'new-years',
}, {
  name: '成人の日',
  code: 'coming-of-age',
}, {
  name: '全国ファウンデーションの日',
  code: 'national-foundation',
}, {
  name: '皇后陛下の誕生日',
  code: 'the-emporers-birthday',
}, {
  name: '春分の日',
  code: 'vernal-equinox',
}, {
  name: '海の日',
  code: 'marine',
}, {
  name: '山の日',
  code: 'mountain',
}, {
  name: '敬老の日',
  code: 'respect-for-the-aged',
}, {
  name: '秋分の日',
  code: 'autumnal-equinox',
}, {
  name: 'スポーツの日',
  code: 'sports',
}, {
  name: '文化の日',
  code: 'culture',
}, {
  name: '勤労感謝の日',
  code: 'labor-thanksgiving',
}, {
  name: 'ゴールデンウィーク',
  code: 'golden-week',
}]

const messages = defineMessages({
  missingField: {
    id: 'settings.missing-field',
    defaultMessage: 'This field is required.',
  },
})

const validate = (values, props) => {
  const errors = {}

  for (let fieldName of REQUIRED_FIELDS) {
    if (!values[fieldName]) {
      errors[fieldName] = props.intl.formatMessage(messages.missingField)
    }
  }

  return errors
}

const styles = theme => ({
  container: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  content: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    color: '#292529',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  saveCta: {
    width: 190,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  ctaProgress: {
    position: 'absolute',
  },
  formBody: {
    marginTop: theme.spacing(4),
  },
  formField: {
    marginBottom: theme.spacing(4),
  },
  formFieldNoGutter: {
    marginBottom: 0,
  },
  formFieldPostal: {
    width: 180,
  },
  formFieldSelect: {
    minWidth: 180,
  },
  textFieldNoUnderline: {
    '& label.Mui-focused': {
      color: theme.palette.secondary.main,
    },
    '&:before': {
      borderBottom: 'none !important',
    },
    '&:after': {
      borderBottom: 'none !important',
    },
  },
  notificationEmails: {
    marginBottom: theme.spacing(1),
  },
  notificationEmail: {
    margin: theme.spacing(1, 1, 1, 0),
  },
  newNotificationEmail: {
    marginBottom: theme.spacing(4),
  },
  checkboxField: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2, 0),
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 12,
    fontWeight: 400,
  },
  lineUsers: {
    marginBottom: theme.spacing(1),
  },
  lineUser: {
    margin: theme.spacing(1, 1, 1, 0),
    backgroundColor: '#00C300',
    color: '#FFFFFF',
    '&:focus': {
      backgroundColor: '#00C300',
    },
    '& svg': {
      color: 'rgba(255, 255, 255, 0.7)',
      '&:hover': {
        color: '#FFFFFF',
      },
    },
  },
  lineButton: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(4),
  },
  changePasswordCta: {
    marginTop: -12,
    marginLeft: -12,
  },
})

const fetchData = ( dispatch, shop ) => promiseMap({
  neighborhoods: dispatch(getNeighborhoods()),
  myShops: dispatch(getMyShops()),
  lineAccounts: dispatch(getLineAccounts(shop.id)),
  myShopPreferences: dispatch(getMyShopPreferences(shop.id)),
  notificationEmails: dispatch(getNotificationEmails(shop.id)),
})

class Settings extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      editPasswordDialogOpen: false,
      connectLineDialogOpen: false,
      newNotificationEmail: '',
      notificationEmails: [],
      holidays: props.shop.holidays || [],
    }
  }

  hasLoaded = () => {
    return this.props.neighborhoods.loaded
      && this.props.myShops.loaded
      && this.props.lineAccounts.loaded
      && this.props.myShopPreferences.loaded
  }

  onClickSave = async values => {
    this.setState({ loading: true })

    const promises = [
      this.props.editShop(this.props.shop.id, {
        values,
        holidays: this.state.holidays,
      }),
      // this.props.editMyShopPreferences(this.props.shop.id, values.preferences)
    ]

    if (!arraysEqual(this.state.notificationEmails, this.props.shop.emails)) {
      promises.push(this.props.setNotificationEmails(this.props.shop.id, this.state.notificationEmails))
    }

    await Promise.all(promises)

    if (values.locale !== this.props.initialValues.locale) {
      this.props.setLocale(values.locale)
    }

    const results = await fetchData(this.props.dispatch, this.props.shop)
    const myNewShop = results.myShops.find(myShop => myShop.id === this.props.shop.id)

    this.props.setShop(myNewShop)

    this.setState({ loading: false })
  }

  onClickHoliday = holiday => {
    if (this.state.holidays.includes(holiday.code)) {
      this.setState({
        holidays: this.state.holidays.filter(code => code !== holiday.code),
      })
    } else {
      this.setState({
        holidays: [...this.state.holidays, holiday.code],
      })
    }
  }

  onClickChangePassword = () => {
    this.setState({
      editPasswordDialogOpen: true,
    })
  }

  onClickCloseChangePassword = () => {
    this.setState({
      editPasswordDialogOpen: false,
    })
  }

  onClickRemoveNotificationEmail = email => {
    this.setState({
      notificationEmails: this.state.notificationEmails.filter(notificationEmail => notificationEmail !== email),
    })
  }

  onChangeNewNotificationEmail = event => {
    this.setState({
      newNotificationEmail: event.nativeEvent.target.value,
    })
  }

  onKeyPressNewNotificationEmail = event => {
    if (event.key === 'Enter' && EMAIL_REGEX.test(this.state.newNotificationEmail)) {
      this.setState({
        notificationEmails: this.state.notificationEmails.concat(this.state.newNotificationEmail),
        newNotificationEmail: '',
      })
    }
  }

  onClickConnectLine = () => {
    this.setState({
      connectLineDialogOpen: true,
    })
  }

  onClickCloseConnectLineDialog = () => {
    this.setState({
      connectLineDialogOpen: false,
    })
  }

  onClickRemoveLineAccount = async lineAccount => {
    await this.props.deleteLineAccount(this.props.shop.id, lineAccount.lineUserId)
    await this.props.getLineAccounts(this.props.shop.id)
  }

  async componentWillMount() {
    let notificationEmails

    if (!this.hasLoaded()) {
      const results = await fetchData(this.props.dispatch, this.props.shop)

      notificationEmails = this.props.notificationEmails

    } else {
      notificationEmails = this.props.notificationEmails
    }

    this.setState({
      notificationEmails,
    })
  }

  async componentDidUpdate(prevProps){
    if (prevProps.shop.id !== this.props.shop.id) {
      this.setState({
        loading: true,
      })

      this.props.reset(FORM_NAME)
      await fetchData(this.props.dispatch, this.props.shop)

      this.setState({
        loading: false,
        notificationEmails: this.props.notificationEmails,
      })
    }
  }

  render() {
    let lineAccounts = []

    if (this.hasLoaded()) {
      lineAccounts = this.props.lineAccounts.data
    }

    return (
      <div className={this.props.classes.container}>
        <EditPasswordDialog
          open={this.state.editPasswordDialogOpen}
          onClose={this.onClickCloseChangePassword} />
        <ConnectLineDialog
          open={this.state.connectLineDialogOpen}
          onClose={this.onClickCloseConnectLineDialog} />
        <Paper className={this.props.classes.content}>
          <div className={this.props.classes.topBar}>
            <Typography
              variant="h6"
              className={this.props.classes.title}>
              {this.props.shop && this.props.shop.name}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={(this.props.pristine && arraysEqual(this.props.shop.holidays, this.state.holidays) && arraysEqual(this.state.notificationEmails, this.props.notificationEmails) && this.state.newNotificationEmail.length === 0) || this.props.invalid || this.state.loading}
              className={this.props.classes.saveCta}
              onClick={this.props.handleSubmit(this.onClickSave)}>
              <FormattedMessage
                id="settings.cta"
                defaultMessage="Save" />
              {this.state.loading && (
                <CircularProgress
                  size={24}
                  color="primary"
                  className={this.props.classes.ctaProgress} />
              )}
            </Button>
          </div>
          <div className={this.props.classes.formBody}>
            <form>
              <Field
                name="locale"
                component={Select}
                variant="outlined"
                className={classNames(this.props.classes.formField, this.props.classes.formFieldSelect)}>
                {LOCALES.map(locale => (
                  <MenuItem
                    key={locale.value}
                    value={locale.value}>
                    {locale.label}
                  </MenuItem>
                ))}
              </Field>
              <Field
                name="details"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                label={(
                  <FormattedMessage
                    id="settings.details"
                    defaultMessage="Details" />
                )}
                className={this.props.classes.formField}
                component={TextField} />
              <div>
                <Field
                  name="zip"
                  variant="outlined"
                  label={(
                    <FormattedMessage
                      id="settings.zip"
                      defaultMessage="Postal" />
                  )}
                  className={classNames(this.props.classes.formField, this.props.classes.formFieldPostal)}
                  component={TextField} />
              </div>
              <Field
                name="neighborhoodId"
                component={Select}
                variant="outlined"
                className={classNames(this.props.classes.formField, this.props.classes.formFieldSelect)}>
                {(this.props.neighborhoods.data || []).map(neighborhood => (
                  <MenuItem
                    key={neighborhood.id}
                    value={neighborhood.id}>
                    {neighborhood.name}
                  </MenuItem>
                ))}
              </Field>
              <Field
                name="address"
                variant="outlined"
                fullWidth
                label={(
                  <FormattedMessage
                    id="settings.address"
                    defaultMessage="Address" />
                )}
                className={this.props.classes.formField}
                component={TextField} />
              <Field
                name="contactName"
                variant="outlined"
                fullWidth
                label={(
                  <FormattedMessage
                    id="settings.contact-name"
                    defaultMessage="Contact Name" />
                )}
                className={this.props.classes.formField}
                component={TextField} />
              <Field
                name="contactPhone"
                variant="outlined"
                fullWidth
                label={(
                  <FormattedMessage
                    id="settings.contact-number"
                    defaultMessage="Contact Phone" />
                )}
                className={classNames(this.props.classes.formField, this.props.classes.formFieldNoGutter)}
                component={TextField} />
              <div className={this.props.classes.checkboxField}>
                <Field
                  name="showShopPhone"
                  component={Checkbox}
                  defaultChecked={this.props.shop && this.props.shop.showShopPhone}
                  color="primary" />
                <Typography variant="body1">
                  <FormattedMessage
                    id="settings.show-shop-phone"
                    defaultMessage="Display shop phone number publicly" />
                </Typography>
              </div>
              <Typography
                variant="caption"
                className={this.props.classes.label}>
                <FormattedMessage
                  id="settings.holidays-title"
                  defaultMessage="The shop will be closed on" />
              </Typography>
              <FormGroup>
                {HOLIDAYS.map(holiday => (
                  <FormControlLabel
                    control={
                      <MuiCheckbox
                        color="primary"
                        checked={this.state.holidays.includes(holiday.code)}
                        onChange={() => this.onClickHoliday(holiday)} />
                    }
                    label={holiday.name} />
                ))}
              </FormGroup>
              <Typography
                variant="caption"
                className={this.props.classes.label}>
                <FormattedMessage
                  id="settings.notification-emails"
                  defaultMessage="Notification emails" />
              </Typography>
              <div className={this.props.classes.notificationEmails}>
                {this.state.notificationEmails.map(email => (
                  <Chip
                    key={email}
                    label={email}
                    color="primary"
                    className={this.props.classes.notificationEmail}
                    onDelete={this.state.notificationEmails.length > 1 ? () => this.onClickRemoveNotificationEmail(email) : null} />
                ))}
              </div>
              <TextField
                fullWidth
                type="email"
                label={(
                  <FormattedMessage
                    id="settings.notification-email-add"
                    defaultMessage="Add notification email" />
                )}
                value={this.state.newNotificationEmail}
                className={this.props.classes.newNotificationEmail}
                onChange={this.onChangeNewNotificationEmail}
                onKeyPress={this.onKeyPressNewNotificationEmail} />
              <Field
                name="contactEmail"
                fullWidth
                InputProps={{
                  readOnly: true,
                  className: this.props.classes.textFieldNoUnderline,
                }}
                label={(
                  <FormattedMessage
                    id="settings.login-email"
                    defaultMessage="Login email" />
                )}
                className={this.props.classes.formField}
                component={TextField} />
              <Typography
                variant="caption"
                className={this.props.classes.label}>
                <FormattedMessage
                  id="settings.connect-line-title"
                  defaultMessage="Connect Line" />
              </Typography>
              <div className={this.props.classes.lineUsers}>
                {lineAccounts && lineAccounts.map(lineAccount => (
                  <Chip
                    key={lineAccount.id}
                    label={lineAccount.lineUserName}
                    className={this.props.classes.lineUser}
                    onDelete={() => this.onClickRemoveLineAccount(lineAccount)} />
                ))}
              </div>
              <LineButton
                  className={this.props.classes.lineButton}
                  onClick={this.onClickConnectLine}>
                  <FormattedMessage
                    id="settings.connect-line-text"
                    defaultMessage="Connect with Line" />
                </LineButton>
              <Field
                name="fake-password"
                fullWidth
                InputProps={{
                  readOnly: true,
                  className: this.props.classes.textFieldNoUnderline,
                }}
                label={(
                  <FormattedMessage
                    id="settings.password"
                    defaultMessage="Password" />
                )}
                className={classNames(this.props.classes.formField, this.props.classes.formFieldNoGutter)}
                component={TextField} />
              <Button
                variant="text"
                color="primary"
                size="large"
                className={this.props.classes.changePasswordCta}
                onClick={this.onClickChangePassword}>
                <FormattedMessage
                  id="settings.change-password"
                  defaultMessage="Change" />
              </Button>
            </form>
          </div>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const props = {
    shop: state.features.shop,
    myShops: state.api.myShops.default,
    neighborhoods: state.api.neighborhoods.default,
    lineAccounts: state.api.lineAccounts.default,
    myShopPreferences: state.api.myShopPreferences.default,
    notificationEmails: state.api.getNotificationEmails.default.data,
  }

  if (props.shop && props.myShopPreferences.data) {
    props.initialValues = {
      details: props.shop.details,
      zip: props.shop.zip,
      neighborhoodId: props.shop.neighborhood.id,
      address: props.shop.address,
      locale: props.shop.locale,
      contactName: props.shop.contact.name,
      contactPhone: props.shop.contact.phone,
      contactEmail: props.shop.contact.email,
      showShopPhone: props.shop.showShopPhone,
      'fake-password': '********** ',
    }
  }

  return props
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    setShop,
    setLocale,
    getNeighborhoods,
    getMyShops,
    editShop,
    getLineAccounts,
    deleteLineAccount,
    getMyShopPreferences,
    editMyShopPreferences,
    getNotificationEmails,
    setNotificationEmails,
  }, dispatch),
  dispatch,
})

Settings.fetchData = fetchData

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(reduxForm({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
})(withStyles(styles)(withRouter(Settings)))))
