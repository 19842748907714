import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import {
  Field,
  reduxForm,
  SubmissionError,
  reset,
} from 'redux-form'
import {
  TextField,
} from 'redux-form-material-ui'
import {
  injectIntl,
  defineMessages,
  FormattedMessage,
} from 'react-intl'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import green from '@material-ui/core/colors/green'
import CloseIcon from '@material-ui/icons/Close'
import CheckRoundedIcon from '@material-ui/icons/CheckRounded'
import {
  resetPassword,
} from '../../redux/api/actions'
import Constants from '../../utils/Constants'

const FORM_NAME = 'edit-password'

const messages = defineMessages({
  missingField: {
    id: 'edit-password-dialog.missing-field',
    defaultMessage: 'This field is required',
  },
  passwordDoNotMatch: {
    id: 'edit-password-dialog.password-do-not-match',
    defaultMessage: 'Passwords do not match',
  },
})

const validate = (values, props) => {
  const errors = {}

  if (!values.password) {
    errors.password = props.intl.formatMessage(messages.missingField)
  }

  if (!values['confirm-password']) {
    errors['confirm-password'] = props.intl.formatMessage(messages.missingField)
  }

  if (values.password && values['confirm-password'] && values.password !== values['confirm-password']) {
    errors['confirm-password'] = props.intl.formatMessage(messages.passwordDoNotMatch)
  }

  return errors
}


const styles = theme => ({
  content: {
  },
  form: {
    maxWidth: 450,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: '#292529',
    textAlign: 'center',
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  cta: {},
  ctaSuccess: {
    boxShadow: `0px 8.72651px 17.453px ${green[500]}`,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  ctaProgress: {
    position: 'absolute',
  },
})

class EditPasswordDialog extends Component {
  state = {
    loading: false,
    success: false,
  }

  onClickSubmit = async values => {
    this.setState({ loading: true })

    const result = await this.props.resetPassword(values.password)

    if (result && result.error) {
      throw new SubmissionError({
        _error: 'Something went wrong',
      })
    } else {
      this.setState({
        loading: false,
        success: true,
      })

      setTimeout(() => {
        this.props.onClose()

        setTimeout(() => {
          this.props.dispatch(reset(FORM_NAME))
          this.setState({ success: false })
        }, 100)
      }, Constants.FAKE_LATENCY_MS)
    }
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        fullWidth
        maxWidth="sm"
        onClose={this.props.onClose}>
        <DialogTitle className={this.props.classes.title}>
          <FormattedMessage
            id="edit-password-dialog.title"
            defaultMessage="Reset password" />
          <IconButton
            aria-label="Close"
            className={this.props.classes.closeButton}
            onClick={this.props.onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          dividers
          className={this.props.classes.content}>
          <form className={this.props.classes.form}>
            <Field
              name="password"
              type="password"
              variant="outlined"
              label={(
                <FormattedMessage
                  id="edit-password-dialog.new-password"
                  defaultMessage="New password" />
              )}
              disabled={!!this.props.openRequestForMeal}
              component={TextField}
              className={this.props.classes.formField} />
            <Field
              name="confirm-password"
              type="password"
              variant="outlined"
              label={(
                <FormattedMessage
                  id="edit-password-dialog.confirm-new-password"
                  defaultMessage="Confirm new password" />
              )}
              disabled={!!this.props.openRequestForMeal}
              component={TextField}
              className={this.props.classes.formField} />
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={this.props.pristine || this.props.invalid || this.props.loading}
              className={classNames(this.props.classes.cta, {
                [this.props.classes.ctaSuccess]: this.state.success,
              })}
              onClick={this.props.handleSubmit(this.onClickSubmit)}>
              {this.state.loading && (
                <CircularProgress
                  size={24}
                  className={this.props.classes.ctaProgress} />
              )}
              {!this.state.success && (
                <FormattedMessage
                  id="edit-password-dialog.cta"
                  defaultMessage="Save" />
              )}
              {this.state.success && (<CheckRoundedIcon />)}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {
  resetPassword,
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(reduxForm({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
})((withStyles(styles)(EditPasswordDialog)))))
