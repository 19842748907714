import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 8 8" fill="none" {...props}>
      <circle cx={4} cy={4} r={4} fill="#C4C4C4" />
    </svg>
  )
}

export default SvgComponent
